const Collaboration = ({ isWhite }: any) => {
  return (
    <svg
      width="21"
      height="13"
      viewBox="0 0 21 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.73242 11.4995H20.2312C20.6454 11.4995 20.9812 11.8353 20.9812 12.2495C20.9812 12.6292 20.699 12.943 20.3329 12.9927L20.2312 12.9995H1.73242C1.31821 12.9995 0.982422 12.6637 0.982422 12.2495C0.982422 11.8698 1.26458 11.556 1.63065 11.5063L1.73242 11.4995H20.2312H1.73242ZM17.2318 0C18.1983 0 18.9818 0.783502 18.9818 1.75V9.25029C18.9818 10.2168 18.1983 11.0003 17.2318 11.0003H4.7318C3.7653 11.0003 2.9818 10.2168 2.9818 9.25029V1.75C2.9818 0.783502 3.7653 0 4.7318 0H17.2318Z"
        fill={isWhite ? 'white' : '#185D74'}
      />
    </svg>
  );
};

export default Collaboration;
