import KudosNRewards from './assets/KudosNRewards';
import LiveOffice from './assets/LiveOffice';
import SocialBonding from './assets/SocialBonding';
import TeamActivity from './assets/TeamActivity';
import WallOfFame from './assets/WallOfFame';
import WallOfMoments from './assets/WallOfMoments';

const Experience = () => {
  return (
    <div className="mx-[20px] sm:mx-[40px] pb-[120px]">
      <div className="text-left">
        <div className="text-[28px] sm:text-[48px]">
          What improves experience ?
        </div>
      </div>
      <div className="flex flex-col sm:flex-row flex-wrap justify-between py-8 gap-4">
        <div className="p-3 sm:p-6 w-[100%] md:w-[45%] lg:w-[32%]">
          <div className="flex justify-center">
            <LiveOffice />
          </div>
          <div className="text-center py-2">
            <div className="text-[24px] text-bold text-[#000000] font-semibold py-[12px]">
              {' '}
              Live office Collaboration
            </div>
            <div className="text-[1rem]">
              Collaborate with in-office experience (tap & talk, live
              audio-video a tap away.)
            </div>
          </div>
        </div>
        <div className="p-3 sm:p-6 w-[100%] md:w-[45%] lg:w-[32%]">
          <div className="flex justify-center">
            <KudosNRewards />
          </div>
          <div className="text-center py-2">
            <div className="text-[24px] text-bold text-[#000000] font-semibold py-[12px]">
              Kudos & Rewards
            </div>
            <div className="text-[1rem]">
              Motivate employees by point-based redeemable rewards & kudos as
              recognitions.
            </div>
          </div>
        </div>
        <div className="p-3 sm:p-6 w-[100%] md:w-[45%] lg:w-[32%]">
          <div className="flex justify-center">
            <WallOfMoments />
          </div>
          <div className="text-center py-2">
            <div className="text-[24px] text-bold text-[#000000] font-semibold py-[12px]">
              Wall of Moments
            </div>
            <div className="text-[1rem]">
              Share formal or informal update with a specific team or with whole
              organisation on the wall to comment/like.
            </div>
          </div>
        </div>
        <div className="p-3 sm:p-6 w-[100%] md:w-[45%] lg:w-[32%]">
          <div className="flex justify-center">
            <WallOfFame />
          </div>
          <div className="text-center py-2">
            <div className="text-[24px] text-bold text-[#000000] font-semibold py-[12px]">
              Wall of Fame
            </div>
            <div className="text-[1rem]">
              Wall displaying photos of achievers, work anniversaries and extra
              milers.
            </div>
          </div>
        </div>
        <div className="p-3 sm:p-6 w-[100%] md:w-[45%] lg:w-[32%]">
          <div className="flex justify-center">
            <SocialBonding />
          </div>
          <div className="text-center py-2">
            <div className="text-[24px] text-[#000000] font-semibold py-[12px]">
              Social Bondings
            </div>
            <div className="text-[1rem]">
              Auto reminders to wish for birthdays, wedding anniversaries and
              new joiners.
            </div>
          </div>
        </div>
        <div className="p-3 sm:p-6 w-[100%] md:w-[45%] lg:w-[32%]">
          <div className="flex justify-center">
            <TeamActivity />
          </div>
          <div className="text-center py-2">
            <div className="text-[24px] text-bold text-[#000000] font-semibold py-[12px]">
              Team activities
            </div>
            <div className="text-[1rem]">
              Felicitation of team games, and celebrations.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
