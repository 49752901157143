import * as Types from '@worx.squad/app-sdk/base-types';

import { DocumentNode } from 'graphql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetOrganizationByUserIdQueryVariables = Types.Exact<{
  userId: Types.Scalars['uuid'];
}>;


export type GetOrganizationByUserIdQuery = { __typename?: 'query_root', organization_user: Array<{ __typename?: 'organization_user', organization?: { __typename?: 'organization', id: any, owned_by: any } | null }> };


export const GetOrganizationByUserIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getOrganizationByUserId"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"uuid"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organization_user"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"user_id"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"_eq"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}]}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organization"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"owned_by"}}]}}]}}]}}]} as unknown as DocumentNode;

export function useGetOrganizationByUserIdQuery(options: Omit<Urql.UseQueryArgs<GetOrganizationByUserIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOrganizationByUserIdQuery, GetOrganizationByUserIdQueryVariables>({ query: GetOrganizationByUserIdDocument, ...options });
};