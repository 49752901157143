import { useLayoutState } from '@worx.squad/shared-frontend';
import { useLayoutEffect } from 'react';
import Challenges from '../components/CommonComponent/Challenges';
import ContactUs from '../components/CommonComponent/ContactUs';
import { Footer } from '../components/CommonComponent/Footer';
import { Header } from '../components/CommonComponent/Header';
import { TryUs } from '../components/CommonComponent/TryUs';
import CustomersFeedback from '../components/Home/CustomersFeedback';
import DownloadEBook from '../components/Home/DownloadEBook';
import Experience from '../components/Home/Experience';
import { FAQ } from '../components/Home/FAQ';
import { Features } from '../components/Home/Features';
import OurMission from '../components/Home/OurMission';
import Productivity from '../components/Home/Productivity';
import { ProductivityNExperiencePlatform } from '../components/Home/ProductivityNExperiencePlatform';
import { Starter } from '../components/Home/Starter';

const Home = () => {
  const [, setLayoutState] = useLayoutState();
  useLayoutEffect(() => {
    setLayoutState?.('normal');
  }, [setLayoutState]);
  return (
    <div className="relative">
      <div className="sticky bg-[#000000] top-0 z-[9999] shadow-lg">
        <Header />
      </div>
      <div className="bg-[#000000]">
        <Starter />
        <OurMission />
      </div>
      <div>
        <Productivity />
        <Experience />
      </div>
      <div className="bg-[#F1F8F9]">
        <ProductivityNExperiencePlatform />
      </div>
      <div className="bg-[#000000]">
        <Challenges />
      </div>
      <div>
        <CustomersFeedback />
      </div>
      <Features />
      <TryUs />
      <DownloadEBook />
      <FAQ />
      <div className="pt-1 bg-[#000000]">
        <ContactUs />
        <Footer />
      </div>
    </div>
  );
};

export default Home;
