import { MessageBarType, Modal, Stack } from '@fluentui/react';
import { useNotification } from '@worx.squad/shared-frontend';
import { VscClose } from 'react-icons/vsc';
import { useOrganizationContext } from '../../context/OrganizationContext/OrganizationContext';
import { useCheckGoalCountMutation } from './PerformanceReview.generated';

interface IModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  action: string;
  selectedPeriod: any;
  selectedPeriodInfo: {
    index: number;
    info: any;
  };
  children?: React.ReactNode;
}

const ConformationPageForStartAndStop = (props: IModalProps) => {
  const { organization } = useOrganizationContext();
  const { notification } = useNotification();

  const [{ data: checkGoalCountValue }, checkGoalCount] =
    useCheckGoalCountMutation();

  const handleUpdateAction = async (
    id: any,
    action: any,
    timePeriodValue: any,
    index: any,
  ) => {
    if (index !== 0) {
      const percentageValue: any =
        props.selectedPeriod[index - 1].status.split('%');
      if (parseInt(percentageValue[0]) >= 100) {
        notification({
          message: `Error: The previous performance is not yet completed.`,
          type: MessageBarType.error,
        });
      } else {
        try {
          const { error } = await checkGoalCount({
            object: {
              orgId: organization?.id,
              action: action,
              timePeriod: timePeriodValue,
              timePeriodId: id,
            },
          });
          if (error) {
            notification({
              message: error.message,
              type: MessageBarType.error,
            });
          } else {
            notification({
              message: `Performance Review ${
                action === 'Stopped' ? 'Stopped' : 'Started'
              } successfully!`,
              type: MessageBarType.success,
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      try {
        const { error } = await checkGoalCount({
          object: {
            orgId: organization?.id,
            action: action,
            timePeriod: timePeriodValue,
            timePeriodId: id,
          },
        });
        if (error) {
          notification({
            message: error.message,
            type: MessageBarType.error,
          });
        } else {
          notification({
            message: `Performance Review ${
              action === 'Stopped' ? 'Stopped' : 'Started'
            } successfully!`,
            type: MessageBarType.success,
          });
          props.onDismiss();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Modal {...props}>
      <Stack className="p-6" style={{ width: 462, height: 231 }}>
        <span className="">
          <div className="flex flex-row justify-between items-center">
            <span>
              <label className="text-[#201F1E] text-xl leading-7 font-semibold">
                Confirm
              </label>
            </span>
            <span
              onClick={props.onDismiss}
              className="flex justify-end cursor-pointer"
            >
              <VscClose size={25} />
            </span>
          </div>
          <div className="py-6">
            <label className="text-xl font-normal leading-5 text-[#02171E]">
              Are you sure you want to {props.action}?This Performance Review
            </label>
          </div>
          <div className="flex justify-end p-4">
            <button
              onClick={props.onDismiss}
              className="bg-[#FFFFFF] rounded-sm border border-[#8A8886] py-1 px-6 text-black text-sm font-normal leading-5 m-1"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                handleUpdateAction(
                  props.selectedPeriodInfo.info.id,
                  props.action,
                  props.selectedPeriodInfo.info.timePeriod,
                  props.selectedPeriodInfo.index,
                );
              }}
              className="bg-[#175E74] py-1 px-5 rounded-sm text-white text-sm font-normal leading-5 m-1"
            >
              Confirm & Submit
            </button>
          </div>
        </span>
      </Stack>
    </Modal>
  );
};

export default ConformationPageForStartAndStop;
