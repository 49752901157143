import {
  Checkbox,
  Dropdown,
  Icon,
  MessageBarType,
  TextField,
} from '@fluentui/react';
import { useNotification } from '@worx.squad/shared-frontend';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useOrganizationContext } from '../../../context/OrganizationContext/OrganizationContext';
import {
  useCreatePerformanceReviewMutation,
  useFinancialYearValueQuery,
  useGetPerformanceReviewTableDataQuery,
  useGet_TimePeriod_ListMutation,
  useUpdatePerformanceReviewMutation,
} from '../PerformanceReview.generated';
import RatingScale from './RatingScale';
import TimePeriod from './TimePeriod';

interface IProps {
  selectedPerformance: any;
  setCreatePR: (data: boolean) => void;
  getPerformanceReviewList: any;
  setSelectedPerformance: any;
}

const CreateOrUpdatePerformanceReview = (props: IProps) => {
  const {
    selectedPerformance,
    setCreatePR,
    setSelectedPerformance,
    getPerformanceReviewList,
  } = props;

  const { organization } = useOrganizationContext();
  const { notification } = useNotification();

  const checkSelectedPerformace = Object.keys(selectedPerformance).length !== 0;

  const generateRatingArray = (scale: number) => {
    const descriptions =
      scale === 5
        ? ['Deficient', 'Below Average', 'Satisfactory', 'Good', 'Excellent']
        : [
            'Deficient',
            'Inadequate',
            'Needs Improvement',
            'Below Average',
            'Average',
            'Above Average',
            'Good',
            'Very Good',
            'Outstanding',
            'Excellent',
          ];

    return Array.from({ length: scale }, (_, i) => ({
      rating: i + 1,
      description: descriptions[i] || '',
    })).sort((a, b) => a.rating - b.rating);
  };

  const [state, setState] = useState<any>({
    name: '',
    startYear: '',
    startDate: '',
    startDateText: '',
    endDate: '',
    endDateText: '',
    frequency: 'Quarterly',
    period: [],
    viewAllReview: true,
    selfReview: '20%',
    managerReview: '40%',
    peerReview: '40%',
    organizationalGoals: '30%',
    copyGoalsAndKpis: true,
    ratingScale: 'Rating Scale 1-5',
    rating: generateRatingArray(5),
    Instructions: '',
  });

  const todayDate = moment().format('YYYY-MM-DD');
  const year = new Date().getFullYear();
  const monthNames = moment.months();
  const previousYears: any[] = [];
  for (let i = 0; i <= 1; i++) {
    previousYears.push({
      key: year - i,
      text: (year - i).toString(),
    });
  }
  const months = monthNames.map((x, i) => ({
    key: new Date(new Date().setFullYear(state.startYear, i, 1)).setHours(
      0,
      0,
      0,
      0,
    ),
    text: `${x} ${state.startYear}`,
  }));
  const monthsCycleEnd = monthNames.map((x, i) => ({
    key: new Date(
      new Date().setFullYear(parseInt(state.startYear), i, 1),
    ).setHours(0, 0, 0, 0),
    text: `${x === 'January' ? 'December' : monthNames[--i]} ${
      x === 'January' ? parseInt(state.tartYear) : parseInt(state.startYear) + 1
    }`,
  }));

  const [{ data: getTimePeriodListOrgAdmin }, get_TimePeriod_List] =
    useGet_TimePeriod_ListMutation();
  const [, createPerformanceReview] = useCreatePerformanceReviewMutation();
  const [, updatePerformanceReview] = useUpdatePerformanceReviewMutation();
  const [{ data: financialYearValue }] = useFinancialYearValueQuery({
    variables: {
      todaysDate: todayDate,
      _eq: organization?.id,
    },
    pause: !todayDate,
    requestPolicy: 'cache-and-network',
  });
  const [{ data: performanceReviewTableData }, tableView] =
    useGetPerformanceReviewTableDataQuery({
      variables: {
        _eq: organization?.id,
        _eq1: selectedPerformance?.year,
      },
      pause: !organization?.id,
      requestPolicy: 'cache-and-network',
    });

  const handleState = (field: string, value: any) => {
    setState((prevState: any) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handlePeriod = (field: string, index: number, value: any) => {
    setState((prevState: any) => {
      const updatedPeriod = [...prevState.period];
      updatedPeriod[index] = {
        ...updatedPeriod[index],
        [field]: value,
      };

      return {
        ...prevState,
        period: updatedPeriod,
      };
    });
  };

  const handleRating = (field: string, index: number, value: any) => {
    const updatedRating = [...state.rating];
    updatedRating[index] = {
      ...updatedRating[index],
      [field]: value,
    };

    setState({
      ...state,
      rating: updatedRating,
    });
  };

  const createPerformance = async () => {
    const formattedTimePeriod = state.period.map((info: any) => ({
      title: info.title,
      startDate: info.startDate,
      period: info.timePeriod,
      endDate: info.dueDate,
      startingDate: info.startDateFrom,
      endingDate: info.endingDate,
    }));

    const formatDate = (dateText: string | undefined, type: 'start' | 'end') =>
      dateText
        ? moment(dateText)
            [type === 'start' ? 'startOf' : 'endOf']('month')
            .format('YYYY-MM-DD')
        : '';

    const performanceObject = {
      name: state.name,
      view_option: state.viewAllReview,
      org_goal_share: state.organizationalGoals,
      copy_previous_year_goals: state.copyGoalsAndKpis,
      self_review: state.selfReview,
      manager_review: state.managerReview,
      peer_review: state.peerReview,
      rating_scale: state.rating,
      frequency: state.frequency,
      instruction: state.instructions,
      year: state.startYear.toString(),
      timePeriod: formattedTimePeriod,
      organization_Id: organization?.id,
      start: formatDate(state.startDateText, 'start'),
      end: formatDate(state.endDateText, 'end'),
    };

    try {
      const { error } = checkSelectedPerformace
        ? await updatePerformanceReview({
            object: {
              ...performanceObject,
              id: selectedPerformance.id,
            },
          })
        : await createPerformanceReview({
            object: performanceObject,
          });

      if (error) {
        notification({
          message: error.message,
          type: MessageBarType.error,
        });
      } else {
        notification({
          message: `Performance Review ${checkSelectedPerformace ? 'Updated' : 'Created'} successfully!`,
          type: MessageBarType.success,
        });
        setCreatePR(false);
        getPerformanceReviewList();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (state.ratingScale === 'Rating Scale 1-5') {
      handleState('rating', generateRatingArray(5));
    } else {
      handleState('rating', generateRatingArray(10));
    }
  }, [state.ratingScale]);

  useEffect(() => {
    monthsCycleEnd?.map((date: any) => {
      if (
        date.key ===
        moment(state.startDate).toDate().setFullYear(state.startYear)
      ) {
        handleState('endDateText', date.text);
      }
    });
  }, [state.startDate, state.startYear]);

  useEffect(() => {
    if (financialYearValue?.performance_review_settings.length) {
      handleState(
        'startYear',
        financialYearValue.performance_review_settings[0].year,
      );
    }
  }, [financialYearValue?.performance_review_settings]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { error } = await get_TimePeriod_List({
          object: {
            endDate: state.endDateText,
            startDate: state.startDateText,
            timePeriod: state.frequency,
          },
        });
        if (error) {
          console.error(error);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [state.endDateText, state.frequency, state.startDateText]);

  useEffect(() => {
    if (getTimePeriodListOrgAdmin?.getTimePeriodList?.response) {
      const formattedTimePeriod =
        getTimePeriodListOrgAdmin.getTimePeriodList.response.map(
          (data: any) => {
            const startDate = data.timePeriod.split(' -');
            const startDateValue = startDate[1]?.replace(')', ' ');

            const startDateRange = moment(
              `${startDateValue}, ${data.year}`,
              'DD MMMM, YYYY',
            );
            const startDateFrom = startDateRange
              .clone()
              .subtract(7, 'days')
              .format('YYYY-MM-DD');

            const endDateRange = moment(`${startDateFrom}`, 'YYYY-MM-DD');

            const startDateTo = endDateRange
              .clone()
              .add(90, 'days')
              .format('YYYY-MM-DD');
            const dueDateTo = endDateRange
              .clone()
              .add(100, 'days')
              .format('YYYY-MM-DD');

            return {
              timePeriod: data.timePeriod,
              title: `Performance Review ${data.timePeriod}`,
              startDateFrom: startDateFrom,
              startDate: '',
              startDateTo: startDateTo,
              dueDate: '',
              dueDateTo: dueDateTo,
              endingDate: '',
            };
          },
        );
      let updatedTimePeriod = [...formattedTimePeriod];
      if (
        checkSelectedPerformace &&
        performanceReviewTableData?.performance_review_settings.length
      ) {
        const response =
          performanceReviewTableData.performance_review_settings[0];

        if (state.frequency === response.frequency) {
          const formattedPeriod =
            response.performance_review_setting_With_Goals.map(
              (periodItem, index) => ({
                ...periodItem,
                title: periodItem.title,
                startDate: periodItem.startDate,
                dueDate: periodItem.dueDate,
                endingDate: periodItem.endingDate,
              }),
            );

          updatedTimePeriod = updatedTimePeriod.map((item, index) => ({
            ...item,
            title: formattedPeriod[index]?.title || item.title,
            startDate: formattedPeriod[index]?.startDate || item.startDate,
            dueDate: formattedPeriod[index]?.dueDate || item.dueDate,
            endingDate: formattedPeriod[index]?.endingDate || item.endingDate,
          }));
        }
      }

      setState((prevState: any) => ({
        ...prevState,
        period: updatedTimePeriod,
      }));
    }
  }, [
    getTimePeriodListOrgAdmin,
    selectedPerformance,
    performanceReviewTableData,
    state.frequency,
  ]);

  useEffect(() => {
    if (
      performanceReviewTableData?.performance_review_settings.length &&
      checkSelectedPerformace
    ) {
      const response =
        performanceReviewTableData.performance_review_settings[0];
      const formattedRating = response.performance_rating_scales.map(
        (info: any) => {
          return {
            rating: info.rating,
            description: info.name,
          };
        },
      );
      const updatedState: any = {
        ...state,
        name: response.name,
        viewAllReview: response.view_option,
        selfReview: response.self_review,
        managerReview: response.manager_review,
        peerReview: response.peer_review,
        organizationalGoals: response.org_goal_share,
        copyGoalsAndKpis: response.copy_previews_performance_goals,
        ratingScale:
          formattedRating.length === 5
            ? 'Rating Scale 1-5'
            : 'Rating Scale 1-10',
        rating: formattedRating,
        frequency: response.frequency,
        instructions: response.instruction,
        startYear: response.year,
      };

      months.forEach((info: any) => {
        if (info.text === moment(response.startDate).format('MMMM YYYY')) {
          updatedState.startDate = info.key;
          updatedState.startDateText = info.text;
        }
      });

      setState(updatedState);
    }
  }, [
    performanceReviewTableData?.performance_review_settings,
    selectedPerformance,
  ]);

  return (
    <div>
      <div className="flex gap-x-4 items-center">
        <Icon
          iconName="Back"
          className="text-[#5F6368] text-xl cursor-pointer"
          onClick={() => {
            setSelectedPerformance({});
            setCreatePR(false);
          }}
        />
        <label className="text-xl text-[#000000] font-semibold">
          {checkSelectedPerformace
            ? selectedPerformance.name
            : 'Create Performance Review'}
        </label>
      </div>
      <div className="flex flex-col divide-y divide-[#E0E3E1] gap-y-8">
        <div className="mt-6 flex flex-col gap-y-6">
          <TextField
            label="Name"
            className={'w-[40%]'}
            value={state.name}
            onChange={(e, val: any) => handleState('name', val)}
          />
          <div className="flex flex-col gap-y-1">
            <label className="text-sm text-[#000000] font-semibold">
              Annual Performance Cycle
            </label>
            <label className="text-sm text-[#5C5F60] font-normal">
              Please select the cycle start month. End month will be
              automatically calculated as 12 months from the start month.
            </label>
            <div className="flex items-center gap-x-4">
              <Dropdown
                selectedKey={parseInt(state.startYear)}
                onChange={(_, option) => {
                  handleState('startYear', option?.key as number);
                  handleState('startDate', '');
                  handleState('startDateText', '');
                }}
                options={previousYears || []}
                label="Start Year"
                style={{ minWidth: '6rem' }}
              />
              <Dropdown
                selectedKey={state.startDate}
                onChange={(_, option) => {
                  handleState('startDate', option?.key as number);
                  handleState('startDateText', option?.text as string);
                }}
                options={months || []}
                label="Start Month"
                style={{ minWidth: '6rem' }}
              />
              <Dropdown
                selectedKey={
                  state.startDate &&
                  moment(state.startDate).toDate().setFullYear(state.startYear)
                }
                disabled
                options={monthsCycleEnd}
                label="To"
                style={{ minWidth: '6rem' }}
              />
            </div>
          </div>
        </div>
        <TimePeriod
          state={state}
          handleState={handleState}
          handlePeriod={handlePeriod}
        />
        <div className="pt-12 flex flex-col gap-y-4">
          <Checkbox
            label="View all peer, manager and self reviews as well as comments to employees."
            styles={{ label: { color: '#000000', fontSize: '14px' } }}
            checked={state.viewAllReview}
            onChange={() => handleState('viewAllReview', !state.viewAllReview)}
          />
          <label className="text-sm text-[#5C5F60] font-normal">
            If this is not selected, employees will be able to see their own
            consolidated score sheet containing KPI wise ratings and comments of
            manager and peers. If selected employees can not see peer review
            score and ratings.
          </label>
        </div>
        <div className="pt-12 flex flex-col gap-y-4">
          <div className="flex flex-col gap-y-1">
            <label className="text-sm text-[#000000] font-semibold">
              Reviewer Weightage
            </label>
            <label className="text-sm text-[#5C5F60] font-normal">
              Define weightage for self,manager and peer.These will be used to
              calculate the overall employee score.
            </label>
          </div>
          <div className="flex items-center gap-x-4">
            <TextField
              label="Self Review"
              value={state.selfReview}
              onChange={(e, val) => handleState('selfReview', val)}
            />
            <TextField
              label="Manager Review"
              value={state.managerReview}
              onChange={(e, val) => handleState('managerReview', val)}
            />
            <TextField
              label="Peer Review"
              value={state.peerReview}
              onChange={(e, val) => handleState('peerReview', val)}
            />
          </div>
        </div>
        <div className="pt-12 flex flex-col gap-y-2">
          <TextField
            label="Organisational Goals Share"
            className="w-[20%]"
            value={state.organizationalGoals}
            onChange={(e, val) => handleState('organizationalGoals', val)}
          />
          <label className="text-sm text-[#5C5F60] font-normal">
            This will the share fixed for organisational goals for all the
            roles. Rest will be available for role/department specific goals.
            Total goals weightage for each role should amount to 100% Which is
            Organisational goals%+Role/department specific goals %
          </label>
        </div>
        <div className="pt-12 flex flex-col gap-y-4">
          <Checkbox
            label="Copy goals and KPI data from previous performance review"
            styles={{ label: { color: '#000000', fontSize: '14px' } }}
            checked={state.copyGoalsAndKpis}
            onChange={() =>
              handleState('copyGoalsAndKpis', !state.copyGoalsAndKpis)
            }
          />
          <label className="text-sm text-[#5C5F60] font-normal">
            If this is selected, goals and KPI’s as well as their weightage
            percentages will be copied to this performance review cycle with
            updated dates. You can modify, edit delete later.
          </label>
        </div>
        <RatingScale
          state={state}
          handleState={handleState}
          handleRating={handleRating}
        />
        <div className="pt-12">
          <TextField
            multiline={true}
            label="Instructions"
            value={state.instructions}
            onChange={(e, val) => handleState('instructions', val)}
          />
        </div>
      </div>
      <div className="flex mt-3">
        <button
          className="bg-[#FFFFFF] py-1.5 px-5 text-black rounded-sm border border-[#8A8886] text-sm font-semibold leading-5"
          onClick={() => {
            setSelectedPerformance({});
            setCreatePR(false);
          }}
        >
          Cancel
        </button>
        <button
          className="bg-[#175E74] mx-2 py-1.5 px-5 text-white rounded-sm border border-[#8A8886] text-sm font-semibold leading-5"
          onClick={createPerformance}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default CreateOrUpdatePerformanceReview;
