const LogoSvg = () => {
  return (
    <svg
      width="157"
      height="48"
      viewBox="0 0 157 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_244_858)">
        <path
          d="M75.1719 16.6348H76.8832L79.0035 27.9901H79.0436L81.0079 16.6348H83.0691L85.0333 27.9901H85.0735L87.1937 16.6348H88.9051L86.0473 30.5169H83.9673L82.0621 19.2774H82.0243L80.0222 30.5169H77.9422L75.1813 16.6348H75.1719Z"
          fill="white"
        />
        <path
          d="M92.7848 19.8242C95.7986 19.8242 96.5384 22.3723 96.5384 25.308C96.5384 28.2438 95.7797 30.7919 92.7848 30.7919C89.79 30.7919 89.0312 28.2438 89.0312 25.308C89.0312 22.3723 89.79 19.8242 92.7848 19.8242ZM94.8271 25.308C94.8271 23.0531 94.4962 21.1077 92.747 21.1077C91.0948 21.1077 90.7449 23.0531 90.7449 25.308C90.7449 27.563 91.0759 29.5084 92.747 29.5084C94.4182 29.5084 94.8271 27.563 94.8271 25.308Z"
          fill="white"
        />
        <path
          d="M97.8398 20.096H99.4732V21.6514H99.5134C99.979 20.6586 100.603 19.8242 101.768 19.8242C102.099 19.8242 102.333 19.8644 102.487 19.94V21.5355C102.331 21.5166 102.137 21.4575 101.612 21.4575C100.794 21.4575 99.4732 22.1974 99.4732 23.9276V30.52H97.8398V20.0984V20.096Z"
          fill="white"
        />
        <path
          d="M103.059 20.0977H104.848L106.735 23.8111L108.64 20.0977H110.429L107.706 25.0946L110.526 30.5193H108.737L106.657 26.397L104.655 30.5193H102.865L105.723 25.0946L103.059 20.0977Z"
          fill="white"
        />
        <path
          d="M120.87 21.6332V19.6879C120.87 19.0072 120.655 18.6195 120.014 18.6195C119.315 18.6195 119.159 19.0072 119.159 19.6879C119.159 22.0209 124.096 22.7016 124.096 26.98C124.096 29.5848 122.697 30.7903 119.993 30.7903C117.932 30.7903 115.93 30.0906 115.93 27.6985V25.1315H119.156V27.5236C119.156 28.3415 119.409 28.5731 120.012 28.5731C120.537 28.5731 120.867 28.3391 120.867 27.5236C120.867 24.3941 115.93 24.335 115.93 19.8628C115.93 17.3549 117.582 16.4023 120.071 16.4023C122.248 16.4023 124.096 17.1422 124.096 19.4563V21.6332H120.867H120.87Z"
          fill="white"
        />
        <path
          d="M132.205 33.0067H129.132V29.0593H129.092C128.82 30.071 128.042 30.6548 126.915 30.6548C125.359 30.6548 124.775 29.6053 124.775 28.2438V22.1005C124.775 20.7012 125.281 19.2617 126.974 19.2617C128.101 19.2617 128.938 19.6896 129.094 20.8572H129.134V19.3988H132.207V33.0091L132.205 33.0067ZM127.848 27.3669C127.848 27.8538 128.12 28.1257 128.489 28.1257C128.858 28.1257 129.129 27.8538 129.129 27.3669V22.5449C129.129 22.058 128.858 21.7862 128.489 21.7862C128.12 21.7862 127.848 22.058 127.848 22.5449V27.3669Z"
          fill="white"
        />
        <path
          d="M137.317 29.1374H137.277C136.986 30.1088 136.247 30.6549 135.041 30.6549C133.661 30.6549 132.961 29.7023 132.961 28.1068V19.3965H136.034V27.1164C136.034 27.6033 136.074 28.1281 136.655 28.1281C137.237 28.1281 137.317 27.6033 137.317 27.1164V19.3965H140.39V30.5178H137.317V29.1374Z"
          fill="white"
        />
        <path
          d="M145.417 29.2153H145.377C145.008 30.0119 144.443 30.6548 143.219 30.6548C141.876 30.6548 141.061 29.837 141.061 28.3809V26.7287C141.061 23.8119 145.417 24.1617 145.417 22.1005C145.417 21.6538 145.28 21.2827 144.776 21.2827C144.273 21.2827 144.136 21.6514 144.136 22.1005V23.3249H141.063V22.2944C141.063 20.0583 142.93 19.2617 144.776 19.2617C146.622 19.2617 148.49 20.0583 148.49 22.2944V30.5201H145.417V29.2177V29.2153ZM144.133 27.66C144.133 28.0477 144.405 28.2438 144.698 28.2438C145.339 28.2438 145.417 27.816 145.417 26.8233V24.8992C144.814 25.327 144.133 25.5799 144.133 26.4545V27.66Z"
          fill="white"
        />
        <path
          d="M153.567 29.1184H153.529C153.373 30.227 152.536 30.6548 151.409 30.6548C149.716 30.6548 149.213 29.2153 149.213 27.8751V21.7318C149.213 20.3135 149.797 19.2617 151.352 19.2617C152.48 19.2617 153.257 19.9023 153.529 20.9139H153.567V16.6758H156.64V30.5201H153.567V29.1207V29.1184ZM152.283 27.3692C152.283 27.8562 152.555 28.128 152.924 28.128C153.293 28.128 153.564 27.8562 153.564 27.3692V22.5473C153.564 22.0603 153.293 21.7885 152.924 21.7885C152.555 21.7885 152.283 22.0603 152.283 22.5473V27.3692Z"
          fill="white"
        />
        <path
          d="M7.46225 24.0717C7.46225 22.0153 5.78874 20.3418 3.73231 20.3418C1.67588 20.3418 0 22.0153 0 24.0717C0 26.1282 1.67351 27.8041 3.72995 27.8041C5.78638 27.8041 7.45989 26.1305 7.45989 24.0717H7.46225Z"
          fill="white"
        />
        <path
          d="M26.5475 36.9892L31.3458 32.1885L27.0131 24.0644L31.3458 15.9569L26.5475 11.168L18.4234 15.5007L10.3158 11.168L5.5293 15.9592L9.86199 24.0833L5.5293 32.1909L10.3158 36.9916L18.4399 32.6589L26.5475 36.9916V36.9892ZM12.5495 18.1906C13.4596 18.6893 15.1378 19.6041 18.2603 21.3036L18.4234 21.3934L24.3232 18.1811C23.8268 19.0888 22.9121 20.767 21.2055 23.9013L21.1156 24.0644L24.335 29.9737C23.4297 29.4773 21.7491 28.5626 18.603 26.8489L18.4399 26.759L12.5448 29.969C13.0412 29.0637 13.9559 27.3831 15.6673 24.2417L15.7571 24.0786L12.5495 18.1906Z"
          fill="white"
        />
        <path
          d="M33.1421 27.8041C35.1985 27.8041 36.872 26.1305 36.872 24.0717C36.872 22.0129 35.1985 20.3418 33.1421 20.3418C31.0856 20.3418 29.4121 22.0153 29.4121 24.0717C29.4121 26.1282 31.0856 27.8041 33.1421 27.8041Z"
          fill="white"
        />
        <path
          d="M17.2719 0V5.69419C15.6315 6.08657 14.4023 7.55444 14.4023 9.31304C14.4023 11.3718 16.0759 13.0454 18.1323 13.0454C20.1887 13.0454 21.8622 11.3718 21.8622 9.31304C21.8622 7.49062 20.548 5.97548 18.8178 5.65165V1.5506H62.6387V46.4494H18.8225V42.4925C20.5527 42.1687 21.867 40.6536 21.867 38.8311C21.867 36.7747 20.1935 35.1012 18.137 35.1012C16.0806 35.1012 14.4071 36.7747 14.4071 38.8311C14.4071 40.5897 15.6338 42.06 17.2766 42.4524V48H64.1964V0H17.2719Z"
          fill="white"
        />
        <path
          d="M47.2225 41.2158H44.2891L41.0957 30.2812H44.0291L47.2225 41.2158Z"
          fill="white"
        />
        <path
          d="M50.9122 30.2812L49.4491 35.238L47.9883 30.2812H50.9122Z"
          fill="white"
        />
        <path
          d="M54.6124 41.216H51.6814H51.3457L53.0027 35.7559L54.6124 41.216Z"
          fill="white"
        />
        <path
          d="M58.0177 30.2812L56.5545 35.238L55.0938 30.2812H58.0177Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_244_858">
          <rect width="156.639" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoSvg;
