import { useState } from 'react';
import { CustomersFeedbackCardDetails } from './Utils';

const CustomersFeedback = () => {
  const [activeCard, setActiveCard] = useState(0);
  return (
    <div className="pt-[120px]">
      <div className="h-[50vh] px-[20px]">
        <div
          className={`mx-[20px] sm:mx-[40px] flex justify-center gap-4 my-6 cursor-pointer relative`}
        >
          {CustomersFeedbackCardDetails?.map((item: any, index: number) => (
            <div
              style={{
                display: index === activeCard ? 'block' : 'none',
              }}
              className={`border rounded-md flex flex-col items-center justify-center bg-[#F1F8F9] shadow-[0_10px_20px_-8px_rgba(0,0,0,0.12)] absolute p-[20px] sm:px-[80px] sm:py-[60px] w-[90vw] sm:w-[660px]`}
            >
              <div className="py-2 text-center text-[16px] sm:text-[20px] font-semibold font-poppins">
                {item.feedback}
              </div>
              <div className="w-full flex justify-between flex-col gap-4 sm:flex-row items-center pt-[40px]">
                <div className="flex justify-between w-ful items-center gap-2 sm:gap-4">
                  {item.customerImage}
                  <div className="flex flex-col">
                    <div className="text-[1rem] sm:text-[24px] font-semibold font-poppins">
                      {item.name}
                    </div>
                    <div className="text-[12px] sm:text-[16px]">
                      {item.role}
                    </div>
                  </div>
                </div>
                <div className="py-2">{item.companyLogo}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center gap-4 mt-[-20px] sm:mt-[-60px] lg:mt-[0px]">
        {[...Array(4)].map((_, index) => (
          <div
            key={index}
            onClick={() => setActiveCard(index)}
            className={`w-[50px] h-[6px] ${
              activeCard === index ? 'bg-[#185D74]' : 'border bg-gray-300'
            } rounded-md cursor-pointer`}
          ></div>
        ))}
      </div>
      <div className="px-4 py-10 sm:p-10 flex justify-center gap-4 sm:gap-10">
        <div
          onClick={() => setActiveCard(0)}
          className="cursor-pointer p-[12px] rounded-[8px] hover:bg-[#f1f1f1]"
        >
          <img
            src={'../../../assets/Capterra.png'}
            alt="CapterraImages"
            width={'173px'}
            height={'40px'}
          />
        </div>
        <div
          onClick={() => setActiveCard(1)}
          className="cursor-pointer p-[12px] rounded-[8px] hover:bg-[#f1f1f1]"
        >
          <img
            src={'../../../assets/PHunt.png'}
            alt="PHuntImage"
            width={'165px'}
            height={'40px'}
          />
        </div>
        <div
          onClick={() => setActiveCard(2)}
          className="cursor-pointer p-[12px] rounded-[8px] hover:bg-[#f1f1f1]"
        >
          <img
            src={'../../../assets/GTwo.png'}
            alt="GTwoImage"
            width={'38px'}
            height={'40px'}
          />
        </div>
        <div
          onClick={() => setActiveCard(3)}
          className="cursor-pointer p-[12px] rounded-[8px] hover:bg-[#f1f1f1]"
        >
          <img
            src={'../../../assets/SAdvice.png'}
            alt="SAdviceImage"
            width={'268px'}
            height={'40px'}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomersFeedback;
