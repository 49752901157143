import { DefaultButton } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import AddressSvg from '../Home/assets/AddressSvg';
import CallSvg from '../Home/assets/CallSvg';
import LinkSvg from '../Home/assets/LinkSvg';
import MailSvg from '../Home/assets/MailSvg';
import WebSvg from '../Home/assets/WebSvg';

const ContactUs = () => {
  const navigate = useNavigate();
  return (
    <div className="mt-[120px] mb-[96px] mx-[20px] sm:mx-[40px] flex flex-col sm:flex-row justify-between items-center sm:items-start">
      <div>
        <div className="text-[1rem] text-white">Worxwide Consultants</div>
        <div className="text-white hidden text-[2rem] my-4">
          {' '}
          We Are A Team That <br /> Enables Businesses Growth <br />
          Through Digital Solutions
          <br /> And New Age Thinking.
        </div>
        <div className="text-white text-[1.5rem] my-4">
          {' '}
          We Are A Team That Enables <br /> Businesses Growth Through
          <br />
          Digital Solutions And New
          <br /> Age Thinking.
        </div>
        <div className="my-8 sm:mt-[56px] w-auto">
          <DefaultButton
            className="bg-[#000000] w-full sm:w-auto border border-[#FF9900] text-white hover:bg-[#FF9900] hover:text-white px-6 py-6 ease-in-out duration-300"
            onClick={() => navigate('/contact-us')}
          >
            <span className="flex justify-center items-center gap-4">
              <span className="text-[1rem]">Make an Inquiry</span>
              <span>
                <LinkSvg />
              </span>
            </span>
          </DefaultButton>
        </div>
      </div>
      <div className="w-full sm:w-[33%] flex justify-center sm:justify-start items-center">
        <div>
          <div className="flex items-center gap-4 mt-0 mb-6">
            <div>
              <MailSvg />
            </div>
            <div className="text-white text-[1rem]">consult@worxwide.com</div>
          </div>
          <div className="flex items-center gap-4 my-6">
            <div>
              <CallSvg />
            </div>
            <div className="text-white text-[1rem]">+1-571-365-0400</div>
          </div>
          <div className="flex items-center gap-4 my-6">
            <div>
              <WebSvg />
            </div>
            <div className="text-white text-[1rem]">www.worxwide.com</div>
          </div>
          <div className="flex gap-4">
            <div>
              <AddressSvg />
            </div>
            <div>
              <div className="text-white text-[1rem]">
                32, Global business park,
              </div>
              <div className="text-white text-[1rem]">Sector 44, Gurgaon,</div>
              <div className="text-white text-[1rem]">Haryana, 122001</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
