import { Icon, MessageBarType, PrimaryButton } from '@fluentui/react';
import { useNotification } from '@worx.squad/shared-frontend';
import { useBoolean } from 'ahooks';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useOrganizationContext } from '../../context/OrganizationContext/OrganizationContext';
import ConformationPageForStartAndStop from './ConformationPageForStartAndStop';
import { useCheckGoalCountMutation } from './PerformanceReview.generated';
import { ReminderModal } from './ReminderModal';

interface IProps {
  performanceList: any;
  setCreatePR: (data: boolean) => any;
  setSelectedPerformance: any;
  getPerformanceReviewList: any;
}

const PerformanceReviewList = (props: IProps) => {
  const {
    performanceList,
    setCreatePR,
    setSelectedPerformance,
    getPerformanceReviewList,
  } = props;

  const { organization } = useOrganizationContext();
  const { notification } = useNotification();

  const [isReminderModalOpen, setIsReminderModalOpen] = useState<boolean>();
  const [selectedTimePrd, setSelectedTimePrd] = useState<string>('');
  const [action, setAction] = useState('');
  const [showIdx, setShowIdx] = useState(-1);
  const [selectedPeriodInfo, setSelectedPeriodInfo] = useState<any>({
    index: -1,
    info: {},
  });
  const [selectedPeriod, setSelectedPeriod] = useState<any>([]);
  const [visibleClose, { toggle: togglevisibleCloseModal }] = useBoolean(false);

  const [{ data: checkGoalCountValue }, checkGoalCount] =
    useCheckGoalCountMutation();

  const handlePauseAndResumeAction = async (
    id: any,
    action: any,
    timePeriodValue: any,
    index: any,
    periodInfo: any,
  ) => {
    if (index !== 0) {
      const percentageValue: any = periodInfo[index - 1].status.split('%');
      if (parseInt(percentageValue[0]) >= 100) {
        notification({
          message: `Error: The previous performance is not yet completed.`,
          type: MessageBarType.error,
        });
      } else {
        try {
          const { error } = await checkGoalCount({
            object: {
              orgId: organization?.id,
              action: action,
              timePeriod: timePeriodValue,
              timePeriodId: id,
            },
          });
          if (error) {
            notification({
              message: error.message,
              type: MessageBarType.error,
            });
          } else {
            notification({
              message: `Performance Review ${action}d successfully!`,
              type: MessageBarType.success,
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      try {
        const { error } = await checkGoalCount({
          object: {
            orgId: organization?.id,
            action: action,
            timePeriod: timePeriodValue,
            timePeriodId: id,
          },
        });
        if (error) {
          notification({
            message: error.message,
            type: MessageBarType.error,
          });
        } else {
          notification({
            message: `Performance Review ${action}d successfully!`,
            type: MessageBarType.success,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getPerformanceReviewList();
  }, [checkGoalCountValue?.checkGoalCount?.response, visibleClose]);

  return (
    <div>
      <div className="flex justify-between items-center">
        <label className="text-xl text-[#000000] font-semibold">
          Performance Reviews
        </label>
        <PrimaryButton
          text="Create New Performance Review"
          onClick={() => {
            setSelectedPerformance({});
            setCreatePR(true);
          }}
        />
      </div>
      <div className="mt-8 flex flex-col gap-y-4">
        {performanceList?.map((info: any, index: number) => (
          <div
            className={`border border-[#E0E3E1] rounded ${showIdx === index && 'pb-8'} cursor-pointer`}
            key={index}
            onClick={() => setShowIdx(showIdx === index ? -1 : index)}
          >
            <div
              className={`flex justify-between items-center px-5 py-3 ${showIdx === index && 'border-b border-[#E0E3E1]'}`}
            >
              <label className="text-sm font-semibold text-[#000000] w-[40%]">
                {info.name}
              </label>
              <div className="flex items-center gap-7">
                {info.status !== null && (
                  <label className="bg-[#0078D4] py-1 px-4 text-sm font-semibold text-[#FFFFFF] rounded-3xl">
                    {info.status}
                  </label>
                )}
                <label className="text-sm font-normal text-[#201F1E]">
                  {info.duration}
                </label>
                <label className="text-sm font-normal text-[#201F1E]">
                  {info.period.length === 2 && 'Half-Yearly'}
                  {info.period.length === 4 && 'Quarterly'}
                  {info.period.length === 1 && 'Annual'}
                </label>
                <Icon
                  iconName="Settings"
                  className={
                    'text-lg font-medium text-[#212121] cursor-pointer'
                  }
                  onClick={() => {
                    setSelectedPerformance(info);
                    setCreatePR(true);
                  }}
                />
                <Icon
                  iconName="ChevronDown"
                  className={
                    'text-lg font-medium text-[#111827] cursor-pointer'
                  }
                />
              </div>
            </div>
            {showIdx === index && (
              <div className="mx-5">
                <table className="w-full">
                  <thead className="sticky top-0">
                    <tr className="border-b bg-white h-[47px]">
                      <th className="text-sm text-[#323130] font-semibold w-[20%] text-left p-1.5">
                        Name
                      </th>
                      <th className="text-sm text-[#323130] font-semibold w-[10%] text-left p-1.5">
                        Start Date
                      </th>
                      <th className="text-sm text-[#323130] font-semibold w-[10%] text-left p-1.5">
                        Due Date
                      </th>
                      <th className="text-sm text-[#323130] font-semibold w-[20%] text-left p-1.5">
                        Time Period
                      </th>
                      <th className="text-sm text-[#323130] font-semibold w-[10%] text-left p-1.5">
                        Status
                      </th>
                      <th className="text-sm text-[#323130] font-semibold w-[17%] text-left p-1.5">
                        Goals
                      </th>
                      <th className="text-sm text-[#323130] font-semibold w-[8%] text-left p-1.5">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="max-h-[250px]">
                    {info?.period?.map((periodInfo: any, periodIdx: number) => {
                      const goalsCount = periodInfo.goalDetails
                        ? periodInfo.goalDetails[0].count
                        : 0;
                      const currentDate = moment();
                      const dueDateValue = moment(periodInfo.startingDate).add(
                        100,
                        'days',
                      );
                      return (
                        <tr
                          className={`border-b mt-2 h-[47px] cursor-pointer hover:bg-[#F1F8F9]`}
                          key={periodIdx}
                        >
                          <td className="p-1.5 text-sm font-normal">
                            {periodInfo.title}
                          </td>
                          <td className="p-1.5 text-sm font-normal">
                            {periodInfo.startDate}
                          </td>
                          <td className="p-1.5 text-sm font-normal">
                            {periodInfo.dueDate}
                          </td>
                          <td className="p-1.5 text-sm font-normal">
                            {periodInfo.timePeriod}
                          </td>
                          <td className="p-1.5 text-sm font-normal">
                            {periodInfo.status}
                          </td>
                          <td className="p-1.5 text-sm font-normal">
                            <span className="flex gap-x-2 ">
                              {goalsCount}
                              <p
                                className={`${
                                  goalsCount === 0
                                    ? 'text-[#D74654]'
                                    : 'text-[#185D74]'
                                } ' font-normal text-sm'`}
                                onClick={() => {
                                  setSelectedTimePrd(periodInfo.timePeriod);
                                  setIsReminderModalOpen(true);
                                }}
                              >
                                view Goal Detail
                              </p>
                            </span>
                          </td>
                          <td className="p-1.5 text-sm font-normal flex gap-1">
                            {periodInfo.action !== 'Stopped' ? (
                              <>
                                {periodInfo.action === 'Pause' ||
                                periodInfo.action === 'Resume' ? (
                                  <button
                                    disabled={
                                      !currentDate.isBetween(
                                        periodInfo.startingDate,
                                        dueDateValue,
                                      ) || goalsCount === 0
                                    }
                                    className={`text-black px-3 py-1.5 border border-[#E0E0E0] ${
                                      !currentDate.isBetween(
                                        periodInfo.startingDate,
                                        dueDateValue,
                                      ) || goalsCount === 0
                                        ? 'bg-[#F0F0F0] text-[#BDBDBD]'
                                        : ''
                                    }`}
                                    onClick={() => {
                                      handlePauseAndResumeAction(
                                        periodInfo.id,
                                        periodInfo.action,
                                        periodInfo.timePeriod,
                                        periodIdx,
                                        info.period,
                                      );
                                    }}
                                  >
                                    {periodInfo.action === 'Pause'
                                      ? 'Pause'
                                      : 'Resume'}
                                  </button>
                                ) : (
                                  ''
                                )}
                                <button
                                  disabled={
                                    periodInfo?.action !== 'Start'
                                      ? false
                                      : !currentDate.isBetween(
                                          periodInfo.startingDate,
                                          dueDateValue,
                                        ) || goalsCount === 0
                                  }
                                  className={`${
                                    periodInfo?.action === 'Start'
                                      ? 'text-black'
                                      : 'text-[#BC2F32]'
                                  } ${
                                    periodInfo?.action !== 'Start'
                                      ? ''
                                      : !currentDate.isBetween(
                                            periodInfo.startingDate,
                                            dueDateValue,
                                          ) || goalsCount === 0
                                        ? 'bg-[#F0F0F0] text-[#BDBDBD]'
                                        : ''
                                  } px-3 py-1.5 border border-[#E0E0E0]`}
                                  onClick={() => {
                                    setAction(
                                      periodInfo.action === 'Pause' ||
                                        periodInfo.action === 'Resume'
                                        ? 'Stopped'
                                        : 'Start',
                                    );
                                    setSelectedPeriodInfo({
                                      index: periodIdx,
                                      info: periodInfo,
                                    });
                                    setSelectedPeriod(info.period);
                                    togglevisibleCloseModal();
                                  }}
                                >
                                  {periodInfo?.action === 'Start'
                                    ? 'Start'
                                    : 'Stop'}
                                </button>
                              </>
                            ) : (
                              ''
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        ))}
      </div>
      <ReminderModal
        isOpen={isReminderModalOpen}
        onDismiss={(isOpen: boolean) => {
          setIsReminderModalOpen(isOpen);
        }}
        selectedTimePrd={selectedTimePrd}
        setSelectedTimePrd={setSelectedTimePrd}
      />
      {visibleClose && (
        <ConformationPageForStartAndStop
          isOpen={visibleClose}
          onDismiss={togglevisibleCloseModal}
          action={action}
          selectedPeriodInfo={selectedPeriodInfo}
          selectedPeriod={selectedPeriod}
        />
      )}
    </div>
  );
};

export default PerformanceReviewList;
