import { useState } from 'react';
import ForAdmin from './ForAdmin';
import ForEmployee from './ForEmployee';
import HorizontalLine from './assets/HorizontalLine';

const ForEmployeeNAdmin = () => {
  const [type, setType] = useState<string>('employee');

  const handleEmployeeNAdminType = (e: React.MouseEvent<HTMLDivElement>) => {
    const type =
      (e.target as HTMLDivElement).id === 'employee' ? 'employee' : 'admin';
    setType(type);
  };

  return (
    <div className="mx-[20px] sm:mx-[40px] my-[4rem]">
      <div className="text-[28px] sm:text-[48px]">How it works</div>
      <div
        className="flex gap-4 text-[16px] pt-2"
        onClick={handleEmployeeNAdminType}
      >
        <div
          id="employee"
          className={`cursor-pointer font-medium ${
            type === 'employee' ? 'text-[#FF9900]' : 'text-black'
          }`}
        >
          For Employee
        </div>
        <div
          id="admin"
          className={`cursor-pointer font-medium ${
            type === 'admin' ? 'text-[#FF9900]' : 'text-black'
          }`}
        >
          For Employers
        </div>
      </div>
      <div className="py-1">
        <HorizontalLine />
      </div>
      <div>{type === 'employee' ? <ForEmployee /> : <ForAdmin />}</div>
    </div>
  );
};
export default ForEmployeeNAdmin;
