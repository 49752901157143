import DeepDiv from './assets/DeepDiv';
import DeploymentFriendly from './assets/DeploymentFriendly';
import FullEcosystem from './assets/FullEcosystem';
import HyperPersonalization from './assets/HyperPersonalization';
import ImmersiveExperience from './assets/ImmersiveExperience';
import Interoperability from './assets/Interoperability';

export const ProductivityNExperiencePlatform = () => {
  return (
    <div className="mx-[20px] sm:mx-[40px] py-[120px]">
      <div className="text-left">
        <div className="text-[28px] sm:text-[48px]">How Squad stands out?</div>
        <div className="text-[14px] sm:text-[16px] text-[#A19F9D] py-2">
          Enterprise Productivity and Experience Platform
        </div>
      </div>
      <div className="flex flex-col sm:flex-row flex-wrap justify-between py-4 gap-4">
        <div className="cardOne border border-[#A19F9D] relative rounded-md w-[100%] md:w-[48%] lg:w-[32%] cursor-pointer">
          <div className="flex justify-center p-4 sm:p-6">
            <FullEcosystem />
          </div>
          <div className="text-center py-2">
            <div className="text-[24px] text-bold text-[#000000] font-semibold">
              {' '}
              Full Ecosystem
            </div>
            <div className="italic">No Second License</div>
          </div>
          <div className="hoverCardProperty py-[14px] px-[10px] sm:py-[4px]">
            <div className="text-[1.5rem] font-bold text-center">
              Full Ecosystem
            </div>
            <div className="text-center">
              No more scattered business apps, you get all business applications
              build within the Squad app for productivity, engagement, and
              wellness in one place with no need for switching between apps and
              that too with a single license.
            </div>
          </div>
        </div>
        <div className="cardTwo border border-[#A19F9D] rounded-md w-[100%] md:w-[48%] lg:w-[32%] cursor-pointer">
          <div className="flex justify-center p-4 sm:p-6">
            <ImmersiveExperience />
          </div>
          <div className="text-center py-2">
            <div className="text-[24px] text-bold text-[#000000] font-semibold">
              Immersive Experience
            </div>
            <div className="italic">3d rooms, live office and so on</div>
          </div>
          <div className="hoverCardProperty py-[14px] px-[10px] sm:py-[4px]">
            <div className="text-[1.5rem] font-bold text-center">
              Immersive Experiences
            </div>
            <div className="text-center">
              You get immersive experiences like 3d meeting rooms, avatars,
              interactions, and a very intuitive user interface.
            </div>
          </div>
        </div>
        <div className="cardThree border border-[#A19F9D] rounded-md w-[100%] md:w-[48%] lg:w-[32%] cursor-pointer">
          <div className="flex justify-center p-4 sm:p-6">
            <Interoperability />
          </div>
          <div className="text-center py-2">
            <div className="text-[24px] text-bold text-[#000000] font-semibold">
              Interoperability
            </div>
            <div className="italic">Each feature speaks to each other</div>
          </div>
          <div className="hoverCardProperty py-[14px] px-[10px] sm:py-[4px]">
            <div className="text-[1.5rem] font-bold text-center">
              Interoperability
            </div>
            <div className="text-center">
              Each module and feature talks to each other. For ex: Creating a
              project in project management module will automatically create a
              project group. Setting up sprints will auto create scrum calls in
              live office module.
            </div>
          </div>
        </div>
        <div className="cardFour border border-[#A19F9D] rounded-md w-[100%] md:w-[48%] lg:w-[32%] cursor-pointer">
          <div className="flex justify-center p-4 sm:p-6">
            <HyperPersonalization />
          </div>
          <div className="text-center py-2">
            <div className="text-[24px] text-bold text-[#000000] font-semibold">
              Hyper Personalization
            </div>
            <div className="italic">Personalized alerts and custom content</div>
          </div>
          <div className="hoverCardProperty py-[14px] px-[10px] sm:py-[4px]">
            <div className="text-[1.5rem] font-bold text-center">
              Hyper Personalization
            </div>
            <div className="text-center">
              The Squad app is personalized as per user personas in the
              organization hierarchy and there are custom actions and workflows
              which can be further personalized.
            </div>
          </div>
        </div>
        <div className="cardFive border border-[#A19F9D] rounded-md w-[100%] md:w-[48%] lg:w-[32%] cursor-pointer">
          <div className="flex justify-center p-4 sm:p-6">
            <DeepDiv />
          </div>
          <div className="text-center py-2">
            <div className="text-[24px] text-[#000000] font-semibold">
              Organization Insights
            </div>
            <div className="italic">
              Productivity & stress rate, skill & projects deviation
            </div>
          </div>
          <div className="hoverCardProperty py-[14px] px-[10px] sm:py-[4px]">
            <div className="text-[1.5rem] font-bold text-center">
              Organization Insights
            </div>
            <div className="text-center">
              Squad app is a boon for leadership and managerial layer as Squad
              unveils productivity time, skill deviations, billable time,
              employee capacity, projects status and real-time goals
              achievement.
            </div>
          </div>
        </div>
        <div className="cardSix border border-[#A19F9D] rounded-md w-[100%] md:w-[48%] lg:w-[32%]  cursor-pointer">
          <div className="flex justify-center p-4 sm:p-6">
            <DeploymentFriendly />
          </div>
          <div className="text-center py-2">
            <div className="text-[24px] text-bold text-[#000000] font-semibold">
              Highly Intuitive
            </div>
            <div className="italic">Flexibility on-premises or cloud</div>
          </div>
          <div className="hoverCardProperty py-[14px] px-[10px] sm:py-[4px]">
            <div className="text-[1.5rem] font-bold text-center">
              Highly Intuitive
            </div>
            <div className="text-center">
              Squad app is a desktop app which always stays on and no need to
              take log in hassles.The app is built with design thinking
              principles to reduce learning curve for users and is very simple
              and fast to use.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
