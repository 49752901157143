import { Stack } from '@fluentui/react';
// import EmployeeExperience from '../../../assets/MostRecentBlogBanner/EmployeeExperience.jpg';
import MostRecentBlogCard from './MostRecentBlogCard';

import bestPracticesFeature from '../../../assets/BlogFeatureImage/bestPracticesFeature.jpg';
import riseOfEmployeeFeature from '../../../assets/BlogFeatureImage/riseOfEmployeeFeature.png';
import topFiveEvolvingFeature from '../../../assets/BlogFeatureImage/topFiveEvolvingFeature.jpg';
import topFiveProvenFeature from '../../../assets/BlogFeatureImage/topFiveProvenFeature.jpg';
const MostRecentBlog = () => {
  const mostRecentBlog = [
    {
      id: '652b9bb5722f7ce55da8a802',
      fieldData: {
        people: '1173',
        share: '253',
        thumbnail: {
          fileId: '652c2cc6f9121f403b729cfe',
          url: <img src={topFiveEvolvingFeature} alt="EmployeeExperience" />,
          alt: null,
        },
        read: '5 min Read',
        title: 'Top five evolving tools and software for employee productivity',
      },
    },
    {
      id: '652b9bb5722f7ce55da8a803',
      fieldData: {
        people: '1102',
        share: '140',
        thumbnail: {
          fileId: '652c2cd679cbf4ed07a9d9dc',
          url: <img src={topFiveProvenFeature} alt="ProvenHacks" />,
          alt: null,
        },
        read: '3 min Read',
        title: 'Top 5 Proven Hacks to Elevate Your Enterprise Productivity',
      },
    },
    {
      id: '652b9bb5722f7ce55da8a804',
      fieldData: {
        people: '1022',
        share: '374',
        thumbnail: {
          fileId: '652c2cc6f9121f403b729cfe',
          url: (
            <img
              src={riseOfEmployeeFeature}
              alt="EvolvingTools"
              style={{ width: '100%' }}
            />
          ),
          alt: null,
        },
        read: '8 min Read',
        title: 'The Rise of Employee Experience: Digital Facilitation',
      },
    },
    {
      id: '652b9bb5722f7ce55da8a805',
      fieldData: {
        people: '2093',
        share: '33',
        thumbnail: {
          fileId: '652c2cd679cbf4ed07a9d9dc',
          url: <img src={bestPracticesFeature} alt="BestPractices" />,
          alt: null,
        },
        read: '6 min Read',
        title: 'Best Practices and Tools for Project Management',
      },
    },
  ];

  return (
    <Stack>
      <div className="text-[28px] sm:text-[3rem] font-normal sm:font-light mx-[20px] sm:mx-[40px] mt-[40px]">
        Most Recent
      </div>
      <div className="flex flex-wrap mx-[20px] sm:mx-[40px] gap-4 my-[20px]">
        {mostRecentBlog.map((item: any) => (
          <MostRecentBlogCard cardData={item} />
        ))}
      </div>
    </Stack>
  );
};

export default MostRecentBlog;
