import { Link } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import Collaboration from './assets/Collaboration';
import LinkSvg from './assets/LinkSvg';
import ProjectManagement from './assets/ProjectManagement';
import Stress from './assets/Stress';
import Upskill from './assets/Upskill';
export const Features = () => {
  const navigate = useNavigate();
  return (
    <div className="mx-[20px] sm:mx-[40px] py-[120px]">
      <div className="text-left">
        <div className="text-[28px] sm:text-[48px]">
          Powerful building blocks
        </div>
        <div className="text-[14px] sm:text-[16px] text-[#FF9900] py-2">
          Let’s take a deep dive into a new way to work.
        </div>
      </div>
      <div className="flex flex-wrap justify-between py-4 gap-4">
        <div className="flex sticky flex-col lg:flex-row top-[6rem] z-[0] bg-white justify-between gap-4 w-full pt-4">
          <div>
            <div className="flex items-center gap-3">
              <div>
                <Collaboration />
              </div>
              <div className="text-[12px] text-[#185D74] font-semibold">
                Collaboration
              </div>
            </div>
            <div className="text-[2rem] my-4">Tap and Talk</div>
            <div
              className="my-2 text-[1rem] text-[#7E7E7E]"
              style={{ maxWidth: 600 }}
            >
              Speaking To A Colleague Is Just A Click Away.Tap And Start Talking
              Live Video Audio And Chat. No More Waiting Of Calls. Stay Live As
              You Do In Physical Office.
            </div>
            <div className="flex items-center gap-4 text-[#FF9900]">
              <Link
                onClick={() => navigate('/contact-us')}
                className="flex text-[14px] items-center justify-center gap-4 hover:no-underline text-[#FF9900] hover:text-[#FF9900]"
              >
                Get In Touch <LinkSvg feature={true} />
              </Link>
            </div>
          </div>
          <div>
            <img
              src={'../../../assets/TapAndTalk.png'}
              alt="TapAndTalk"
              width={'670px'}
              height={'570px'}
            />
          </div>
        </div>
        <div className="flex sticky flex-col lg:flex-row top-[6rem] z-[20] bg-white justify-between gap-4 w-full pt-4">
          <div>
            <div className="flex items-center gap-3">
              <div>
                <ProjectManagement />
              </div>
              <div className="text-[12px] text-[#185D74] font-semibold">
                Productivity
              </div>
            </div>
            <div className="text-[2rem] my-4">Project management</div>

            <div
              className="my-2 text-[1rem] text-[#7E7E7E]"
              style={{ maxWidth: 600 }}
            >
              Manage Projects, Tasks, Sprints, Gantt Chart Schedules And Measure
              Task Productivity Of People And Project Deviation.
            </div>
            <div className="flex items-center gap-4 text-[#FF9900]">
              <Link
                onClick={() => navigate('/contact-us')}
                className="flex text-[14px] items-center justify-center gap-4 hover:no-underline text-[#FF9900] hover:text-[#FF9900]"
              >
                Get In Touch <LinkSvg feature={true} />
              </Link>
            </div>
          </div>
          <div>
            <img
              src={'../../../assets/ProjectManagement.png'}
              alt="TapAndTalk"
              width={'670px'}
              height={'570px'}
            />
          </div>
        </div>
        <div className="flex sticky flex-col lg:flex-row top-[6rem] z-[30] bg-white justify-between gap-4 w-full pt-4">
          <div>
            <div className="flex items-center gap-3">
              <div>
                <Upskill />
              </div>
              <div className="text-[12px] text-[#185D74] font-semibold">
                Engagement
              </div>
            </div>
            <div className="text-[2rem] my-4">Upskilling</div>
            <div
              className="my-2 text-[1rem] text-[#7E7E7E]"
              style={{ maxWidth: 600 }}
            >
              Create Custom Assessments, Custom Creation Of Trainings, Measure
              Skill Deviations In Dashboard.
            </div>
            <div className="flex items-center gap-4 text-[#FF9900]">
              <Link
                onClick={() => navigate('/contact-us')}
                className="flex text-[14px] items-center justify-center gap-4 hover:no-underline text-[#FF9900] hover:text-[#FF9900]"
              >
                Get In Touch <LinkSvg feature={true} />
              </Link>
            </div>
          </div>
          <div>
            <img
              src={'../../../assets/Upskill.png'}
              alt="TapAndTalk"
              width={'670px'}
              height={'570px'}
            />
          </div>
        </div>
        <div className="flex sticky flex-col lg:flex-row top-[6rem] z-[40] bg-white justify-between gap-4 w-full pt-4">
          <div>
            <div className="flex items-center gap-3">
              <div>
                <Upskill />
              </div>
              <div className="text-[12px] text-[#185D74] font-semibold">
                Engagement
              </div>
            </div>
            <div className="text-[2rem] my-4">Wall of Moments</div>

            <div
              className="my-2 text-[1rem] text-[#7E7E7E]"
              style={{ maxWidth: 600 }}
            >
              Squad Has Pre-Built And Seamless Integration Of All The Business
              And Productivity Applications. Squad Has Pre-Built And Seamless
              Integration Of All The Business And Productivity Applications.
            </div>
            <div className="flex items-center gap-4 text-[#FF9900]">
              <Link
                onClick={() => navigate('/contact-us')}
                className="flex text-[14px] items-center justify-center gap-4 hover:no-underline text-[#FF9900] hover:text-[#FF9900]"
              >
                Get In Touch <LinkSvg feature={true} />
              </Link>
            </div>
          </div>
          <div>
            <img
              src={'../../../assets/WallOfMoment.png'}
              alt="TapAndTalk"
              width={'670px'}
              height={'570px'}
            />
          </div>
        </div>
        <div className="flex sticky flex-col lg:flex-row top-[6rem] z-[50] bg-white justify-between gap-4 w-full pt-4">
          <div>
            <div className="flex items-center gap-3">
              <div>
                <Upskill />
              </div>
              <div className="text-[12px] text-[#185D74] font-semibold">
                Engagement
              </div>
            </div>
            <div className="text-[2rem] my-4">
              Shared Leave & Event Calender
            </div>

            <div
              className="my-2 text-[1rem] text-[#7E7E7E]"
              style={{ maxWidth: 600 }}
            >
              Organization-Level View To See Who All Are On Leave Or Have A
              Meeting. Anyone Can See Anyone’s Event/Leave To Plan His
              Colleagues’ Assignments.
            </div>
            <div className="flex items-center gap-4 text-[#FF9900]">
              <Link
                onClick={() => navigate('/contact-us')}
                className="flex text-[14px] items-center justify-center gap-4 hover:no-underline text-[#FF9900] hover:text-[#FF9900]"
              >
                Get In Touch <LinkSvg feature={true} />
              </Link>
            </div>
          </div>
          <div>
            <img
              src={'../../../assets/LeavesNEvents.png'}
              alt="TapAndTalk"
              width={'670px'}
              height={'570px'}
            />
          </div>
        </div>
        <div className="flex sticky flex-col lg:flex-row top-[6rem] z-[60] bg-white justify-between gap-4 w-full pt-4">
          <div>
            <div className="flex items-center gap-3">
              <div>
                <Upskill />
              </div>
              <div className="text-[12px] text-[#185D74] font-semibold">
                Engagement
              </div>
            </div>
            <div className="text-[2rem] my-4">Employee Capacity</div>

            <div
              className="my-2 text-[1rem] text-[#7E7E7E]"
              style={{ maxWidth: 600 }}
            >
              Keep A Track Of Real Time Status Of Employee Capacity And Assign
              Tasks Accordingly.
            </div>
            <div className="flex items-center gap-4 text-[#FF9900]">
              <Link
                onClick={() => navigate('/contact-us')}
                className="flex text-[14px] items-center justify-center gap-4 hover:no-underline text-[#FF9900] hover:text-[#FF9900]"
              >
                Get In Touch <LinkSvg feature={true} />
              </Link>
            </div>
          </div>
          <div>
            <img
              src={'../../../assets/EmployeeCapacity.png'}
              alt="TapAndTalk"
              width={'670px'}
              height={'570px'}
            />
          </div>
        </div>
        <div className="flex sticky flex-col lg:flex-row top-[6rem] z-[70] bg-white justify-between gap-4 w-full pt-4">
          <div>
            <div className="flex items-center gap-3">
              <div>
                <Upskill />
              </div>
              <div className="text-[12px] text-[#185D74] font-semibold">
                Engagement
              </div>
            </div>
            <div className="text-[2rem] my-4">Project Deviations</div>

            <div
              className="my-2 text-[1rem] text-[#7E7E7E]"
              style={{ maxWidth: 600 }}
            >
              Measure Tasks Productivity In Gantt Chart Viewed By People Or By
              Projects.
            </div>
            <div className="flex items-center gap-4 text-[#FF9900]">
              <Link
                onClick={() => navigate('/contact-us')}
                className="flex text-[14px] items-center justify-center gap-4 hover:no-underline text-[#FF9900] hover:text-[#FF9900]"
              >
                Get In Touch <LinkSvg feature={true} />
              </Link>
            </div>
          </div>
          <div>
            <img
              src={'../../../assets/ProjectDeviation.png'}
              alt="TapAndTalk"
              width={'670px'}
              height={'570px'}
            />
          </div>
        </div>
        <div className="flex sticky flex-col lg:flex-row top-[6rem] z-[80] bg-white justify-between gap-4 w-full pt-4">
          <div>
            <div className="flex items-center gap-3">
              <div>
                <Upskill />
              </div>
              <div className="text-[12px] text-[#185D74] font-semibold">
                Engagement
              </div>
            </div>
            <div className="text-[2rem] my-4">Skill Deviations</div>

            <div
              className="my-2 text-[1rem] text-[#7E7E7E]"
              style={{ maxWidth: 600 }}
            >
              Measure Everyone On Different Skills With Custom Assessments And
              Custom Training Plan.
            </div>
            <div className="flex items-center gap-4 text-[#FF9900]">
              <Link
                onClick={() => navigate('/contact-us')}
                className="flex text-[14px] items-center justify-center gap-4 hover:no-underline text-[#FF9900] hover:text-[#FF9900]"
              >
                Get In Touch <LinkSvg feature={true} />
              </Link>
            </div>
          </div>
          <div>
            <img
              src={'../../../assets/SkillDevation.png'}
              alt="TapAndTalk"
              width={'670px'}
              height={'570px'}
            />
          </div>
        </div>
        <div className="flex sticky flex-col lg:flex-row top-[6rem] z-[90] bg-white justify-between gap-4 w-full pt-4">
          <div>
            <div className="flex items-center gap-3">
              <div>
                <Upskill />
              </div>
              <div className="text-[12px] text-[#185D74] font-semibold">
                Engagement
              </div>
            </div>
            <div className="text-[2rem] my-4">Wellness Nudges</div>

            <div
              className="my-2 text-[1rem] text-[#7E7E7E]"
              style={{ maxWidth: 600 }}
            >
              Auto Reminder For Constant Screen Time, Stretched Work
              {'Days (>8 hrs) etc.'}
            </div>
            <div className="flex items-center gap-4 text-[#FF9900]">
              <Link
                onClick={() => navigate('/contact-us')}
                className="flex text-[14px] items-center justify-center gap-4 hover:no-underline text-[#FF9900] hover:text-[#FF9900]"
              >
                Get In Touch <LinkSvg feature={true} />
              </Link>
            </div>
          </div>
          <div>
            <img
              src={'../../../assets/Wellness.png'}
              alt="TapAndTalk"
              width={'670px'}
              height={'570px'}
            />
          </div>
        </div>
        <div className="flex sticky flex-col lg:flex-row top-[6rem] z-[100] bg-white justify-between gap-4 w-full pt-4">
          <div>
            <div className="flex items-center gap-3">
              <div>
                <Stress />
              </div>
              <div className="text-[12px] text-[#185D74] font-semibold">
                Wellness
              </div>
            </div>
            <div className="text-[2rem] my-4">Stress and Burnout Rate</div>

            <div
              className="my-2 text-[1rem] text-[#7E7E7E]"
              style={{ maxWidth: 600 }}
            >
              Squad Has Pre-Built And Seamless Integration Of All The Business
              And Productivity Applications.
            </div>
            <div className="flex items-center gap-4 text-[#FF9900]">
              <Link
                onClick={() => navigate('/contact-us')}
                className="flex text-[14px] items-center justify-center gap-4 hover:no-underline text-[#FF9900] hover:text-[#FF9900]"
              >
                Get In Touch <LinkSvg feature={true} />
              </Link>
            </div>
          </div>
          <div>
            <img
              src={'../../../assets/StressNBurnout.png'}
              alt="TapAndTalk"
              width={'670px'}
              height={'570px'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
