import * as Types from '@worx.squad/app-sdk/base-types';

import { DocumentNode } from 'graphql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetOrgAwardsQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
}>;


export type GetOrgAwardsQuery = { __typename?: 'query_root', organization_awards: Array<{ __typename?: 'organization_awards', id: any, is_active: boolean, manager_permission: boolean, created_at: any, deleted_at?: any | null, award_types: Array<{ __typename?: 'awards', id: any, name: string, description?: string | null, cycle: Types.Enum_Award_Cycle_Type_Enum }> }> };

export type UpdateOrganizationAwardsByOrgIdMutationVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
  data: Types.Organization_Awards_Set_Input;
}>;


export type UpdateOrganizationAwardsByOrgIdMutation = { __typename?: 'mutation_root', update_organization_awards?: { __typename?: 'organization_awards_mutation_response', returning: Array<{ __typename?: 'organization_awards', id: any }> } | null };

export type InsertOrganizationAwardMutationVariables = Types.Exact<{
  object: Types.Organization_Awards_Insert_Input;
}>;


export type InsertOrganizationAwardMutation = { __typename?: 'mutation_root', insert_organization_awards_one?: { __typename?: 'organization_awards', organization_id: any } | null };

export type CreateNewAwardTypeMutationVariables = Types.Exact<{
  object: Types.CreateAwardTypeInput;
}>;


export type CreateNewAwardTypeMutation = { __typename?: 'mutation_root', createAwardType?: { __typename?: 'createAwardTypeOutput', name?: string | null } | null };

export type GetAllAwardsByOrgIdQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
}>;


export type GetAllAwardsByOrgIdQuery = { __typename?: 'query_root', awards: Array<{ __typename?: 'awards', id: any, name: string, cycle: Types.Enum_Award_Cycle_Type_Enum, description?: string | null, created_at: any, created_by: any, permissions?: string | null, limit?: number | null }> };

export type GetAwardDetailsByIdQueryVariables = Types.Exact<{
  awardId: Types.Scalars['uuid'];
}>;


export type GetAwardDetailsByIdQuery = { __typename?: 'query_root', awards: Array<{ __typename?: 'awards', id: any, name: string, cycle: Types.Enum_Award_Cycle_Type_Enum, description?: string | null, created_at: any, created_by: any, permissions?: string | null, limit?: number | null }> };

export type UpdateAwardsByIdMutationVariables = Types.Exact<{
  awardId: Types.Scalars['uuid'];
  data: Types.Awards_Set_Input;
}>;


export type UpdateAwardsByIdMutation = { __typename?: 'mutation_root', update_awards_by_pk?: { __typename?: 'awards', id: any } | null };

export type UpdateAwardsByOrgIdMutationVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
  data: Types.Awards_Set_Input;
}>;


export type UpdateAwardsByOrgIdMutation = { __typename?: 'mutation_root', update_awards?: { __typename?: 'awards_mutation_response', returning: Array<{ __typename?: 'awards', id: any }> } | null };

export type DeleteAwardsByIdMutationVariables = Types.Exact<{
  awardId: Types.Scalars['uuid'];
}>;


export type DeleteAwardsByIdMutation = { __typename?: 'mutation_root', delete_awards?: { __typename?: 'awards_mutation_response', affected_rows: number } | null, delete_awards_by_pk?: { __typename?: 'awards', id: any, name: string, cycle: Types.Enum_Award_Cycle_Type_Enum } | null };

export type CheckIsAwardActiveSubscriptionVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
}>;


export type CheckIsAwardActiveSubscription = { __typename?: 'subscription_root', organization_awards: Array<{ __typename?: 'organization_awards', id: any, is_active: boolean }> };

export type CheckIsManagerActiveSubscriptionVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
}>;


export type CheckIsManagerActiveSubscription = { __typename?: 'subscription_root', organization_awards: Array<{ __typename?: 'organization_awards', id: any, manager_permission: boolean }> };


export const GetOrgAwardsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getOrgAwards"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"orgId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"uuid"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organization_awards"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"organization_id"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"_eq"},"value":{"kind":"Variable","name":{"kind":"Name","value":"orgId"}}}]}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"is_active"}},{"kind":"Field","name":{"kind":"Name","value":"manager_permission"}},{"kind":"Field","name":{"kind":"Name","value":"created_at"}},{"kind":"Field","name":{"kind":"Name","value":"deleted_at"}},{"kind":"Field","name":{"kind":"Name","value":"award_types"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"cycle"}}]}}]}}]}}]} as unknown as DocumentNode;

export function useGetOrgAwardsQuery(options: Omit<Urql.UseQueryArgs<GetOrgAwardsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOrgAwardsQuery, GetOrgAwardsQueryVariables>({ query: GetOrgAwardsDocument, ...options });
};
export const UpdateOrganizationAwardsByOrgIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updateOrganizationAwardsByOrgId"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"orgId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"uuid"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"organization_awards_set_input"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"update_organization_awards"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"organization_id"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"_eq"},"value":{"kind":"Variable","name":{"kind":"Name","value":"orgId"}}}]}}]}},{"kind":"Argument","name":{"kind":"Name","value":"_set"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"returning"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode;

export function useUpdateOrganizationAwardsByOrgIdMutation() {
  return Urql.useMutation<UpdateOrganizationAwardsByOrgIdMutation, UpdateOrganizationAwardsByOrgIdMutationVariables>(UpdateOrganizationAwardsByOrgIdDocument);
};
export const InsertOrganizationAwardDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"InsertOrganizationAward"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"object"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"organization_awards_insert_input"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"insert_organization_awards_one"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"object"},"value":{"kind":"Variable","name":{"kind":"Name","value":"object"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organization_id"}}]}}]}}]} as unknown as DocumentNode;

export function useInsertOrganizationAwardMutation() {
  return Urql.useMutation<InsertOrganizationAwardMutation, InsertOrganizationAwardMutationVariables>(InsertOrganizationAwardDocument);
};
export const CreateNewAwardTypeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"createNewAwardType"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"object"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"createAwardTypeInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createAwardType"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"object"},"value":{"kind":"Variable","name":{"kind":"Name","value":"object"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode;

export function useCreateNewAwardTypeMutation() {
  return Urql.useMutation<CreateNewAwardTypeMutation, CreateNewAwardTypeMutationVariables>(CreateNewAwardTypeDocument);
};
export const GetAllAwardsByOrgIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getAllAwardsByOrgId"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"orgId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"uuid"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"awards"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"organization_id"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"_eq"},"value":{"kind":"Variable","name":{"kind":"Name","value":"orgId"}}}]}}]}},{"kind":"Argument","name":{"kind":"Name","value":"order_by"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"updated_at"},"value":{"kind":"EnumValue","value":"desc_nulls_last"}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"cycle"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"created_at"}},{"kind":"Field","name":{"kind":"Name","value":"created_by"}},{"kind":"Field","name":{"kind":"Name","value":"permissions"}},{"kind":"Field","name":{"kind":"Name","value":"limit"}}]}}]}}]} as unknown as DocumentNode;

export function useGetAllAwardsByOrgIdQuery(options: Omit<Urql.UseQueryArgs<GetAllAwardsByOrgIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllAwardsByOrgIdQuery, GetAllAwardsByOrgIdQueryVariables>({ query: GetAllAwardsByOrgIdDocument, ...options });
};
export const GetAwardDetailsByIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getAwardDetailsById"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"awardId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"uuid"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"awards"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"_eq"},"value":{"kind":"Variable","name":{"kind":"Name","value":"awardId"}}}]}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"cycle"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"created_at"}},{"kind":"Field","name":{"kind":"Name","value":"created_by"}},{"kind":"Field","name":{"kind":"Name","value":"permissions"}},{"kind":"Field","name":{"kind":"Name","value":"limit"}}]}}]}}]} as unknown as DocumentNode;

export function useGetAwardDetailsByIdQuery(options: Omit<Urql.UseQueryArgs<GetAwardDetailsByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAwardDetailsByIdQuery, GetAwardDetailsByIdQueryVariables>({ query: GetAwardDetailsByIdDocument, ...options });
};
export const UpdateAwardsByIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updateAwardsById"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"awardId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"uuid"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"awards_set_input"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"update_awards_by_pk"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"pk_columns"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"awardId"}}}]}},{"kind":"Argument","name":{"kind":"Name","value":"_set"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;

export function useUpdateAwardsByIdMutation() {
  return Urql.useMutation<UpdateAwardsByIdMutation, UpdateAwardsByIdMutationVariables>(UpdateAwardsByIdDocument);
};
export const UpdateAwardsByOrgIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updateAwardsByOrgId"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"orgId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"uuid"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"awards_set_input"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"update_awards"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"organization_id"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"_eq"},"value":{"kind":"Variable","name":{"kind":"Name","value":"orgId"}}}]}}]}},{"kind":"Argument","name":{"kind":"Name","value":"_set"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"returning"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode;

export function useUpdateAwardsByOrgIdMutation() {
  return Urql.useMutation<UpdateAwardsByOrgIdMutation, UpdateAwardsByOrgIdMutationVariables>(UpdateAwardsByOrgIdDocument);
};
export const DeleteAwardsByIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"deleteAwardsById"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"awardId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"uuid"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"delete_awards"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"_eq"},"value":{"kind":"Variable","name":{"kind":"Name","value":"awardId"}}}]}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"affected_rows"}}]}},{"kind":"Field","name":{"kind":"Name","value":"delete_awards_by_pk"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"awardId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"cycle"}}]}}]}}]} as unknown as DocumentNode;

export function useDeleteAwardsByIdMutation() {
  return Urql.useMutation<DeleteAwardsByIdMutation, DeleteAwardsByIdMutationVariables>(DeleteAwardsByIdDocument);
};
export const CheckIsAwardActiveDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"checkIsAwardActive"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"orgId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"uuid"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organization_awards"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"organization_id"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"_eq"},"value":{"kind":"Variable","name":{"kind":"Name","value":"orgId"}}}]}},{"kind":"ObjectField","name":{"kind":"Name","value":"is_active"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"_eq"},"value":{"kind":"BooleanValue","value":true}}]}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"is_active"}}]}}]}}]} as unknown as DocumentNode;

export function useCheckIsAwardActiveSubscription<TData = CheckIsAwardActiveSubscription>(options: Omit<Urql.UseSubscriptionArgs<CheckIsAwardActiveSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<CheckIsAwardActiveSubscription, TData>) {
  return Urql.useSubscription<CheckIsAwardActiveSubscription, TData, CheckIsAwardActiveSubscriptionVariables>({ query: CheckIsAwardActiveDocument, ...options }, handler);
};
export const CheckIsManagerActiveDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"checkIsManagerActive"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"orgId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"uuid"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organization_awards"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"organization_id"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"_eq"},"value":{"kind":"Variable","name":{"kind":"Name","value":"orgId"}}}]}},{"kind":"ObjectField","name":{"kind":"Name","value":"manager_permission"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"_eq"},"value":{"kind":"BooleanValue","value":true}}]}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"manager_permission"}}]}}]}}]} as unknown as DocumentNode;

export function useCheckIsManagerActiveSubscription<TData = CheckIsManagerActiveSubscription>(options: Omit<Urql.UseSubscriptionArgs<CheckIsManagerActiveSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<CheckIsManagerActiveSubscription, TData>) {
  return Urql.useSubscription<CheckIsManagerActiveSubscription, TData, CheckIsManagerActiveSubscriptionVariables>({ query: CheckIsManagerActiveDocument, ...options }, handler);
};