const Stress = ({ isWhite }: any) => {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8197 1.57961L9.99895 2.40211L9.1757 1.57886C7.07663 -0.520204 3.67337 -0.520204 1.5743 1.57886C-0.524767 3.67793 -0.524767 7.08119 1.5743 9.18026L9.46967 17.0756C9.76256 17.3685 10.2374 17.3685 10.5303 17.0756L18.4318 9.1788C20.5262 7.07277 20.5298 3.67906 18.4303 1.57961C16.3274 -0.523282 12.9226 -0.523279 10.8197 1.57961Z"
        fill={isWhite ? 'white' : '#185D74'}
      />
    </svg>
  );
};

export default Stress;
