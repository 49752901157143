const DeploymentFriendly = () => {
  return (
    <svg
      width="313"
      height="270"
      viewBox="0 0 313 270"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.87">
        <path
          d="M56.2434 72.3677C55.2759 73.9872 53.2509 74.6724 51.5093 75.0219C49.2839 75.4787 46.9826 75.5998 44.7295 75.8005L43.4199 76.1223C43.1721 76.1988 42.9416 76.3237 42.7422 76.4895C42.5425 76.6556 42.3777 76.8594 42.2571 77.0892C42.1361 77.319 42.0622 77.5705 42.0387 77.8294C42.0152 78.0879 42.0432 78.3488 42.1206 78.5966C42.9309 81.3892 44.0457 84.0835 45.4448 86.6318L43.3715 88.7081C42.7841 87.9122 42.7564 86.5176 41.9685 86.6526C41.2118 85.8947 40.3133 85.5245 39.3838 86.5384C37.5662 88.5282 37.8599 92.6635 40.531 93.8054L39.9332 96.7849C39.7269 97.8265 39.1668 98.7647 38.3478 99.4391C37.5285 100.114 36.5015 100.483 35.441 100.484L35.9006 96.9579C32.7906 98.5325 29.2625 95.1273 29.5078 92.3209C29.6668 90.3968 29.2314 89.068 27.8111 87.6734C27.7178 87.58 28.288 87.234 28.2016 87.1405C27.8008 85.9259 27.7455 84.5071 28.1429 83.7043C28.834 82.2716 30.8693 81.4342 29.9328 79.87C29.522 79.1883 29.222 78.4454 29.0448 77.6692C28.8568 76.64 28.891 75.5825 29.1457 74.5675C29.4003 73.5529 29.8689 72.6044 30.5203 71.7863C31.9025 70.1253 34.1486 70.5717 35.9835 69.9834C38.2193 69.2913 39.2525 66.8932 41.5919 66.1527C42.7564 65.7824 43.8345 65.9519 44.9991 66.2184C45.9839 66.4468 46.9584 66.7029 47.9259 67.0074C48.8935 67.3119 49.7435 67.5645 50.6904 67.2808C51.9413 66.9347 52.9054 65.7651 54.2703 65.7547C54.9514 65.7391 55.618 65.9523 56.164 66.3603C57.173 68.177 57.1799 70.8139 56.2434 72.3677Z"
          fill="#FF9900"
        />
        <path
          d="M13.4784 119.224C13.4784 119.224 -24.1422 77.6703 31.2606 56.935C34.4732 55.7554 37.8217 54.9857 41.2264 54.6442C50.0692 53.7029 69.6173 50.3566 71.8703 39.0547"
          stroke="#CCDDE2"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M171.404 61.0533V61.043"
          stroke="#CCDDE2"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M72.6934 135.872V135.861"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M43.2791 88.4312C42.9107 87.7938 42.4725 87.1992 41.9729 86.6594C41.2161 85.9016 40.3176 85.5313 39.3881 86.5452C37.5705 88.535 37.8642 92.6703 40.5353 93.8122L39.9375 96.7917C39.7312 97.8333 39.1711 98.7711 38.3521 99.4456C37.5328 100.12 36.5058 100.49 35.4453 100.491"
          stroke="black"
          stroke-width="1.42226"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M33.7734 113.677C33.9255 112.521 34.3021 109.486 34.665 106.593C35.0105 103.911 35.3181 101.33 35.439 100.478L35.9021 96.959"
          stroke="black"
          stroke-width="1.42226"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M55.1081 78.998C55.1081 78.998 50.6953 79.2161 49.8418 82.8738"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M56.7113 88.664C57.9138 88.6329 59.4757 88.4564 60.0424 87.2348L57.0015 83.8747C56.2596 83.0515 55.8208 81.9991 55.7575 80.8918C55.6366 79.0335 55.8543 76.0851 54.0781 74.123"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M54.1115 84.0124C54.6095 83.4608 54.634 82.6712 54.1668 82.2483C53.6996 81.8251 52.9173 81.9296 52.4197 82.4812C51.9217 83.0324 51.8968 83.8225 52.364 84.2453C52.8312 84.6682 53.6136 84.564 54.1115 84.0124Z"
          fill="black"
        />
        <path
          d="M53.8125 110.735L51.5215 99.6133"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M111.889 160.79L97.1233 172.881C114.888 200.011 101.443 204.991 101.443 204.991C98.8345 205.491 96.201 205.845 93.5537 206.053C80.7129 206.98 67.5403 203.662 56.956 196.229C35.5315 181.176 16.8715 151.049 26.8235 124.818C27.6608 122.6 28.684 120.455 29.8817 118.409C30.9139 116.631 32.228 115.032 33.7726 113.675C36.4763 111.305 39.8057 109.767 43.3611 109.246C46.9162 108.725 50.5463 109.243 53.8149 110.737C55.0661 111.309 56.2496 112.02 57.343 112.855C62.9375 117.143 68.0241 123.237 72.4092 129.632C76.3026 135.415 79.8065 141.452 82.8968 147.702C83.7503 149.408 84.5244 151.021 85.2086 152.499C91.4113 149.986 96.6119 145.719 101.45 141.11C95.5994 152.045 111.889 160.79 111.889 160.79Z"
          fill="black"
        />
        <path
          d="M101.556 169.635C101.273 169.853 100.979 170.081 100.686 170.299"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M57.9023 123.67C61.8185 131.179 65.735 138.702 69.6512 146.239C72.6057 151.928 77.599 155.441 84.631 152.745C92.2816 149.81 98.4533 144.201 104.162 138.484C109.87 132.768 115.655 127.3 123.897 125.826C134.55 123.919 144.63 129.733 151.886 137.079C152.445 137.64 152.798 138.372 152.889 139.158C152.98 139.945 152.803 140.739 152.388 141.412C152.121 141.842 151.765 142.211 151.343 142.491C150.922 142.771 150.445 142.957 149.945 143.036C149.445 143.115 148.934 143.086 148.447 142.949C147.96 142.813 147.508 142.573 147.121 142.246C143.683 139.308 138.088 135.058 134.198 135.003"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M123.582 145.084C124.778 145.883 126.436 146.769 127.819 146.769"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M138.904 136.461C141.454 137.724 142.384 141.42 140.583 143.628C138.301 146.464 135.44 148.781 132.193 150.424C128.641 152.199 126.36 151.116 122.863 148.95"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M133.002 139.771C133.002 139.771 138.054 141.024 137.919 145.935C137.91 146.144 137.873 146.351 137.809 146.551"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M129.434 142.865C129.434 142.865 136.21 147.25 132.986 150.008"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M260.333 160.05C252.35 182.02 230.003 182.64 227.446 182.64C228.517 180.38 228.808 176.411 226.461 169.455C228.033 167.157 228.86 164.43 228.831 161.644C228.802 158.858 227.918 156.149 226.299 153.883C226.33 153.856 226.347 153.817 226.375 153.79C227.492 153.722 228.601 153.554 229.689 153.288C233.545 152.368 241.147 150.347 247.181 147.502C251.272 145.568 256.044 148.668 255.695 153.191C255.695 153.25 255.695 153.305 255.674 153.371C256.473 153.371 257.261 153.563 257.969 153.933C258.677 154.302 259.286 154.838 259.744 155.494C260.201 156.15 260.493 156.906 260.596 157.699C260.699 158.493 260.608 159.299 260.333 160.05Z"
          fill="#FF9900"
        />
        <path
          d="M157.9 211.581C155.212 216.201 151.027 220.135 146.984 223.568C132.751 235.631 114.156 241.756 95.4965 241.995C76.7743 241.756 58.1108 235.61 43.8118 223.654C-1.36965 185.873 6.79583 141.264 14.1043 120.608C14.3617 119.894 14.7102 119.216 15.141 118.59C15.7124 117.753 16.4543 117.047 17.3179 116.517C18.1815 115.988 19.147 115.647 20.1516 115.518C23.9872 116.047 27.4704 119.234 26.8554 124.169C26.8554 124.359 26.7793 124.622 26.7621 124.812C16.8169 151.043 35.4839 181.17 56.8945 196.223C66.608 203.051 78.5021 206.407 90.3201 206.189H90.4548C91.4683 206.169 92.4808 206.122 93.4923 206.047C100.13 205.563 106.582 203.971 112.971 202.044C120.47 199.798 127.885 197.074 135.377 195.168C138.01 194.496 140.65 193.849 143.304 193.244C144.836 192.929 146.402 192.821 147.962 192.922C157.164 193.545 163.63 201.739 157.9 211.581Z"
          fill="#D7E8F0"
        />
        <path
          d="M160.175 211.581C157.486 216.201 153.298 220.135 149.255 223.568C134.455 236.123 114.928 242.255 95.4866 241.995C76.7644 241.756 58.101 235.61 43.8019 223.654C-1.36906 185.873 6.79641 141.264 14.1049 120.608C14.3623 119.894 14.7108 119.216 15.1416 118.59C15.713 117.753 16.4549 117.047 17.3185 116.517C18.1821 115.988 19.1479 115.647 20.1521 115.518C23.9878 116.047 27.471 119.234 26.8559 124.169C26.8559 124.359 26.7798 124.622 26.7626 124.812C16.8175 151.043 35.4844 181.17 56.895 196.223C66.6086 203.051 78.5026 206.407 90.3206 206.189H90.4553C92.236 206.217 94.0066 206.17 95.7665 206.047C102.405 205.563 108.853 203.971 115.245 202.044C122.744 199.798 130.16 197.074 137.651 195.168C140.281 194.496 142.924 193.849 145.575 193.244C146.363 193.082 147.16 192.975 147.963 192.922C158.309 192.226 166.367 200.943 160.175 211.581Z"
          fill="#D7E8F0"
        />
        <path
          d="M98.6059 241.932L103.153 248.375C103.153 248.375 130.67 249.448 145.729 262.106C148.514 264.449 146.849 269 143.214 269C142.302 269.003 141.419 268.682 140.722 268.093C137.166 265.103 123.583 255.704 92.8559 255.421C62.917 255.14 51.4341 264.418 48.2585 267.799C47.8964 268.181 47.4599 268.484 46.9761 268.691C46.4924 268.897 45.9716 269.002 45.4457 269H44.8548C41.1228 269 39.5436 264.28 42.4912 261.989C55.0625 252.199 83.2425 249.078 83.2425 249.078L88.0527 241.811"
          fill="#353642"
        />
        <path
          d="M220.384 206.203C220.384 206.203 228.812 214.221 226.182 239.078C225.991 240.853 225.375 242.555 224.386 244.04L223.47 245.424C221.968 247.676 219.704 249.309 217.094 250.02C211.59 251.525 201.603 255.013 195.435 261.623C192.829 264.422 194.768 268.983 198.59 268.983C199.302 268.985 200.005 268.811 200.633 268.475C201.262 268.139 201.798 267.653 202.194 267.059C204.706 263.274 212.312 255.235 230.657 255.404C251.349 255.595 259.684 263.834 262.355 267.385C262.724 267.88 263.203 268.282 263.755 268.559C264.306 268.837 264.914 268.982 265.531 268.983C266.304 268.982 267.06 268.754 267.706 268.329C268.352 267.903 268.861 267.298 269.168 266.588C269.476 265.877 269.57 265.092 269.439 264.329C269.308 263.566 268.957 262.857 268.43 262.291C260.361 253.511 250.492 250.238 245.046 249.023C242.31 248.433 239.893 246.839 238.27 244.556C237.027 242.794 236.32 240.71 236.235 238.555C235.931 230.849 235.772 212.141 240.986 206.21"
          fill="black"
        />
        <path
          d="M113.039 202.053C127.86 194.848 143.946 190.121 159.762 185.671C175.678 181.193 191.75 177.286 207.87 173.614C214.311 172.151 220.811 170.846 227.221 169.195"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M126.945 146.67H126.956H126.963"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M125.282 145.731C124.352 146.652 121.035 151.569 120.202 152.452C115.627 157.362 110.767 161.998 105.648 166.335C104.359 167.408 102.994 168.515 101.556 169.637C101.273 169.855 100.979 170.083 100.685 170.301C90.9407 177.706 78.2243 184.835 66.4754 180.267C66.1644 180.153 65.8603 180.021 65.5597 179.886C47.5389 171.806 34.9435 151.569 33.8446 132.035C33.8066 131.398 33.7858 130.765 33.7789 130.138C33.6822 124.799 34.47 119.459 39.5843 116.643"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M26.8506 124.744L26.9232 124.176C28.0705 114.964 15.0085 111.857 11.9089 120.608C4.60383 141.26 -3.5651 185.859 41.6266 223.648C70.7949 248.048 118.019 248.217 147.076 223.568C151.119 220.139 155.304 216.197 157.996 211.578C164.807 199.895 154.405 190.527 142.674 193.403"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M98.6059 241.932L103.153 248.375C103.153 248.375 130.67 249.448 145.729 262.106C148.514 264.449 146.849 269 143.214 269C142.302 269.003 141.419 268.682 140.722 268.093C137.166 265.103 123.583 255.704 92.8559 255.421C62.917 255.14 51.4341 264.418 48.2585 267.799C47.8964 268.181 47.4599 268.484 46.9761 268.691C46.4924 268.897 45.9716 269.002 45.4457 269H44.8548C41.1228 269 39.5436 264.28 42.4912 261.989C55.0625 252.199 83.2425 249.078 83.2425 249.078L88.0527 241.811"
          fill="black"
        />
        <path
          d="M98.6059 241.932L103.153 248.375C103.153 248.375 130.67 249.448 145.729 262.106C148.514 264.449 146.849 269 143.214 269C142.302 269.003 141.419 268.682 140.722 268.093C137.166 265.103 123.583 255.704 92.8559 255.421C62.917 255.14 51.4341 264.418 48.2585 267.799C47.8964 268.181 47.4599 268.484 46.9761 268.691C46.4924 268.897 45.9716 269.002 45.4457 269H44.8548C41.1228 269 39.5436 264.28 42.4912 261.989C55.0625 252.199 83.2425 249.078 83.2425 249.078L88.0527 241.811"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M83.2441 147.151C94.7788 144.037 99.5302 127.741 101.445 116.785"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M72.4004 129.644C75.6047 129.223 78.648 127.987 81.239 126.053C83.8303 124.12 85.8829 121.553 87.2005 118.598C87.4763 118.01 87.6104 117.366 87.5924 116.717C87.5744 116.067 87.4047 115.432 87.0969 114.86C85.839 112.528 83.9627 109.825 84.4084 107.029C84.7298 105.057 86.3505 103.669 88.2095 103.223C89.1519 102.975 90.1443 102.992 91.0777 103.271C91.6371 103.43 92.1544 103.711 92.5919 104.095C93.0294 104.479 93.3759 104.955 93.6071 105.489C94.0598 106.552 93.5207 107.604 92.6879 108.296C91.8552 108.988 90.8807 109.234 89.8648 109.51C89.7335 109.549 89.3741 109.625 88.9767 109.701"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M227.207 169.189C227.207 169.189 249.945 164.42 254.216 157.551C255.016 156.299 255.515 154.877 255.674 153.398"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M115.324 157.458C154.984 153.24 199.747 153.862 199.747 153.862H224.869C226.492 153.865 228.11 153.674 229.689 153.295C233.546 152.374 241.148 150.357 247.181 147.509C251.273 145.575 256.045 148.675 255.696 153.201C255.696 153.257 255.696 153.316 255.675 153.381C256.472 153.382 257.257 153.575 257.964 153.943C258.671 154.312 259.279 154.846 259.736 155.5C260.193 156.154 260.486 156.909 260.59 157.7C260.694 158.492 260.606 159.296 260.333 160.046C251.86 183.367 227.208 182.636 227.208 182.636C224.057 182.73 220.912 182.854 217.757 183.017C189.964 184.37 162.416 188.277 135.449 195.174C127.958 197.08 120.542 199.8 113.044 202.05C106.651 203.984 100.192 205.566 93.5542 206.05C80.7134 206.981 67.5408 203.659 56.9564 196.226C33.7731 179.934 13.8208 145.966 29.8822 118.392C35.5527 108.662 48.3936 105.997 57.3435 112.856C69.9666 122.528 80.0499 141.37 85.2229 152.485"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M259.807 206.203H204.615"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M193.475 195.038C193.483 192.079 194.66 189.245 196.748 187.152C198.837 185.06 201.668 183.88 204.622 183.871H259.821C262.776 183.879 265.608 185.058 267.697 187.151C269.787 189.243 270.964 192.079 270.972 195.038"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M272.059 198.998C272.061 199.945 271.877 200.883 271.516 201.759C271.155 202.635 270.625 203.43 269.956 204.1C269.287 204.769 268.493 205.3 267.618 205.661C266.744 206.022 265.807 206.206 264.861 206.203H199.551C197.916 206.047 196.407 205.256 195.349 203.999C194.29 202.742 193.767 201.119 193.891 199.479C194.015 197.839 194.777 196.313 196.012 195.23C197.248 194.147 198.858 193.592 200.498 193.686H265.808C266.967 193.684 268.11 193.963 269.137 194.501C270.165 195.038 271.048 195.816 271.71 196.769C271.941 197.489 272.059 198.241 272.059 198.998Z"
          fill="#D7E8F0"
        />
        <path
          d="M272.057 198.997C272.06 199.944 271.875 200.883 271.515 201.758C271.154 202.634 270.624 203.429 269.955 204.099C269.286 204.768 268.491 205.299 267.617 205.66C266.742 206.021 265.805 206.205 264.859 206.202H199.549C198.591 206.223 197.638 206.052 196.746 205.699C195.855 205.346 195.043 204.819 194.358 204.148C193.673 203.476 193.128 202.675 192.757 201.79C192.385 200.906 192.193 199.955 192.193 198.995C192.193 198.035 192.385 197.085 192.757 196.2C193.128 195.315 193.673 194.514 194.358 193.843C195.043 193.171 195.855 192.644 196.746 192.291C197.638 191.939 198.591 191.768 199.549 191.789H264.859C266.38 191.788 267.861 192.27 269.092 193.164C270.322 194.059 271.238 195.32 271.708 196.769C271.939 197.489 272.057 198.241 272.057 198.997Z"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M101.319 116.527L101.187 117.638C101.127 118.142 100.885 118.607 100.506 118.944C100.127 119.282 99.6383 119.468 99.1314 119.469H91.9542C91.4473 119.468 90.9583 119.282 90.5792 118.944C90.2005 118.607 89.9583 118.142 89.8982 117.638L88.9721 109.679C89.3695 109.603 89.7288 109.527 89.8601 109.489C90.8761 109.212 91.8609 108.977 92.6833 108.274C93.5058 107.572 94.0656 106.544 93.6025 105.468C93.3713 104.933 93.0247 104.457 92.5873 104.073C92.1498 103.69 91.6325 103.408 91.073 103.249C90.1394 102.971 89.1473 102.954 88.2049 103.201L87.9872 101.305C87.9551 101.015 87.9845 100.722 88.0733 100.444C88.1617 100.166 88.3079 99.9105 88.5018 99.6931C88.6956 99.4755 88.9334 99.3014 89.1988 99.182C89.4645 99.0626 89.7523 99.0007 90.0433 99H101.056C101.347 99.001 101.634 99.0637 101.9 99.1831C102.165 99.3028 102.402 99.4769 102.595 99.6942C102.789 99.9115 102.935 100.167 103.023 100.445C103.112 100.722 103.141 101.015 103.109 101.305L102.853 103.467"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M102.276 108.412L102.266 108.488"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M101.736 112.984V112.953"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M101.84 112.984C101.809 112.978 101.777 112.978 101.746 112.984C101.703 112.979 101.659 112.979 101.615 112.984C101.167 112.985 100.736 113.155 100.408 113.46C100.079 113.764 99.8766 114.182 99.8403 114.629C99.8044 115.075 99.9374 115.52 100.213 115.873C100.488 116.227 100.886 116.464 101.328 116.538C101.519 116.576 101.715 116.576 101.905 116.538C102.768 116.4 103.555 115.964 104.131 115.306C104.898 114.043 105.43 112.651 105.703 111.198C105.708 111.183 105.708 111.168 105.703 111.153C105.914 110.082 105.994 108.99 105.941 107.9M105.941 107.9C105.941 107.433 105.883 106.969 105.827 106.516C105.722 105.757 105.376 105.053 104.84 104.507C104.304 103.96 103.608 103.601 102.852 103.481C102.814 103.481 102.769 103.481 102.731 103.46C101.28 103.28 99.2446 103.831 98.868 105.471C98.5224 106.952 100.126 108.222 101.442 108.336C101.442 108.336 101.788 108.381 102.275 108.412C103.25 108.478 104.87 108.468 105.941 107.9ZM105.941 107.9C106.099 107.818 106.247 107.72 106.384 107.606"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M103.354 108.492H103.116H101.274H100.462C99.913 108.494 99.3856 108.706 98.9879 109.085C98.5898 109.464 98.3521 109.981 98.3231 110.53C98.2961 111.064 98.4678 111.588 98.8051 112.003C99.142 112.417 99.6203 112.691 100.148 112.773C102.532 113.157 105.255 113.237 105.721 111.098"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M220.384 206.203C220.384 206.203 228.812 214.221 226.182 239.078C225.991 240.853 225.375 242.555 224.386 244.04L223.47 245.424C221.968 247.676 219.704 249.309 217.094 250.02C211.59 251.525 201.603 255.013 195.435 261.623C192.829 264.422 194.768 268.983 198.59 268.983C199.302 268.985 200.005 268.811 200.633 268.475C201.262 268.139 201.798 267.653 202.194 267.059C204.706 263.274 212.312 255.235 230.657 255.404C251.349 255.595 259.684 263.834 262.355 267.385C262.724 267.88 263.203 268.282 263.755 268.559C264.306 268.837 264.914 268.982 265.531 268.983C266.304 268.982 267.06 268.754 267.706 268.329C268.352 267.903 268.861 267.298 269.168 266.588C269.476 265.877 269.57 265.092 269.439 264.329C269.308 263.566 268.957 262.857 268.43 262.291C260.361 253.511 250.492 250.238 245.046 249.023C242.31 248.433 239.893 246.839 238.27 244.556C237.027 242.794 236.32 240.71 236.235 238.555C235.931 230.849 235.772 212.141 240.986 206.21"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M202.862 148.104V148.664C202.862 152.502 199.773 153.163 197.105 153.229L145.476 154.433C143.212 154.488 141.308 152.509 141.26 150.031V149.927C141.26 147.505 143.043 145.481 145.275 145.429C145.275 145.429 174.16 144.612 196.524 143.996C196.953 145.353 197.99 146.398 199.289 147.218C200.365 147.862 201.609 148.171 202.862 148.104Z"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M230.307 93.3935C230.31 94.025 230.165 94.6479 229.885 95.2137L206.795 142.615C206.524 143.166 206.137 143.65 205.659 144.035C205.181 144.419 204.626 144.694 204.031 144.841C203.077 145.085 202.068 144.982 201.183 144.55C200.474 144.2 199.878 143.658 199.461 142.985C199.045 142.312 198.827 141.535 198.83 140.743C198.832 140.549 198.846 140.355 198.872 140.162C198.933 139.733 199.061 139.316 199.252 138.927L222.338 91.5179C222.755 90.6673 223.448 89.9828 224.303 89.5765C225.158 89.1706 226.125 89.0661 227.047 89.281C227.969 89.4956 228.79 90.0167 229.379 90.759C229.967 91.5016 230.287 92.4214 230.286 93.3692L230.307 93.3935Z"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M43.3765 88.7152C43.3488 88.6183 43.3107 88.5249 43.2796 88.4315C43.2485 88.338 43.2035 88.2308 43.1758 88.127"
          stroke="black"
          stroke-width="1.42226"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M43.375 88.7195L44.0834 88.0066L45.438 86.6328"
          stroke="black"
          stroke-width="1.42226"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M51.3477 90.5957C51.3477 90.5957 52.2565 93.3122 55.7293 93.4956"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M35.9006 96.9593C32.7906 98.5339 29.2625 95.1287 29.5079 92.3223C29.6668 90.3983 29.2314 89.0698 27.8112 87.6748C27.7179 87.5814 27.6315 87.4845 27.5486 87.3911C27.0078 86.8021 26.6563 86.064 26.5392 85.2722C26.422 84.4808 26.5451 83.6721 26.892 82.9513C27.5382 81.446 28.9308 80.5635 27.9632 78.9406C27.5385 78.2388 27.2286 77.4737 27.044 76.6739C26.8509 75.6137 26.8872 74.5243 27.1501 73.4789C27.4131 72.4338 27.8965 71.4573 28.5679 70.6146C29.9916 68.8844 32.3103 69.3619 34.2143 68.7563C36.5053 68.0262 37.5766 65.5589 39.9816 64.801C41.1842 64.4238 42.3003 64.5934 43.5028 64.8668C44.5153 65.1055 45.5313 65.372 46.5161 65.6834C47.5009 65.9949 48.3994 66.2613 49.3842 65.9672C50.6731 65.59 51.6649 64.4031 53.0678 64.3927C54.4708 64.3823 55.5489 65.2093 56.1778 66.3651C57.173 68.1784 57.1799 70.8153 56.2435 72.3795C55.2759 73.999 53.251 74.6842 51.5094 75.0337C49.284 75.4905 46.9826 75.6116 44.7296 75.8123L43.4199 76.1341C42.9216 76.2891 42.5052 76.6352 42.2616 77.0972C42.018 77.5591 41.9672 78.099 42.1206 78.598C42.931 81.3906 44.0457 84.0853 45.4449 86.6332"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M45.4375 97.3557C45.4375 97.3557 52.2933 102.384 56.2015 99.6119C60.1098 96.8401 57.9017 88.7148 57.9017 88.7148"
          stroke="black"
          stroke-width="1.42226"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M93.8822 95.5292C93.8822 95.5292 90.9692 93.5568 92.8214 91.5428C94.6736 89.5287 92.2063 86.8711 92.2063 86.8711"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M96.5703 95.872C96.5703 95.872 98.4985 94.5674 97.2614 93.2316C96.0243 91.8959 97.6726 90.1172 97.6726 90.1172"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M109.856 77.0754C130.834 77.0754 147.84 60.0453 147.84 39.0377C147.84 18.0301 130.834 1 109.856 1C88.8789 1 71.873 18.0301 71.873 39.0377C71.873 60.0453 88.8789 77.0754 109.856 77.0754Z"
          stroke="#CCDDE2"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M109.857 59.9284C121.378 59.9284 130.718 50.5751 130.718 39.0375C130.718 27.4995 121.378 18.1465 109.857 18.1465C98.3358 18.1465 88.9961 27.4995 88.9961 39.0375C88.9961 50.5751 98.3358 59.9284 109.857 59.9284Z"
          stroke="#CCDDE2"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M109.858 42.6183C111.833 42.6183 113.434 41.0147 113.434 39.0367C113.434 37.0587 111.833 35.4551 109.858 35.4551C107.883 35.4551 106.281 37.0587 106.281 39.0367C106.281 41.0147 107.883 42.6183 109.858 42.6183Z"
          stroke="#CCDDE2"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M117.262 27.0781L111.74 35.9923"
          stroke="#CCDDE2"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M109.857 18.1465V21.863"
          stroke="#CCDDE2"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M130.719 39.0371H127.201"
          stroke="#CCDDE2"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M109.857 59.9311V56.3184"
          stroke="#CCDDE2"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M88.9941 39.0371H92.1663"
          stroke="#CCDDE2"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <path
          d="M146.262 102.281C154.068 102.281 160.395 95.9446 160.395 88.128C160.395 80.3111 154.068 73.9746 146.262 73.9746C138.457 73.9746 132.129 80.3111 132.129 88.128C132.129 95.9446 138.457 102.281 146.262 102.281Z"
          fill="#FF9900"
        />
        <path
          d="M150.91 83.4013C150.71 82.9411 150.442 82.5141 150.115 82.1348C149.555 81.4714 148.772 81.0351 147.914 80.9077C147.056 80.7804 146.181 80.9707 145.453 81.4427C144.979 81.7528 144.582 82.1687 144.294 82.658C144.006 83.1473 143.835 83.6965 143.795 84.263C143.051 83.99 142.238 83.9664 141.48 84.1962C140.722 84.4257 140.058 84.8963 139.59 85.5365C139.122 86.1767 138.875 86.9518 138.885 87.7453C138.895 88.5384 139.163 89.307 139.648 89.9348C140.661 91.2567 142.381 92.1252 143.94 92.6305C144.757 92.8924 145.599 93.071 146.452 93.1634C146.884 93.2118 149.348 93.544 149.562 93.1634C151.1 90.7549 152.192 86.3428 150.91 83.4013Z"
          fill="#F1F8F9"
        />
        <g opacity="0.72">
          <path
            opacity="0.72"
            d="M181.487 82.2677C185.785 82.2677 189.269 78.7788 189.269 74.4747C189.269 70.1708 185.785 66.6816 181.487 66.6816C177.189 66.6816 173.705 70.1708 173.705 74.4747C173.705 78.7788 177.189 82.2677 181.487 82.2677Z"
            fill="#FF9900"
          />
          <path
            opacity="0.72"
            d="M184.862 75.8558C185.055 75.6592 185.216 75.4339 185.339 75.1879C185.561 74.7636 185.621 74.2726 185.507 73.8075C185.393 73.3424 185.114 72.9348 184.721 72.6617C184.464 72.4842 184.17 72.3686 183.861 72.324C183.552 72.2797 183.237 72.3077 182.941 72.4057C182.925 71.9689 182.766 71.5495 182.491 71.2108C182.215 70.872 181.837 70.6322 181.413 70.5273C180.99 70.4228 180.544 70.4588 180.142 70.6304C179.741 70.8017 179.406 71.0993 179.188 71.4782C178.765 72.3351 178.601 73.2974 178.715 74.2466C178.754 74.7152 178.839 75.1789 178.971 75.6308C179.036 75.8627 179.382 77.1881 179.617 77.2296C181.162 77.4891 183.636 77.1258 184.862 75.8558Z"
            fill="#F1F8F9"
          />
        </g>
        <path
          d="M170.336 111.276L166.637 119.109C165.295 121.95 166.507 125.341 169.343 126.684L177.165 130.389C180.002 131.732 183.388 130.518 184.73 127.678L188.429 119.845C189.77 117.004 188.558 113.613 185.722 112.27L177.9 108.565C175.064 107.222 171.677 108.435 170.336 111.276Z"
          fill="#FF9900"
        />
        <path
          d="M186.317 121.216L183.639 126.888C183.077 128.07 182.071 128.98 180.842 129.422C179.611 129.863 178.257 129.799 177.074 129.245L170.28 126.026C169.101 125.463 168.192 124.456 167.752 123.224C167.312 121.992 167.376 120.636 167.93 119.452L168.11 119.071C169.955 117.597 173.145 115.61 174.309 118.313C175.985 122.216 178.166 120.957 180.951 119.324C183.276 117.946 186.069 117.486 186.317 121.216Z"
          fill="#F1F8F9"
        />
        <path
          d="M175.798 115.189C176.952 115.189 177.888 114.252 177.888 113.096C177.888 111.939 176.952 111.002 175.798 111.002C174.643 111.002 173.707 111.939 173.707 113.096C173.707 114.252 174.643 115.189 175.798 115.189Z"
          fill="#F1F8F9"
        />
        <path
          d="M211.465 85.5843C211.88 86.3432 212.182 87.1595 212.36 88.0066C212.676 89.5563 212.585 91.1619 212.096 92.6655C211.606 94.1694 210.735 95.5201 209.568 96.5852C208.642 97.4053 207.463 98.0455 206.527 98.9037C205.844 99.4941 205.34 100.265 205.075 101.129C205.027 101.424 204.908 101.703 204.73 101.942C204.184 102.53 203.648 101.596 203.175 101.437C202.412 101.188 201.584 101.229 200.849 101.551C198.917 102.36 196.764 102.475 194.756 101.877C192.749 101.278 191.01 100.002 189.834 98.266C188.658 96.5298 188.119 94.4397 188.306 92.3506C188.494 90.2612 189.397 88.3011 190.863 86.8024C192.311 85.3248 194.204 84.38 196.046 83.418C197.656 82.5633 199.26 81.6532 201.008 81.141C205.106 79.9126 209.481 81.9577 211.465 85.5843Z"
          fill="#FF9900"
        />
        <path
          d="M194.96 96.2731C196.048 96.2731 196.93 95.39 196.93 94.3006C196.93 93.2112 196.048 92.3281 194.96 92.3281C193.872 92.3281 192.99 93.2112 192.99 94.3006C192.99 95.39 193.872 96.2731 194.96 96.2731Z"
          fill="#F1F8F9"
        />
        <path
          d="M200.267 93.4313C201.354 93.4313 202.236 92.5482 202.236 91.4588C202.236 90.3694 201.354 89.4863 200.267 89.4863C199.179 89.4863 198.297 90.3694 198.297 91.4588C198.297 92.5482 199.179 93.4313 200.267 93.4313Z"
          fill="#F1F8F9"
        />
        <path
          d="M205.241 90.7672C206.329 90.7672 207.211 89.8841 207.211 88.7947C207.211 87.7054 206.329 86.8223 205.241 86.8223C204.153 86.8223 203.271 87.7054 203.271 88.7947C203.271 89.8841 204.153 90.7672 205.241 90.7672Z"
          fill="#F1F8F9"
        />
        <path
          d="M145.186 25.0527C145.186 25.0527 158.154 65.2221 208.077 63.0524C224.663 60.9761 244.405 63.0524 261.983 86.2376C288.705 121.507 277.976 160.797 265.833 185.737"
          stroke="#CCDDE2"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
        <g opacity="0.54">
          <path
            opacity="0.54"
            d="M217.348 40.8294C217.299 41.4353 217.205 42.0367 217.068 42.6288L215.513 49.3837C215.036 51.46 214.5 53.6332 213.021 55.1489C210.257 57.9726 205.661 57.3255 201.819 56.4708L192.385 54.3737C190.218 53.8927 188.017 53.3979 186.075 52.3216C184.133 51.2454 182.447 49.484 181.997 47.3074C181.652 45.7017 182.022 44.0407 182.374 42.442L183.798 35.957C184.271 33.8322 184.803 31.5899 186.334 30.043C189.061 27.2919 193.591 27.9183 197.357 28.7384L207.019 30.8424C212.845 32.102 217.811 34.8081 217.348 40.8294Z"
            fill="#FF9900"
          />
          <path
            opacity="0.54"
            d="M204.339 41.0097C204.743 41.3858 205.041 41.862 205.203 42.3901C205.365 42.9185 205.385 43.4801 205.261 44.0186C205.138 44.557 204.874 45.0533 204.498 45.4578C204.122 45.862 203.647 46.1599 203.119 46.3215L200.244 47.204L197.369 48.0864C196.841 48.248 196.28 48.2677 195.743 48.1435C195.205 48.0193 194.71 47.7559 194.306 47.3791C193.902 47.0026 193.605 46.5264 193.443 45.998C193.281 45.4699 193.261 44.9083 193.385 44.3698L194.076 41.4353L194.75 38.5043C194.873 37.9655 195.135 37.4689 195.511 37.064C195.887 36.6595 196.363 36.3609 196.89 36.1989C197.418 36.0366 197.979 36.0169 198.516 36.1408C199.054 36.265 199.55 36.5287 199.954 36.9055L202.155 38.9576L204.339 41.0097Z"
            fill="#F1F8F9"
          />
        </g>
        <path
          d="M13.6074 268.984H312.001"
          stroke="black"
          stroke-width="1.42226"
          stroke-miterlimit="10"
        />
      </g>
    </svg>
  );
};

export default DeploymentFriendly;
