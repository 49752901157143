import { useLayoutState } from '@worx.squad/shared-frontend';
import { useLayoutEffect } from 'react';
import { Header } from '../components/CommonComponent/Header';
import Contact from '../components/Contact/Contact';

const ContactUs = () => {
  const [, setLayoutState] = useLayoutState();
  useLayoutEffect(() => {
    setLayoutState?.('normal');
  }, [setLayoutState]);
  return (
    <div className="relative">
      <div
        className="sticky bg-white top-0 z-[9999]"
        style={{ borderBottom: '1px solid #E0E0E0' }}
      >
        <Header />
      </div>
      <div>
        <Contact />
      </div>
    </div>
  );
};

export default ContactUs;
