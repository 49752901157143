import { FAQ } from './FAQ';

const WhatYouGet = () => {
  return (
    <div className="mx-[20px] sm:mx-[40px]">
      <div className="text-[28px] sm:text-[3rem] my-[2rem]">
        What all will you get with Worx.Squad?
      </div>
      <div className="border border-[#FF9900]">
        <FAQ />
      </div>
    </div>
  );
};

export default WhatYouGet;
