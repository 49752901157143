import { DefaultButton } from '@fluentui/react';
import LinkSvg from './assets/LinkSvg';

const DownloadEBook = () => {
  return (
    <div
      className="place-content-center px-[20px] sm:px-[40px] py-12 sm:py-[80px] bg-cover bg-no-repeat bg-center"
      style={{
        backgroundImage: "url('../../../assets/DownloadBGImgae.png')",
      }}
    >
      <div className="text-[1.5rem] sm:text-[2rem] text-white">
        Download The E-Book Future <br />
        Of Digital Work Force
      </div>
      <div className="mt-[56px] w-auto">
        <DefaultButton
          // onClick={handleSubmit(onSubmit)}
          className="bg-[#000000] w-full sm:w-auto border border-[#FF9900] text-white hover:bg-[#FF9900] hover:text-white px-6 py-6 ease-in-out duration-300"
        >
          <span className="flex justify-center items-center gap-4">
            <span className="text-[1rem]">Download</span>
            <span>
              <LinkSvg />
            </span>
          </span>
        </DefaultButton>
      </div>
    </div>
  );
};

export default DownloadEBook;
