import constate from 'constate';
import { format, getWeek, getYear, lastDayOfWeek, startOfWeek } from 'date-fns';
import { get, toNumber } from 'lodash';
import moment from 'moment';
import { useMemo, useState } from 'react';

export enum DashboardTimeRange {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MOTHLY = 'MOTHLY',
}

interface TimeRangeState {
  timeRangeValue: null | any;
  dashboardTimeRange: DashboardTimeRange;
}

const todayDate = moment().utc();

function useDashboardControlHook() {
  const [timeRange, setTimeRange] = useState<TimeRangeState>({
    timeRangeValue: null,
    dashboardTimeRange: DashboardTimeRange.DAILY,
  });

  // const { id, organization, role } = useUser();

  // const graphTitle = localStorage.getItem('graphTitle');

  // const [{ data: usersData }] = useGet_UsersInfoOf_OrganizationAdminQuery({
  //   variables:
  //     role === SystemRoles.ORGANIZATION_ADMIN
  //       ? {
  //           _eq1: organization?.id,
  //         }
  //       : {
  //           _eq: id,
  //           _eq1: organization?.id,
  //         },
  // });

  // const userOptions: any = usersData?.organization_user.map((type) => ({
  //   text: type.user?.display_name,
  //   key: type.user?.id,
  // }));

  const ViewByType: any = [
    {
      key: 'View by people',
      text: 'View by people',
    },
    {
      key: 'View by project',
      text: 'View by project',
    },
  ];

  const [currentUser, setCurrentUser] = useState<string | null>(null);

  const [selectedUser_Id, setSelectedUser_Id] = useState<any>('');

  const [selectedUser_Name, setSelectedUser_Name] = useState<any>('');

  const [excelHead, setExcelHead] = useState<any>([]);

  const [excelData, setExcelData] = useState<any>([]);

  const [oneToOneMeeting, setOneToOneMeeting] = useState<string | null>(null);

  const [roleId, setRoleId] = useState('');

  const [ViewByTypeText, setViewByTypeText] = useState<any>(ViewByType[0].text);

  const [projectTypeText, setProjectTypeText] = useState<string | any>('');

  const [projectTypeName, setProjectTypeName] = useState<string | any>('');

  const [selectedPage, setSelectedPage] = useState<number>(0);

  const [selectedOption, setSelectedOption] = useState('');

  const [selectedRole, setSelectedRole] = useState('');

  const [selectedSystemRole, setSelectedSystemRole] = useState('');

  const [selectedBar, setSelectedBar] = useState<any>(null);

  const [selectedEmployee, setSelectedEmployee] = useState<any>(null);

  const timeRangeComputed = useMemo(() => {
    if (timeRange.dashboardTimeRange == DashboardTimeRange.DAILY) {
      const selectedDate = moment({
        years: toNumber(get(timeRange.timeRangeValue, 'year')),
        // because for moment object it uses month function it stars with zero
        months: toNumber(get(timeRange.timeRangeValue, 'monthNumber')) - 1,
        date: toNumber(get(timeRange.timeRangeValue, 'dateNum')),
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });

      const dateStartDate = selectedDate.clone();
      const dateEndDate = selectedDate.clone().endOf('day');

      return {
        dateStartDate: dateStartDate.format('YYYY-MM-DD'),
        dateEndDate: dateEndDate.format('YYYY-MM-DD'),
        dateStartTimestamp: dateStartDate.toISOString(),
        dateEndTimestamp: dateEndDate.toISOString(),
      };
    } else if (timeRange.dashboardTimeRange == DashboardTimeRange.WEEKLY) {
      const dateStartDate = moment({
        year: toNumber(get(timeRange.timeRangeValue, 'year')),
        month: toNumber(get(timeRange.timeRangeValue, 'monthNumber')) - 1,
        date: toNumber(get(timeRange.timeRangeValue, 'startOfWeek')),
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });
      const dateEndDate = dateStartDate
        .clone()
        .week(get(timeRange.timeRangeValue, 'week'))
        .endOf('week')
        .add(1, 'day');

      return {
        dateStartDate: dateStartDate.format('YYYY-MM-DD'),
        dateEndDate: dateEndDate.format('YYYY-MM-DD'),
        dateStartTimestamp: dateStartDate.toISOString(),
        dateEndTimestamp: dateEndDate.toISOString(),
      };
    } else {
      const dateStartDate = moment({
        year: toNumber(get(timeRange.timeRangeValue, 'year')),
        month: toNumber(get(timeRange.timeRangeValue, 'monthNumber')) - 1,
        date: 1,
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });

      const dateEndDate = dateStartDate.clone().endOf('month');
      return {
        dateStartDate: dateStartDate.format('YYYY-MM-DD'),
        dateEndDate: dateEndDate.format('YYYY-MM-DD'),
        dateStartTimestamp: dateStartDate.toISOString(),
        dateEndTimestamp: dateEndDate.toISOString(),
      };
    }
  }, [timeRange]);

  // useEffect(() => {
  //   if (graphTitle === 'billable-hours') {
  //     setCurrentUser(userOptions?.splice(0, 5).map((user: any) => user.key));
  //   }
  // }, [graphTitle, userOptions]);

  return {
    setExcelData,
    excelData,
    oneToOneMeeting,
    setOneToOneMeeting,
    excelHead,
    setExcelHead,
    setSelectedEmployee,
    selectedEmployee,
    selectedBar,
    setSelectedBar,
    selectedSystemRole,
    setRoleId,
    setSelectedRole,
    selectedRole,
    setSelectedSystemRole,
    currentUser,
    setCurrentUser,
    roleId,
    timeRangeComputed,
    timeRange,
    setTimeRange,
    ViewByTypeText,
    setViewByTypeText,
    projectTypeText,
    setProjectTypeText,
    projectTypeName,
    setProjectTypeName,
    ViewByType,
    selectedPage,
    setSelectedPage,
    selectedOption,
    setSelectedOption,
    selectedUser_Id,
    setSelectedUser_Id,
    selectedUser_Name,
    setSelectedUser_Name,
    setDashboardTimeRange: (dashboardTimeRange: DashboardTimeRange) => {
      if (dashboardTimeRange == DashboardTimeRange.DAILY) {
        setTimeRange({
          dashboardTimeRange: DashboardTimeRange.DAILY,
          timeRangeValue: {
            dateNum: todayDate.format('DD'),
            month: todayDate.format('MMMM'),
            monthNumber: todayDate.format('MM'),
            year: todayDate.format('YYYY'),
          },
        });
      } else if (dashboardTimeRange == DashboardTimeRange.MOTHLY) {
        setTimeRange({
          dashboardTimeRange: DashboardTimeRange.MOTHLY,
          timeRangeValue: {
            noOfMonth: todayDate.format('DD'),
            month: todayDate.format('MMMM'),
            year: todayDate.format('YYYY'),
            monthNumber: format(todayDate.toDate(), 'MM'),
          },
        });
      } else if (dashboardTimeRange == DashboardTimeRange.WEEKLY) {
        setTimeRange({
          dashboardTimeRange: DashboardTimeRange.WEEKLY,
          timeRangeValue: {
            year: getYear(todayDate.toDate()),
            week: getWeek(todayDate.toDate()),
            endOfWeek: format(
              lastDayOfWeek(todayDate.toDate(), { weekStartsOn: 1 }),
              'd',
              { weekStartsOn: 1 },
            ),
            startOfWeek: format(
              startOfWeek(todayDate.toDate(), { weekStartsOn: 1 }),
              'd',
              { weekStartsOn: 1 },
            ),
            month: format(todayDate.toDate(), 'MMMM'),
            monthNumber: format(todayDate.toDate(), 'MM'),
          },
        });
      }
    },
    setTimeRangeValue: (timeRangeValue: any) => {
      setTimeRange({
        dashboardTimeRange: timeRange.dashboardTimeRange,
        timeRangeValue: timeRangeValue,
      });
    },
  };
}

export const [DashboardControlProvider, useDashboardControl] = constate(
  useDashboardControlHook,
);
