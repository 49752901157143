import { DefaultButton, Link, Stack, StackItem } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BlackLinkSvg from '../Home/assets/BlackLinkSvg';
import CloseIcon from '../Home/assets/CloseIcon';
import HambergIcon from '../Home/assets/HambergIcon';
import LinkSvg from '../Home/assets/LinkSvg';
import LogoSvg from '../Home/assets/LogoSvg';

export function Header() {
  const navigate = useNavigate();
  const [sideBar, setSideBar] = useState(true);
  const url = useLocation();
  const path = url.pathname === '/contact-us' || url.pathname === '/thank-you';
  const [linkIconOne, setLinkIconOne] = useState(false);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <StackItem className="py-4 px-[20px] md:px-[20px] lg:px-[40px]">
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Link style={{ width: 176 }} onClick={() => navigate('/')}>
            {path ? (
              <img
                src="../../../assets/worxsquad-logo-one.png"
                alt="logo"
                width={'157px'}
                height={'48px'}
              />
            ) : (
              <LogoSvg />
            )}
          </Link>
          <StackItem className="text-base gap-10 flex-wrap xl:flex hidden items-center text-black">
            <Link
              className={`${
                path ? 'text-black' : 'text-white'
              } font-semibold hover:text-[#FF9900] hover:ease-in-out duration-300 hover:no-underline`}
              href="https://www.worxwide.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Worxwide"
            >
              About Worxwide
            </Link>
            <Link
              className={`${
                path ? 'text-black' : 'text-white'
              } font-semibold hover:text-[#FF9900] hover:ease-in-out duration-300 hover:no-underline ${
                url.pathname === '/how-it-works' ? 'text-[#FF9900]' : ''
              }`}
              onClick={() => navigate('/how-it-works')}
            >
              How It Works
            </Link>
            <Link
              className={`${
                path ? 'text-black' : 'text-white'
              } font-semibold hover:text-[#FF9900] hover:ease-in-out duration-300 hover:no-underline ${
                url.pathname === '/pricing' ? 'text-[#FF9900]' : ''
              }`}
              onClick={() => navigate('/pricing')}
            >
              Pricing
            </Link>
            <Link
              className={`${
                path ? 'text-black' : 'text-white'
              } font-semibold hover:text-[#FF9900] hover:ease-in-out duration-300 hover:no-underline ${
                url.pathname === '/blog' ? 'text-[#FF9900]' : ''
              }`}
              onClick={() => navigate('/blog')}
            >
              Blog
            </Link>
            <Link
              className={`${
                path ? 'text-black' : 'text-white'
              } font-semibold hover:text-[#FF9900] hover:ease-in-out duration-300 hover:no-underline ${
                url.pathname === '/contact-us' ? 'text-[#FF9900]' : ''
              }`}
              onClick={() => navigate('/contact-us')}
            >
              Contact Us
            </Link>
            <DefaultButton
              text="Sign In As Org Admin"
              onClick={() => navigate('/login')}
              onMouseEnter={() => setLinkIconOne(true)}
              onMouseLeave={() => setLinkIconOne(false)}
              className={`border border-[#FF9900] ${
                path ? 'text-black bg-white' : 'bg-[#000000]'
              } text-white hover:bg-[#FF9900] hover:text-white px-6 py-6 ease-in-out duration-300`}
            >
              {path ? <BlackLinkSvg linkIconOne={linkIconOne} /> : <LinkSvg />}
            </DefaultButton>
          </StackItem>
          <Stack className="block xl:hidden">
            <Stack
              onClick={() => setSideBar(false)}
              className={`p-2 text-white rounded ${
                sideBar ? 'visible' : 'invisible'
              }`}
            >
              <HambergIcon />
            </Stack>
            <Stack>
              <div
                className={`fixed top-0 right-0 h-screen w-[85vw] bg-white transform transition-transform z-50 ${
                  sideBar ? 'translate-x-[85vw]' : 'translate-x-0'
                }`}
              >
                <div className="sticky flex justify-between items-center p-[20px]">
                  <div></div>
                  <div onClick={() => setSideBar(true)}>
                    {!sideBar && <CloseIcon />}
                  </div>
                </div>
                <div
                  className="flex flex-col justify-between overflow-y-auto"
                  style={{ height: 'calc(100vh - 80px)' }}
                >
                  <div>
                    <ul className="text-black space-y-2 p-6">
                      <Link
                        className="text-black text-[20px] font-normal py-2"
                        href="https://www.worxwide.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Worxwide"
                      >
                        About Worxwide
                      </Link>
                      <div className="h-[0.2px] bg-[#e0e0e1]"></div>

                      <Link
                        className={`text-black text-[20px] font-normal py-2 ${
                          url.pathname === '/how-it-works'
                            ? 'text-[#FF9900]'
                            : ''
                        }`}
                        onClick={() => navigate('/how-it-works')}
                      >
                        How It Works
                      </Link>
                      <div className="h-[0.2px] bg-[#e0e0e1]"></div>

                      <Link
                        className={`text-black text-[20px] font-normal py-2 ${
                          url.pathname === '/pricing' ? 'text-[#FF9900]' : ''
                        }`}
                        onClick={() => navigate('/pricing')}
                      >
                        Pricing
                      </Link>
                      <div className="h-[0.2px] bg-[#e0e0e1]"></div>

                      <Link
                        className={`text-black text-[20px] font-normal py-2 ${
                          url.pathname === '/blog' ? 'text-[#FF9900]' : ''
                        }`}
                        onClick={() => navigate('/blog')}
                      >
                        Blog
                      </Link>
                      <div className="h-[0.2px] bg-[#e0e0e1]"></div>

                      <Link
                        className={`text-black text-[20px] font-normal py-2 ${
                          url.pathname === '/contact-us' ? 'text-[#FF9900]' : ''
                        }`}
                        onClick={() => navigate('/contact-us')}
                      >
                        Contact Us
                      </Link>
                    </ul>
                  </div>
                  <div className="flex justify-center px-[20px] mt-[70px]">
                    <DefaultButton
                      onClick={() => navigate('/login')}
                      className="border w-full border-[#FF9900] text-black px-3 py-6"
                    >
                      <span className="flex gap-4 justify-center items-center">
                        <span className="text-[1rem]">
                          Sign In As Org Admin
                        </span>
                        <span>
                          <BlackLinkSvg />
                        </span>
                      </span>
                    </DefaultButton>
                  </div>
                  <div className="flex flex-col justify-between">
                    <div>
                      <div className="px-[20px]">
                        <div className="pt-[40px] pb-[16px]">
                          <div className="text-[16px] font-normal text-black">
                            Address
                          </div>

                          <div className="text-black text-[14px] font-light">
                            32, Global business park, Sector 44, Gurgaon,
                          </div>

                          <div className="text-black text-[14px] font-light">
                            Haryana, 122001
                          </div>
                        </div>
                        <div>
                          <div className="text-[16px] font-normal text-black">
                            Say Hi
                          </div>
                          <div className="text-[14px] font-light text-black">
                            consult@worxwide.com
                          </div>
                        </div>
                        <div className="py-[16px]">
                          <div className="text-[16px] font-normal text-black">
                            For more business. Check out our website
                          </div>
                          <div className="text-[14px] font-light text-black">
                            www.worxwide.com
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center items-center my-[55px]">
                    <div
                      className="w-[104px] h-[32px]"
                      onClick={() => navigate('/')}
                    >
                      <img
                        src="../../../assets/worxsquad-logo-one.png"
                        alt="logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Stack>
          </Stack>
        </Stack>
      </StackItem>
    </div>
  );
}
