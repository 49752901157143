const Upskill = ({ isWhite }: any) => {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C11.364 0 12.4697 1.10572 12.4697 2.4697L12.469 3H16C16.5523 3 17 3.44772 17 4L16.999 7.499L15.4697 7.5C14.1603 7.5 13.0889 8.51903 13.0053 9.80731L13 9.9697V10.0303C13 11.3397 14.019 12.4111 15.3073 12.4947L15.4697 12.5L16.999 12.499L17 16.0029C17 16.5552 16.5523 17.0029 16 17.0029L12.469 17.002L12.4697 17.5303C12.4697 18.8943 11.364 20 10 20C8.63602 20 7.5303 18.8943 7.5303 17.5303L7.53 17.002L4 17.0029C3.44772 17.0029 3 16.5552 3 16.0029L2.999 12.471L2.4697 12.4712C1.10572 12.4712 0 11.3654 0 10.0015C0 8.63749 1.10572 7.53177 2.4697 7.53177L2.999 7.531L3 4C3 3.44772 3.44772 3 4 3H7.53L7.5303 2.4697C7.5303 1.10572 8.63602 0 10 0Z"
        fill={isWhite ? 'white' : '#185D74'}
      />
    </svg>
  );
};

export default Upskill;
