import {
  Checkbox,
  DefaultButton,
  MessageBarType,
  Modal,
  Spinner,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { Form, TextField, useNotification } from '@worx.squad/shared-frontend';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import CloseBtn from '../Home/assets/CloseBtn';
import LinkSvg from '../Home/assets/LinkSvg';
import { useContactUsMutation } from '../Home/home.generated';
import { tryUsSchema } from '../Home/tryUs.schema';

const Challenges = () => {
  const [checkList, setCheckList] = useState({
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
    six: false,
    seven: false,
  });
  const [, contactUs] = useContactUsMutation();
  const { notification } = useNotification();
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const tryUsForm = useForm({
    mode: 'all',
    resolver: tryUsSchema,
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone_no: '',
    },
  });

  const { handleSubmit } = tryUsForm;
  const onSubmit = async (data: any) => {
    setLoader(true);
    try {
      const res = await contactUs({
        object: {
          email: data.email,
          firstName: data.first_name,
          lastName: data.last_name,
          // phone: data.phone_no,
          phone: '+91919821292',
        },
      });
      if (res.data) {
        notification({
          message:
            'Thank you for contacting us. We will get back to you shortly.',
          type: MessageBarType.success,
        });
        tryUsForm.reset();
        showModal();
        navigate('/thank-you');
        setLoader(false);
      } else {
        notification({
          message: res.error?.message + '',
          type: MessageBarType.error,
        });
      }
    } catch (e) {
      notification({
        message: e + '',
        type: MessageBarType.error,
      });
    }
  };

  const handleCheckbox = (id: string, isChecked: any) => {
    setCheckList((prevStates) => ({
      ...prevStates,
      [id]: isChecked,
    }));
  };

  const customStyles = {
    checkbox: {
      borderColor: 'white',
    },
    text: {
      color: 'white',
      fontSize: '16px',
      fontFamily: 'sans-serif',
    },
  };

  const customTextFieldStyles = {
    fieldGroup: {
      height: 52,
    },
  };

  const checkDisabled = (item: any) => {
    if (item.one) return item.one;
    else if (item.two) return item.two;
    else if (item.three) return item.three;
    else if (item.four) return item.four;
    else if (item.five) return item.five;
    else if (item.six) return item.six;
    else if (item.seven) return item.seven;
  };

  return (
    <div>
      <div className="flex flex-col lg:flex lg:flex-row hidden md:hidden lg:block ">
        <div className="w-[50%]">
          <img src="../../../assets/Challenges.png" alt="challengesImage" />
        </div>
        <div className="px-[40px] w-[50%] flex flex-col gap-4 mt-[44px]">
          <div className="text-[28px] sm:text-[48px] text-white ">
            Tell us your challenges
          </div>
          <div className="flex flex-col gap-4">
            <div className="my-2">
              {' '}
              <Checkbox
                label="How to track productivity of employees"
                checked={checkList?.one}
                onChange={(ev, checked) => handleCheckbox('one', checked)}
                styles={customStyles}
              />
            </div>
            <div className="my-2">
              {' '}
              <Checkbox
                label="How to up-skill people and measure skill deviations"
                checked={checkList?.two}
                onChange={(ev, checked) => handleCheckbox('two', checked)}
                styles={customStyles}
              />
            </div>
            <div className="my-2">
              {' '}
              <Checkbox
                label="How to keep people motivated and engaged"
                checked={checkList.three}
                onChange={(ev, checked) => handleCheckbox('three', checked)}
                styles={customStyles}
              />
            </div>
            <div className="my-2">
              {' '}
              <Checkbox
                label="How to keep track of people’s goals"
                checked={checkList.four}
                onChange={(ev, checked) => handleCheckbox('four', checked)}
                styles={customStyles}
              />
            </div>
            <div className="my-2">
              {' '}
              <Checkbox
                label="How to enable seamless working with external clients online"
                checked={checkList.five}
                onChange={(ev, checked) => handleCheckbox('five', checked)}
                styles={customStyles}
              />
            </div>
            <div className="my-2">
              {' '}
              <Checkbox
                label="How can we foster trust when people work remotely"
                checked={checkList.six}
                onChange={(ev, checked) => handleCheckbox('six', checked)}
                styles={customStyles}
              />
            </div>
            <div className="my-2">
              {' '}
              <Checkbox
                label="How to create an inclusive and diverse culture"
                checked={checkList.seven}
                onChange={(ev, checked) => handleCheckbox('seven', checked)}
                styles={customStyles}
              />
            </div>
          </div>
          <div className="mt-[80px] mb-[44px]">
            <DefaultButton
              onClick={() => {
                [checkList].some((item) => checkDisabled(item)) && showModal();
              }}
              className={`bg-[#000000] px-6 py-6 ease-in-out duration-300 ${
                [checkList].some((item) => !checkDisabled(item))
                  ? 'text-[#525252] border border-[#525252] hover:text-[#525252] hover:bg-transparent cursor-default'
                  : 'border border-[#FF9900] hover:bg-[#FF9900] hover:text-white text-white'
              }`}
            >
              <span className="flex gap-4 justify-center items-center">
                <span className="text-[1rem]">Ask for a solution</span>
                <span>
                  <LinkSvg
                    disabledState={[checkList].some(
                      (item) => !checkDisabled(item),
                    )}
                  />
                </span>
              </span>
            </DefaultButton>
          </div>
        </div>
      </div>
      <div
        className="place-content-center px-[20px] sm:px-[40px] py-8 bg-cover bg-no-repeat bg-center block lg:hidden"
        style={{
          backgroundImage: "url('../../../assets/MobileViewChallenges.png')",
        }}
      >
        <div className="w-[100%] flex flex-col gap-2">
          <div className="text-[28px] sm:text-[48px] text-white">
            Tell us your challenges
          </div>
          <div className="flex flex-col gap-4">
            <div className="my-2">
              {' '}
              <Checkbox
                label="How to track productivity of employees"
                checked={checkList?.one}
                onChange={(ev, checked) => handleCheckbox('one', checked)}
                styles={customStyles}
              />
            </div>
            <div className="my-2">
              {' '}
              <Checkbox
                label="How to up-skill people and measure skill deviations"
                checked={checkList?.two}
                onChange={(ev, checked) => handleCheckbox('two', checked)}
                styles={customStyles}
              />
            </div>
            <div className="my-2">
              {' '}
              <Checkbox
                label="How to keep people motivated and engaged"
                checked={checkList.three}
                onChange={(ev, checked) => handleCheckbox('three', checked)}
                styles={customStyles}
              />
            </div>
            <div className="my-2">
              {' '}
              <Checkbox
                label="How to keep track of people’s goals"
                checked={checkList.four}
                onChange={(ev, checked) => handleCheckbox('four', checked)}
                styles={customStyles}
              />
            </div>
            <div className="my-2">
              {' '}
              <Checkbox
                label="How to enable seamless working with external clients online"
                checked={checkList.five}
                onChange={(ev, checked) => handleCheckbox('five', checked)}
                styles={customStyles}
              />
            </div>
            <div className="my-2">
              {' '}
              <Checkbox
                label="How can we foster trust when people work remotely"
                checked={checkList.six}
                onChange={(ev, checked) => handleCheckbox('six', checked)}
                styles={customStyles}
              />
            </div>
            <div className="my-2">
              {' '}
              <Checkbox
                label="How to create an inclusive and diverse culture"
                checked={checkList.seven}
                onChange={(ev, checked) => handleCheckbox('seven', checked)}
                styles={customStyles}
              />
            </div>
          </div>
          <div className="my-2">
            <DefaultButton
              onClick={() => {
                [checkList].some((item) => checkDisabled(item)) && showModal();
              }}
              className="bg-[#000000] border-[0px] text-white hover:bg-[#FF9900] hover:text-white px-6 py-6 ease-in-out duration-300"
            >
              <span className="flex gap-4 justify-center items-center">
                <span className="text-[1rem]">Ask for a solution</span>
                <span>
                  <LinkSvg />
                </span>
              </span>
            </DefaultButton>
          </div>
        </div>
      </div>
      <div>
        <Modal isOpen={isModalOpen} onDismiss={hideModal} isBlocking={false}>
          <div className="flex flex-col sm:flex-row">
            <div className="hidden sm:block">
              <img src="../../../assets/Popover.png" alt="popUpImg" />
            </div>
            <div className="block sm:hidden">
              <span className="relative">
                <img
                  src="../../../assets/MobileViewPopover.png"
                  alt="popUpImg"
                />
                <span
                  onClick={hideModal}
                  className="cursor-pointer absolute left-[86%] top-[26%]"
                >
                  <CloseBtn colorType={true} />{' '}
                </span>
              </span>
            </div>
            <div className="w-[91vw] lg:w-[33vw] md:w-[80vw]">
              <div className="text-[2rem] font-normal pl-[24px] pr-[32px] flex items-center justify-between pt-[24px]">
                <span>Ask for a solution</span>
                <span
                  onClick={hideModal}
                  className="cursor-pointer hidden sm:block"
                >
                  <CloseBtn colorType={false} />{' '}
                </span>
              </div>
              <div>
                <Form
                  className="flex flex-col w-full gap-5"
                  formHook={tryUsForm}
                  onSubmit={() => handleSubmit(onSubmit)}
                >
                  <div className="flex flex-col gap-5 mt-3 pl-[24px] pr-[32px]">
                    <label className="text-black">
                      First Name
                      <TextField
                        formHook={tryUsForm}
                        name="first_name"
                        label=""
                        styles={customTextFieldStyles}
                        placeholder="Enter first name"
                        className="w-full"
                        underlined
                      />
                    </label>
                  </div>
                  <div className="flex flex-col gap-5 mt-3  pl-[24px] pr-[32px]">
                    <label className="text-black">
                      Last Name
                      <TextField
                        formHook={tryUsForm}
                        name="last_name"
                        styles={customTextFieldStyles}
                        placeholder="Enter last name"
                        className="w-full"
                        underlined
                      />
                    </label>
                  </div>

                  <div className="flex flex-col gap-5  pl-[24px] pr-[32px]">
                    <label className="text-black mt-[4px]">
                      Email
                      <TextField
                        formHook={tryUsForm}
                        name="email"
                        styles={customTextFieldStyles}
                        placeholder="Enter company email address"
                        className="w-full"
                        underlined
                      />
                    </label>
                  </div>

                  <div className="w-auto pl-[24px] pr-[32px] pb-[24px] sm:pb-[0px] py-[24px]">
                    <DefaultButton
                      onClick={handleSubmit(onSubmit)}
                      className="bg-white text-black border border-[#FF9900] hover:text-white hover:bg-[#FF9900] px-6 py-6 ease-in-out duration-300"
                    >
                      {loader ? (
                        <Spinner />
                      ) : (
                        <span className="flex gap-4 justify-center items-center">
                          <span className="text-[1rem]">Submit</span>
                        </span>
                      )}
                    </DefaultButton>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Challenges;
