import { DefaultButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import DownloadApp from './DownloadApp';
import RadarAnimation from './RadarAnimation';
import { BookADemo } from './Utils';
import LinkSvg from './assets/LinkSvg';

export const Starter = () => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);

  return (
    <div className="bg-black text-lg sm:h-screen">
      <div className="mx-[20px] sm:mx-[40px] md:flex justify-between">
        <div className="flex flex-col w-full xl:w-2/3 self-center pt-[50px] sm:pt-[180px]">
          <div className="text-white font-medium font-poppins text-[48px] sm:text-[72px] leading-[3.5rem] sm:leading-[5rem]">
            Goodbye to scattered
            <br /> business apps.
          </div>
          <div className="text-black text-[14px] mt-5 mb-10 space-y-5 ">
            <div>
              <p className="text-[#FF9900] text-[1rem]">
                Enterprise Productivity and Employee Experience Platform
              </p>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-4 mb-[6rem] sm:pt-[76px] md:pb-[282px]">
            <div>
              <DefaultButton
                onClick={() => BookADemo()}
                className="bg-[#000000] w-full border border-[#FF9900] text-white hover:bg-[#FF9900] hover:text-white px-6 py-6 ease-in-out duration-300"
              >
                <span className="flex gap-4 justify-center items-center">
                  <span className="text-[1rem]">Book A Demo</span>
                  <span>
                    <LinkSvg />
                  </span>
                </span>
              </DefaultButton>
            </div>
            <div>
              <DefaultButton
                onClick={showModal}
                className="bg-[#000000]  w-full border border-[#FF9900] text-white hover:bg-[#FF9900] hover:text-white px-6 py-6 ease-in-out duration-300"
              >
                <span className="flex gap-4 justify-center items-center">
                  <span className="text-[1rem]">Download Squad For Free</span>
                  <span>
                    <LinkSvg />
                  </span>
                </span>
              </DefaultButton>
              <DownloadApp
                showModal={showModal}
                isModalOpen={isModalOpen}
                hideModal={hideModal}
              />
            </div>
          </div>
        </div>
        <div className="mb-20 hidden xl:block">
          <RadarAnimation />
        </div>
      </div>
    </div>
  );
};
