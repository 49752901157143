const WallOfMoments = () => {
  return (
    <svg
      width="56"
      height="60"
      viewBox="0 0 56 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_957_8439)">
        <path
          d="M20 51V59.8C20 59.91 20.09 60 20.2 60H35.8C35.91 60 36 59.91 36 59.8V51C36 48.24 33.76 46 31 46H25C22.24 46 20 48.24 20 51Z"
          fill="#FF9900"
        />
        <path
          d="M28 44.5C30.4853 44.5 32.5 42.4853 32.5 40C32.5 37.5147 30.4853 35.5 28 35.5C25.5147 35.5 23.5 37.5147 23.5 40C23.5 42.4853 25.5147 44.5 28 44.5Z"
          fill="#FF9900"
        />
        <path
          d="M18 59.8V52.5C18 49.74 15.76 47.5 13 47.5H7C4.24 47.5 2 49.74 2 52.5V59.8C2 59.91 2.09 60 2.2 60H17.8C17.91 60 18 59.91 18 59.8Z"
          fill="#FF9900"
        />
        <path
          d="M10 46C12.4853 46 14.5 43.9853 14.5 41.5C14.5 39.0147 12.4853 37 10 37C7.51472 37 5.5 39.0147 5.5 41.5C5.5 43.9853 7.51472 46 10 46Z"
          fill="#FF9900"
        />
        <path
          d="M54 59.8V52.5C54 49.74 51.76 47.5 49 47.5H43C40.24 47.5 38 49.74 38 52.5V59.8C38 59.91 38.09 60 38.2 60H53.8C53.91 60 54 59.91 54 59.8Z"
          fill="#FF9900"
        />
        <path
          d="M46 46C48.4853 46 50.5 43.9853 50.5 41.5C50.5 39.0147 48.4853 37 46 37C43.5147 37 41.5 39.0147 41.5 41.5C41.5 43.9853 43.5147 46 46 46Z"
          fill="#FF9900"
        />
        <path
          d="M0 20C0 22.21 1.79 24 4 24H10.5C10.78 24 11 24.22 11 24.5V30.5C11 30.98 11.61 31.18 11.9 30.8L16.85 24.2C16.94 24.07 17.09 24 17.25 24H23.69C23.88 24 24.05 24.11 24.14 24.28L27.55 31.11C27.73 31.48 28.26 31.48 28.44 31.11L31.85 24.28C31.93 24.11 32.11 24 32.3 24H38.74C38.9 24 39.05 24.07 39.14 24.2L44.09 30.8C44.38 31.18 44.99 30.98 44.99 30.5V24.5C44.99 24.22 45.21 24 45.49 24H51.99C54.2 24 55.99 22.21 55.99 20V4C55.99 1.79 54.2 0 51.99 0H4C1.79 0 0 1.79 0 4V20ZM45 14H49V16H45V14ZM26 9H40V11H26V9ZM26 14H43V16H26V14ZM12.5 10H13.47C14.21 10 14.89 9.5 15.16 8.74L16.09 6.19C16.26 5.73 16.67 5.45 17.1 5.45C17.26 5.45 17.42 5.49 17.58 5.58C18.04 5.82 18.27 6.38 18.15 6.92L17.44 10H19.61C19.98 10 20.33 10.16 20.59 10.45C20.91 10.79 21.05 11.29 20.98 11.77L20.31 16.16C20.24 16.65 19.86 17 19.41 17H14.62C14.38 17 14.15 16.91 13.96 16.75L13.1 16C12.93 15.85 12.72 15.76 12.5 15.75V10ZM7.5 9.42C7.5 9.31 7.59 9.22 7.7 9.22H11.3C11.41 9.22 11.5 9.31 11.5 9.42V16.02C11.5 16.13 11.41 16.22 11.3 16.22H7.7C7.59 16.22 7.5 16.13 7.5 16.02V9.42Z"
          fill="#FF9900"
          id="WallOfMoments"
        />
      </g>
      <defs>
        <clipPath id="clip0_957_8439">
          <rect width="56" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WallOfMoments;
