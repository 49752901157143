import { DefaultButton, Slider, TextField, Toggle } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LinkSvg from '../Home/assets/LinkSvg';

export const Starter = () => {
  const [sliderValue, setSliderValue] = useState<number>(5);
  const [monthlyPricing, setMonthlyPricing] = useState(1);
  const [yearlyPricing, setYearlyPricing] = useState(1);
  const [option, setOption] = useState<string>('monthly');
  const isMobile = window.innerWidth <= 768;
  const navigate = useNavigate();

  useEffect(() => {
    if (option === 'monthly') {
      setMonthlyPricing(9 * sliderValue);
    } else {
      const yearlyPriceWithoutDiscount = 9 * sliderValue * 12;
      const yearlyPriceWithDiscount = Math.ceil(
        yearlyPriceWithoutDiscount / 10,
      );
      const yearlyPrice = yearlyPriceWithoutDiscount - yearlyPriceWithDiscount;
      setYearlyPricing(yearlyPrice);
    }
  }, []);

  const handlePricing = (
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean,
  ) => {
    setOption(checked ? 'yearly' : 'monthly');
    if (checked) {
      const yearlyPriceWithoutDiscount = 9 * sliderValue * 12;
      const yearlyPriceWithDiscount = Math.ceil(
        yearlyPriceWithoutDiscount / 10,
      );
      const yearlyPrice = yearlyPriceWithoutDiscount - yearlyPriceWithDiscount;
      setYearlyPricing(yearlyPrice);
    } else {
      setMonthlyPricing(9 * sliderValue);
    }
  };

  const handleSliderValue = (e: any) => {
    setSliderValue(
      Number(e.target.value) && Number(e.target.value) <= 200
        ? Number(e.target.value)
        : 0,
    );
    if (option === 'yearly') {
      if (
        (Number(e.target.value) && Number(e.target.value)) > 200 ||
        e.target.value === ''
      ) {
        setYearlyPricing(0);
      } else if (Number(e.target.value) && Number(e.target.value) <= 200) {
        const yearlyPriceWithoutDiscount = 9 * Number(e.target.value) * 12;
        const yearlyPriceWithDiscount = Math.ceil(
          yearlyPriceWithoutDiscount / 10,
        );
        const yearlyPrice =
          yearlyPriceWithoutDiscount - yearlyPriceWithDiscount;
        setYearlyPricing(yearlyPrice);
      }
    } else {
      if (
        (Number(e.target.value) && Number(e.target.value)) > 200 ||
        e.target.value === ''
      ) {
        setMonthlyPricing(0);
      } else if (Number(e.target.value) && Number(e.target.value) <= 200) {
        setMonthlyPricing(9 * Number(e.target.value));
      }
    }
  };

  return (
    <div className="bg-black mx-[20px] sm:mx-[40px]">
      <div className="py-[2rem] flex flex-col items-center justify-center sm:pt-[90px]">
        <div className="text-[#FF9900] text-[1rem] font-poppins">
          Pricing details
        </div>
        <div className="w-full text-center my-2 text-white text-3xl text-[48px] sm:text-[72px] font-medium font-poppins leading-[3.5rem] sm:leading-[5rem] sm:py-[20px]">
          One app for all business needs!
        </div>
        <div className="w-full text-center text-[1rem] sm:text-[1.5rem] my-2 text-white ">
          3 months free trail for unlimited users, followed by $9 per month per
          license
        </div>
      </div>
      <div>
        <div className="w-[89vw]">
          <div className="flex items-baseline  justify-center sm:justify-end gap-2 my-[1rem]">
            {' '}
            <span className="text-white text-[1rem]">Monthly</span>
            <span>
              <Toggle
                onChange={handlePricing}
                styles={{
                  pill: {
                    borderColor: '#FF9900',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    selectors: {
                      ':hover': {
                        background: 'initial', // Reset hover background for pill
                        borderColor: '#FF9900', // Keep border color consistent on hover
                      },
                    },
                  },
                }}
              />
            </span>
            <span className="text-white text-[1rem]">Yearly(10% Discount)</span>
          </div>
        </div>
        <div className="flex gap-2 items-center justify-center mb-[1rem]">
          <span style={{ position: 'relative' }}>
            <Slider
              className="cursor-pointer"
              showValue={false}
              onChange={(value, event) => {
                setSliderValue(value);
                if (option === 'monthly') {
                  setMonthlyPricing(9 * value);
                } else {
                  const yearlyPriceWithoutDiscount = 9 * value * 12;
                  const yearlyPriceWithDiscount = Math.ceil(
                    yearlyPriceWithoutDiscount / 10,
                  );
                  const yearlyPrice =
                    yearlyPriceWithoutDiscount - yearlyPriceWithDiscount;
                  setYearlyPricing(yearlyPrice);
                }
              }}
              min={0}
              max={200}
              value={sliderValue}
              styles={{
                container: {
                  width: '85vw',
                },
                lineContainer: {
                  height: 12,
                  background: '#E6EAF5',
                },
                thumb: {
                  height: 20,
                  width: 35,
                  marginTop: '2px',
                  border: '2px solid #FF9900',
                  selectors: {
                    ':after': {
                      content: `"${sliderValue}"`,
                      position: 'absolute',
                      top: isMobile ? '-11px' : '-9px',
                      left: '16px',
                      transform: 'translateX(-50%)',
                      padding: '4px 12px',
                      fontSize: '12px',
                      color: 'black',
                      fontWeight: 'bold',
                    },
                  },
                },
                activeSection: {
                  background: '#FF9900',
                  hover: {
                    background: '#FF9900',
                  },
                },
                inactiveSection: {
                  background: 'white',
                  hover: {
                    background: 'white',
                  },
                },
                slideBox: {
                  selectors: {
                    ':hover .ms-Slider-active': {
                      background: '#FF9900',
                    },
                    ':hover .ms-Slider-inactive': {
                      background: 'white',
                    },
                  },
                },
              }}
            />
          </span>
        </div>
        <div className="flex justify-center items-center my-[40px]">
          <span className="text-white text-[1rem] sm:text-[1.5rem] w-[70px]">
            I want
          </span>{' '}
          <span className="text-[#FF9900] font-bold mx-[4px] px-1 w-[80px]">
            <TextField
              value={sliderValue.toString()}
              onChange={handleSliderValue}
              borderless
              className="border-b border-[#FF9900] rounded-sm bg-black text-white"
              style={{
                backgroundColor: 'black',
                color: '#FF9900',
                fontWeight: 'bold',
                fontSize: isMobile ? '18px' : '24px',
                textAlign: 'center',
              }}
            />
          </span>{' '}
          <span className="text-white text-[1rem] sm:text-[1.5rem] w-[125px]">
            {`${sliderValue === 1 ? 'licence for' : 'licences for'}`}
          </span>{' '}
          <span className="text-[#FF9900] font-bold ml-[2px] mr-[8px] text-[16px] sm:text-[22px] w-[80px] text-center">
            {option === 'monthly' ? `$${monthlyPricing}` : `$${yearlyPricing}`}
          </span>{' '}
          <span className="text-white text-[1rem] sm:text-[1.5rem] w-[125px]">
            {option === 'monthly' ? (
              <span>per month</span>
            ) : (
              <span>per year</span>
            )}
          </span>
        </div>
        <div className="flex gap-4 justify-center flex-col sm:flex-row items-center pt-[40px] pb-[40px] sm:pb-[170px]">
          <div className="w-full sm:w-auto">
            <DefaultButton
              onClick={() => navigate('/contact-us')}
              className="border-0 w-full bg-[#FF9900] text-white hover:text-white px-6 py-6 hover:bg-[#FF9900]"
            >
              <span className="flex gap-4 justify-center items-center">
                <span className="text-[1rem]">Get started today</span>
                <span>
                  <LinkSvg />
                </span>
              </span>
            </DefaultButton>
          </div>
          <div className="text-white">or</div>
          <div className="w-full sm:w-auto">
            <DefaultButton
              onClick={() => navigate('/login')}
              className="bg-[#000000] w-full sm:w-auto border border-[#FF9900] text-white hover:bg-[#FF9900] hover:text-white px-6 py-6 ease-in-out duration-300"
            >
              <span className="flex gap-4 justify-center items-center">
                <span className="text-[1rem]">Start a 3-month free trial</span>
                <span>
                  <LinkSvg />
                </span>
              </span>
            </DefaultButton>
          </div>
        </div>
      </div>
    </div>
  );
};
