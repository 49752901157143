const ImmersiveExperience = () => {
  return (
    <svg
      width="271"
      height="268"
      viewBox="0 0 271 268"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1201 150.58C11.9109 147.921 11.7496 145.259 11.6211 142.603"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M163.35 246.234H87.4512"
        stroke="#CCDDE2"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M185.508 56.0961C185.508 56.0961 207.875 45.5337 226.852 66.1415C233.052 72.8763 237.282 81.8132 237.581 91.0339C237.717 95.5331 236.992 100.017 235.445 104.244C233.87 108.558 231.044 111.415 228.871 115.257C225.54 121.182 232.505 122.231 236.243 125.102C238.727 127.027 241.083 129.111 243.297 131.341C248.286 136.324 252.677 141.872 256.378 147.873C263.709 159.827 268.122 173.338 269.262 187.314C270.389 201.094 269.525 216.058 262.459 228.299C259.058 234.195 253.937 239.976 247.639 242.725C239.272 246.373 229.875 246.236 220.936 246.236H201.911"
        stroke="#CCDDE2"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M92.2718 193.796C91.5219 199.132 90.4044 205.254 89.1704 211.383L87.7153 211.798C83.0583 213.106 78.113 212.961 73.5406 211.383L75.4469 204.905L63.8418 171.141C63.8418 171.141 72.7129 168.273 79.9975 166.142L80.8341 166.106C84.814 166.946 93.0158 170.098 93.2728 181.904C93.2907 182.471 93.2728 183.069 93.2728 183.72C94.4291 187.53 93.969 190.888 92.2718 193.796Z"
        fill="#FF9900"
      />
      <path
        d="M64.9553 65.2722C64.8408 64.9809 64.617 64.7463 64.3317 64.6184C64.046 64.4903 63.7219 64.4795 63.4284 64.588C63.2641 64.6376 63.112 64.7221 62.9832 64.836C62.5231 65.2095 62.4185 65.8638 62.4036 66.4853C62.3618 67.9046 62.2811 69.3268 62.1765 70.7401C62.1765 70.8357 62.1616 70.9433 62.1616 71.0389C62.0958 72.1773 62.1436 73.5577 61.6596 74.5497C61.6285 74.6253 61.5756 74.6899 61.5072 74.735C61.3459 74.8097 61.1755 74.6483 61.071 74.5019C60.7722 74.0657 60.4734 73.6384 60.1746 73.1932C59.9385 72.8376 59.6726 72.4522 59.2603 72.3387C58.6478 72.1683 58.0651 72.7092 57.7275 73.2589C56.6369 75.0517 56.5323 77.9888 58.2205 79.4918C59.9087 80.9947 56.129 83.9498 53.4219 85.1927C52.9686 85.4476 52.4939 85.6615 52.0026 85.8322C51.9787 85.8322 52.9797 86.0562 52.9558 86.0652C50.8493 86.7984 48.655 87.2496 46.4302 87.4068C43.7889 87.5831 41.2133 87.2514 39.3398 86.1041C39.4205 85.8053 40.0599 83.7137 40.0988 83.4149C40.2362 82.3721 40.3348 81.3383 40.3976 80.2895C40.5111 78.3205 40.5021 76.3485 40.4274 74.3854C40.1555 67.3219 41.4135 61.3879 44.3297 60.4826C44.56 60.4135 44.7808 60.3161 44.987 60.1927C46.3017 59.4667 47.0038 57.9338 46.4003 56.4877C46.3585 56.3891 46.3107 56.2935 46.2688 56.1889C46.2216 56.0858 46.1678 55.986 46.1075 55.8901C51.8024 54.1212 55.6688 55.3493 59.577 57.4886C60.3207 58.0829 61.0199 58.731 61.6686 59.4278C63.1984 61.0885 64.3233 63.0806 64.9553 65.2483V65.2722Z"
        fill="#FF9900"
      />
      <path
        d="M201.852 133.185H182.431V86.7647C182.431 86.7647 178.845 127.099 169.021 149.95H108.871C115.961 145.445 121.647 138.638 125.433 131.312C126.927 128.413 129.317 124.111 133.291 125.24C134.236 125.506 135.625 125.676 136.327 124.813C136.569 124.482 136.707 124.085 136.721 123.675C137.062 119.444 132.676 115.882 129.06 114.759C125.445 113.635 121.22 114.161 118.343 116.787C116.269 118.685 114.969 121.204 113.452 123.537C112.541 124.92 111.461 126.184 110.237 127.299C111.796 117.827 113.186 108.774 113.386 105.442C117.189 102.439 119.983 97.5421 120.975 93.2096C122.066 88.4767 123.028 83.8484 124.722 79.4173L138.589 79.4651C139.111 79.4651 139.612 79.2578 139.981 78.8885C140.351 78.5192 140.558 78.0184 140.558 77.4961V54.4651C140.559 53.9476 140.354 53.451 139.99 53.0835C139.625 52.7163 139.13 52.5083 138.613 52.5051L129.171 52.4244C132.602 51.7073 136.082 51.243 139.581 51.035C139.653 51.035 139.718 51.035 139.781 51.035C142.085 50.8826 146.121 50.8169 148.446 50.793C145.676 52.2869 143.964 55.4601 144.263 58.2628C144.316 58.8598 144.468 59.4436 144.714 59.9898C147.081 65.1888 153.313 66.7246 158.459 65.2127C164.183 63.5425 168.39 58.2777 168.139 52.2212C171.725 52.7471 175.275 53.4143 178.788 54.2231C184.128 55.4512 189.336 57.1065 193.558 60.7159C197.239 63.8502 200.358 67.8869 201.801 72.4495C207.998 91.9577 206.092 113.728 201.852 133.185Z"
        fill="black"
      />
      <path
        d="M113.542 105.443C113.106 112.573 111.983 119.696 110.764 126.801"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M124.878 79.4168C123.181 83.8479 122.222 88.4762 121.132 93.2091C120.131 97.5267 117.343 102.439 113.542 105.442C110.351 107.961 106.443 109.147 102.26 107.557C96.6485 105.418 94.6795 99.2328 93.9923 93.7798C93.0992 86.9897 93.0448 80.1157 93.8309 73.3125C94.6227 75.0724 97.8586 80.4268 107.895 79.0194C108.165 79.2208 108.488 79.3365 108.824 79.3511L124.878 79.4168Z"
        fill="#2E2E2E"
      />
      <path
        d="M119.242 64.0995C121.579 63.5019 123.001 65.7488 122.654 67.4579C122.09 70.2457 118.08 71.3422 115.692 71.393C113.78 71.4319 109.119 70.9269 107.219 70.7058"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M106.433 65.4787C109.324 66.3141 112.385 66.3646 115.301 65.6251C117.754 64.9946 120.653 63.8323 121.937 61.4987C122.687 60.1422 122.09 57.9789 120.524 57.5009C118.716 56.9541 117.043 58.7528 115.406 59.207C114.35 59.4947 113.271 59.6946 112.182 59.8046C111.883 59.8374 111.602 59.8613 111.312 59.8852C109.788 60.0074 108.255 59.9964 106.732 59.8524C106.57 59.8524 106.412 59.8013 106.281 59.7066C106.149 59.6115 106.051 59.4777 106 59.3238C105.949 59.1702 105.948 59.0041 105.997 58.8496C106.046 58.6952 106.142 58.5598 106.272 58.463L106.547 58.2628"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M124.879 79.418C123.181 83.8491 122.222 88.4774 121.132 93.2103C119.291 101.155 111.442 111.054 102.26 107.552C96.6488 105.413 94.6797 99.228 93.9925 93.775C92.9169 85.3311 93.0065 76.6572 94.5901 68.285C95.9048 61.3888 98.2383 54.1461 104.949 50.7548L108.098 49.1802C108.85 48.8007 109.719 48.7302 110.522 48.9836C111.324 49.2372 111.995 49.7948 112.392 50.5367C112.634 50.998 112.762 51.5101 112.766 52.0306C112.769 52.1068 112.763 52.183 112.748 52.2577"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M188.844 144.232C190.466 144.181 192.09 144.222 193.708 144.354C195.029 144.462 196.397 144.524 197.655 145.018C198.692 145.43 199.774 146.413 199.72 147.614C199.621 149.969 196.538 149.951 194.846 149.951"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        opacity="0.54"
        d="M159.826 141.987L173.887 105.961C174.061 105.263 174.073 104.534 173.923 103.831C173.773 103.127 173.464 102.467 173.021 101.9C172.577 101.334 172.01 100.876 171.363 100.561C170.715 100.247 170.005 100.085 169.285 100.087H113.447C112.195 100.09 110.981 100.513 110 101.289C109.018 102.065 108.325 103.149 108.033 104.366L92.6212 140.382C92.4542 141.077 92.4467 141.801 92.5997 142.5C92.753 143.199 93.0626 143.854 93.5051 144.415C93.9476 144.977 94.512 145.431 95.1553 145.743C95.7986 146.055 96.5046 146.217 97.2196 146.218H154.408C155.654 146.217 156.863 145.8 157.845 145.033C158.826 144.267 159.523 143.195 159.826 141.987Z"
        fill="#F1F8F9"
      />
      <path
        d="M159.826 141.987L173.887 105.961C174.061 105.263 174.073 104.534 173.923 103.831C173.773 103.127 173.464 102.467 173.021 101.9C172.577 101.334 172.01 100.876 171.363 100.561C170.715 100.247 170.005 100.085 169.285 100.087H113.447C112.195 100.09 110.981 100.513 110 101.289C109.018 102.065 108.325 103.149 108.033 104.366L92.6212 140.382C92.4542 141.077 92.4467 141.801 92.5997 142.5C92.753 143.199 93.0626 143.854 93.5051 144.415C93.9476 144.977 94.512 145.431 95.1553 145.743C95.7986 146.055 96.5046 146.217 97.2196 146.218H154.408C155.654 146.217 156.863 145.8 157.845 145.033C158.826 144.267 159.523 143.195 159.826 141.987Z"
        fill="#FF9900"
      />
      <path
        d="M159.826 141.987L173.887 105.961C174.061 105.263 174.073 104.534 173.923 103.831C173.773 103.127 173.464 102.467 173.021 101.9C172.577 101.334 172.01 100.876 171.363 100.561C170.715 100.247 170.005 100.085 169.285 100.087H113.447C112.195 100.09 110.981 100.513 110 101.289C109.018 102.065 108.325 103.149 108.033 104.366L92.6212 140.382C92.4542 141.077 92.4467 141.801 92.5997 142.5C92.753 143.199 93.0626 143.854 93.5051 144.415C93.9476 144.977 94.512 145.431 95.1553 145.743C95.7986 146.055 96.5046 146.217 97.2196 146.218H154.408C155.654 146.217 156.863 145.8 157.845 145.033C158.826 144.267 159.523 143.195 159.826 141.987Z"
        stroke="black"
        stroke-width="1.21011"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
      <path
        d="M157.766 140.53L170.739 107.295C170.9 106.652 170.912 105.98 170.774 105.332C170.637 104.683 170.353 104.074 169.945 103.552C169.536 103.03 169.015 102.607 168.419 102.316C167.823 102.026 167.168 101.875 166.505 101.875H114.979C113.822 101.875 112.699 102.264 111.79 102.98C110.882 103.696 110.241 104.697 109.971 105.822L95.7812 139.051C95.6282 139.692 95.6226 140.36 95.7645 141.003C95.9061 141.647 96.1921 142.25 96.6002 142.768C97.0084 143.285 97.5286 143.703 98.1217 143.991C98.7145 144.279 99.3649 144.428 100.024 144.429H152.77C153.918 144.43 155.034 144.047 155.939 143.34C156.845 142.633 157.487 141.644 157.766 140.53Z"
        fill="#FF9900"
      />
      <path
        d="M118.697 108.001C119.381 106.976 120.49 106.507 121.195 106.982C121.9 107.457 121.9 108.652 121.219 109.671C121.111 109.828 120.991 109.976 120.86 110.113L125.922 120.801C126.728 120.263 127.616 120.129 128.255 120.553C128.46 120.703 128.63 120.895 128.753 121.117C128.876 121.339 128.948 121.585 128.966 121.838L132.426 120.673L135.45 119.654L136.436 119.331C136.561 118.96 136.734 118.606 136.95 118.279C137.909 116.836 139.478 116.188 140.455 116.845C141.432 117.502 141.444 119.161 140.512 120.592L146.278 128.465C147.536 127.434 149.015 127.115 150.04 127.796C151.436 128.725 151.456 131.148 150.088 133.204C150.031 133.288 149.968 133.353 149.912 133.431L153.748 138.678L153.784 138.729C153.98 139.025 154.051 139.387 153.98 139.734C153.91 140.082 153.704 140.388 153.408 140.584C153.111 140.78 152.75 140.851 152.402 140.78C152.054 140.71 151.748 140.504 151.552 140.208L148.146 135.065C147.067 135.69 145.92 135.809 145.08 135.251C143.748 134.354 143.682 132.119 144.871 130.126L139.352 121.796C138.537 122.349 137.637 122.489 136.992 122.062C136.633 121.807 136.389 121.422 136.311 120.989L135.898 121.103L132.824 121.942L128.886 123.006C128.763 123.478 128.561 123.926 128.288 124.33C127.329 125.77 125.76 126.421 124.783 125.764C123.806 125.107 123.791 123.419 124.75 121.975C124.891 121.763 125.049 121.564 125.223 121.378L120.403 110.49C119.823 110.887 119.181 110.989 118.721 110.681C118.028 110.221 118.016 109.014 118.697 108.001Z"
        fill="#D7E8F0"
      />
      <path
        d="M155.359 108.574H162.829C163.171 108.574 163.504 108.474 163.79 108.288C164.076 108.101 164.302 107.835 164.44 107.523C164.481 107.428 164.503 107.327 164.505 107.224C164.504 107.02 164.423 106.825 164.279 106.681C164.134 106.537 163.938 106.456 163.734 106.456H155.796C156.169 107.081 155.981 107.956 155.359 108.574Z"
        fill="#D7E8F0"
      />
      <path
        d="M150.533 107.809C150.534 108.012 150.615 108.206 150.759 108.35C150.903 108.493 151.098 108.574 151.301 108.574H155.362C155.983 107.955 156.171 107.08 155.798 106.449H152.212C151.871 106.449 151.537 106.548 151.251 106.734C150.966 106.92 150.74 107.186 150.602 107.498C150.558 107.596 150.534 107.701 150.533 107.809Z"
        fill="#FF9900"
      />
      <path
        d="M152.4 108.573C152.422 108.614 152.446 108.653 152.475 108.69C153.006 109.419 154.163 109.487 155.059 108.833C155.165 108.754 155.265 108.667 155.358 108.573C155.979 107.955 156.168 107.079 155.794 106.449C155.775 106.407 155.751 106.368 155.722 106.332C155.194 105.6 154.037 105.537 153.141 106.186C153.035 106.266 152.935 106.354 152.842 106.449C152.221 107.07 152.032 107.94 152.4 108.573Z"
        fill="#F1F8F9"
      />
      <path
        d="M148.668 112.498C148.67 112.702 148.751 112.896 148.896 113.039C149.04 113.183 149.236 113.263 149.439 113.263H157.276C156.897 112.63 157.085 111.769 157.707 111.139H150.335C149.994 111.139 149.659 111.238 149.373 111.425C149.086 111.612 148.86 111.878 148.722 112.19C148.684 112.289 148.666 112.393 148.668 112.498Z"
        fill="#FF9900"
      />
      <path
        d="M157.264 113.263H160.951C161.292 113.263 161.625 113.164 161.911 112.978C162.197 112.793 162.423 112.528 162.562 112.217C162.604 112.123 162.627 112.021 162.63 111.918C162.631 111.817 162.611 111.717 162.573 111.624C162.534 111.531 162.478 111.446 162.406 111.374C162.335 111.303 162.25 111.247 162.157 111.208C162.063 111.17 161.963 111.15 161.862 111.15H157.694C157.073 111.76 156.884 112.629 157.264 113.263Z"
        fill="#D7E8F0"
      />
      <path
        d="M157.264 113.263C157.282 113.304 157.304 113.343 157.33 113.379C157.859 114.111 159.015 114.177 159.911 113.525C160.018 113.445 160.117 113.357 160.21 113.263C160.826 112.641 161.014 111.769 160.647 111.138C160.625 111.098 160.601 111.059 160.575 111.022C160.04 110.293 158.884 110.227 157.987 110.881C157.881 110.959 157.781 111.045 157.688 111.138C157.073 111.76 156.885 112.629 157.264 113.263Z"
        fill="#F1F8F9"
      />
      <path
        d="M154.645 117.917H159.336C159.677 117.917 160.011 117.818 160.297 117.631C160.584 117.446 160.81 117.181 160.949 116.869C160.99 116.774 161.012 116.673 161.015 116.57C161.015 116.365 160.934 116.168 160.789 116.024C160.645 115.879 160.449 115.796 160.244 115.796H155.09C155.46 116.426 155.269 117.296 154.645 117.917Z"
        fill="#FF9900"
      />
      <path
        d="M147.047 117.156C147.047 117.256 147.067 117.355 147.106 117.448C147.145 117.541 147.202 117.625 147.273 117.696C147.344 117.766 147.429 117.822 147.522 117.86C147.615 117.899 147.714 117.918 147.815 117.917H154.642C155.258 117.296 155.449 116.423 155.078 115.79H148.726C148.384 115.79 148.049 115.89 147.763 116.077C147.477 116.264 147.252 116.531 147.116 116.845C147.072 116.942 147.048 117.049 147.047 117.156Z"
        fill="#D7E8F0"
      />
      <path
        d="M151.691 117.917C151.712 117.958 151.737 117.997 151.765 118.034C152.294 118.766 153.448 118.829 154.347 118.177C154.453 118.098 154.553 118.012 154.646 117.917C155.261 117.296 155.452 116.423 155.082 115.79C155.063 115.749 155.038 115.71 155.01 115.673C154.475 114.947 153.322 114.885 152.423 115.533C152.317 115.612 152.217 115.697 152.124 115.79C151.508 116.411 151.317 117.287 151.691 117.917Z"
        fill="#F1F8F9"
      />
      <path
        d="M124.318 139.873H129.012C129.353 139.873 129.687 139.773 129.973 139.587C130.259 139.401 130.484 139.136 130.623 138.825C130.664 138.73 130.686 138.629 130.689 138.526C130.689 138.321 130.607 138.125 130.463 137.981C130.318 137.836 130.122 137.755 129.918 137.755H124.764C125.125 138.382 124.934 139.252 124.318 139.873Z"
        fill="#FF9900"
      />
      <path
        d="M105.693 139.111C105.694 139.211 105.714 139.31 105.753 139.403C105.791 139.496 105.848 139.58 105.92 139.65C105.991 139.721 106.075 139.777 106.168 139.815C106.261 139.853 106.361 139.873 106.461 139.872H124.32C124.936 139.251 125.127 138.379 124.756 137.748H107.373C107.031 137.748 106.697 137.847 106.41 138.034C106.125 138.221 105.899 138.487 105.762 138.8C105.719 138.898 105.696 139.003 105.693 139.111Z"
        fill="#D7E8F0"
      />
      <path
        d="M121.358 139.872C121.38 139.913 121.405 139.952 121.433 139.989C121.962 140.721 123.115 140.783 124.014 140.135C124.12 140.054 124.219 139.967 124.313 139.872C124.929 139.251 125.12 138.378 124.749 137.748C124.73 137.706 124.706 137.667 124.678 137.631C124.143 136.902 122.989 136.839 122.09 137.488C121.984 137.566 121.884 137.653 121.791 137.748C121.179 138.366 120.984 139.242 121.358 139.872Z"
        fill="#F1F8F9"
      />
      <path
        d="M6.19702 151.38C6.19702 151.38 -3.66311 195.921 21.7013 205.225C47.0657 214.53 115.582 205.79 87.1068 173.652C58.632 141.514 6.19702 151.38 6.19702 151.38Z"
        fill="black"
      />
      <path
        d="M129.271 52.4255C132.719 51.7063 136.214 51.2402 139.729 51.0301C139.804 51.0301 139.867 51.0301 139.932 51.0301C142.242 50.8777 146.288 50.812 148.597 50.7881"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M112.184 59.7959C112.16 59.8108 112.145 59.8288 112.121 59.8437"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M151.491 41.0708C149.728 41.9971 147.703 41.3696 146.926 39.792"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M141.259 18.5581C141.18 19.9289 141.531 21.2902 142.265 22.4508C142.999 23.6116 144.077 24.5133 145.35 25.0299C145.619 24.536 146.004 24.1156 146.473 23.8049C148.454 22.5231 148.101 25.9472 153.48 25.0449C160.935 23.787 162.835 26.3835 162.835 26.3835C162.999 26.6106 162.536 28.0926 162.47 28.3645C162.172 29.957 161.873 31.5406 161.601 33.1452C161.425 34.1171 161.248 35.0831 161.069 36.0434C161.205 35.912 161.348 35.7892 161.499 35.6759C162.724 33.7637 164.52 31.6183 166.477 33.3692C166.87 33.7338 167.171 34.1865 167.356 34.6899C168.252 37.1251 166.537 38.6908 164.532 39.629C164.003 39.949 163.441 40.2104 162.856 40.4088C162.954 41.0243 163.364 43.4774 163.364 43.4834C163.406 43.7344 168.383 40.6658 168.799 40.2923C169.549 39.3 170.128 38.1891 170.511 37.0056C172.059 32.1233 171.796 25.8128 168.679 21.797C167.905 20.802 168.422 20.2283 168.646 19.2602C168.657 19.208 168.652 19.1539 168.632 19.1049C168.611 19.0556 168.577 19.0137 168.532 18.9845C168.488 18.9552 168.436 18.9399 168.382 18.9405C168.329 18.9414 168.278 18.9579 168.234 18.9883C166.555 20.0431 163.516 18.9644 163.516 18.9644C153.656 13.0423 148.603 16.7056 146.186 16.8908C145.206 16.958 144.233 16.6811 143.434 16.108C143.294 16.3853 143.081 16.6189 142.818 16.7841C142.554 16.9494 142.251 17.0399 141.94 17.0462L141.845 17.5601C141.771 17.9506 141.564 18.3035 141.259 18.5581Z"
        fill="#FF9900"
      />
      <path
        d="M145.353 25.0409C144.081 24.5246 143.003 23.6228 142.269 22.462C141.535 21.3012 141.184 19.9399 141.263 18.5691C141.292 18.0426 141.368 17.5197 141.49 17.0064L141.577 17.0243C141.694 17.0509 141.815 17.0599 141.935 17.0512C142.246 17.0452 142.549 16.9547 142.813 16.7895C143.076 16.6242 143.289 16.3903 143.429 16.113C143.512 15.9588 143.569 15.7924 143.6 15.62L144.06 13.1191C144.06 13.1191 145.554 15.1419 147.944 14.9537C150.334 14.7654 155.387 11.1023 165.274 17.0273C165.274 17.0273 167.646 17.9028 169.326 16.848C169.369 16.8178 169.421 16.8011 169.474 16.8005C169.527 16.7999 169.579 16.8151 169.624 16.8444C169.668 16.8737 169.703 16.9155 169.723 16.9645C169.744 17.0138 169.749 17.0679 169.738 17.1199C169.514 18.085 169.663 18.8649 170.437 19.8599C173.553 23.8756 173.816 30.1861 172.269 35.0684C171.591 37.0918 170.388 38.8983 168.782 40.3033C168.366 40.6768 163.389 43.7454 163.347 43.4944C163.347 43.4944 162.937 41.0353 162.839 40.4198L162.815 40.2794L162.955 40.2375C163.488 40.0729 164.009 39.8733 164.515 39.6399C166.52 38.7017 168.244 37.1361 167.339 34.7009C167.154 34.1977 166.853 33.7451 166.46 33.3802C164.503 31.6293 162.707 33.7746 161.482 35.6869C161.245 36.0499 161.051 36.439 160.903 36.8462C160.95 36.5833 160.998 36.3263 161.049 36.0634C161.228 35.1013 161.405 34.1353 161.581 33.1651C161.88 31.5695 162.158 29.9859 162.45 28.3844C162.504 28.1125 162.979 26.6305 162.815 26.4034C162.815 26.4034 160.915 23.8069 153.46 25.0648C148.081 25.9612 148.434 22.543 146.453 23.8248C145.984 24.1356 145.599 24.5563 145.33 25.0499C145.246 25.2109 145.172 25.3765 145.108 25.5459C145.098 25.5671 145.089 25.5892 145.082 25.6116C144.947 25.9821 144.825 26.3556 144.705 26.7201C143.588 30.2728 142.85 34.1093 143.244 37.8412C143.725 42.3649 147.021 47.6835 152.264 47.2323C153.914 47.039 155.524 46.5872 157.033 45.8937"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M145.926 34.6819C146.439 34.6673 146.841 34.1527 146.823 33.5324C146.806 32.9121 146.376 32.4212 145.863 32.4359C145.35 32.4502 144.948 32.9647 144.965 33.585C144.983 34.2053 145.413 34.6962 145.926 34.6819Z"
        fill="black"
      />
      <path
        d="M152.947 36.5696C153.46 36.5549 153.862 36.0404 153.845 35.4204C153.827 34.8001 153.397 34.3089 152.884 34.3236C152.371 34.3382 151.97 34.8527 151.987 35.4727C152.004 36.093 152.434 36.5842 152.947 36.5696Z"
        fill="black"
      />
      <path
        d="M149.915 36.9826C149.915 36.9826 147.199 37.5802 147.474 36.1878C147.557 35.7515 148.239 31.4669 148.239 31.4669C148.239 31.4669 146.972 28.9152 143.658 29.5098"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M151.135 31.0858C151.135 31.0858 155.44 30.3537 157.09 33.7928"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M148.645 50.751C145.253 52.5437 143.443 56.8583 144.862 59.9807C147.228 65.1827 153.458 66.7125 158.606 65.2096C164.331 63.5363 168.538 58.2596 168.287 52.2091"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M169.177 149.947C179.01 127.095 182.58 86.7613 182.58 86.7613V143.036C182.58 143.944 182.758 144.842 183.106 145.681C183.453 146.52 183.962 147.282 184.604 147.924C185.246 148.565 186.007 149.075 186.846 149.422C187.685 149.769 188.584 149.947 189.491 149.947H211.303C212.121 149.96 212.915 149.67 213.532 149.133C214.149 148.597 214.546 147.851 214.647 147.04C214.691 146.593 214.64 146.141 214.499 145.715C214.358 145.289 214.129 144.897 213.826 144.564C213.524 144.231 213.156 143.966 212.745 143.785C212.334 143.603 211.89 143.51 211.441 143.511H202.507C202.151 143.51 201.799 143.427 201.48 143.27C201.16 143.112 200.881 142.884 200.663 142.602C200.445 142.32 200.294 141.993 200.222 141.644C200.15 141.295 200.159 140.934 200.248 140.589C205.626 119.375 208.952 94.4731 201.951 72.4372C200.505 67.8895 197.388 63.8379 193.707 60.7065C189.482 57.0971 184.274 55.4418 178.938 54.2168C175.418 53.41 171.868 52.7425 168.286 52.2148C168.275 52.2184 168.264 52.2184 168.253 52.2148C168.229 52.2148 168.205 52.2148 168.191 52.2148H168.167H168.134H168.086"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M166.033 42.0615L165.639 49.1997C165.601 49.8974 165.82 50.5846 166.254 51.1317C166.689 51.6788 167.309 52.0481 167.996 52.1697H168.086C168.102 52.1655 168.118 52.1655 168.134 52.1697H168.149H168.191"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M168.253 52.2039H168.191H168.167H168.149H168.134H168.086H167.996C167.309 52.0823 166.689 51.713 166.254 51.1659C165.82 50.6185 165.601 49.9313 165.639 49.2339L166.033 42.0957"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M149.208 46.7867C149.014 46.709 149.208 54.7585 144.406 58.2544"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M84.9228 149.95H69.541"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M235.194 149.95H108.871"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M40.4316 74.3761C40.5798 77.3897 40.472 80.4105 40.1089 83.4056C39.6428 86.9254 36.389 91.3983 35.6838 94.8793C34.6769 99.8871 36.2007 105.062 38.2923 109.711C40.82 115.332 44.8029 120.985 44.4145 127.421C44.2155 130.224 43.3816 132.945 41.9755 135.378C40.5697 137.811 38.6284 139.892 36.2993 141.464C33.1091 143.599 29.4648 144.959 25.6564 145.438C25.1245 145.501 24.5837 145.552 24.0429 145.567C23.7441 145.567 23.4453 145.534 23.1465 145.51C13.0175 144.652 2.62848 136.925 2.65238 126.169C2.65238 119.297 7.87523 113.918 8.75667 107.249C9.29748 103.171 8.10235 98.9339 9.20788 94.9749C11.0006 88.6524 17.9296 85.2044 20.9653 79.3839C23.2003 75.0872 23.5648 70.0735 25.3337 65.5677C27.7957 59.293 31.7278 52.122 37.1061 51.5244C38.6667 51.7013 40.1753 52.1934 41.5401 52.9706C44.8747 54.7633 45.7232 54.8769 46.4015 56.4754C47.0947 58.1457 46.0609 59.9295 44.3338 60.4643C41.4176 61.3876 40.1597 67.3126 40.4316 74.3761Z"
        fill="#FF9900"
      />
      <path
        d="M43.3613 53.9433C52.1159 51.4066 57.6794 55.1385 61.6593 59.4411C65.6392 63.7437 65.454 68.629 65.454 68.629"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M70.5162 138.096C70.3841 144.935 69.5176 151.741 67.9316 158.396"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M92.2703 193.795C89.0642 199.311 81.46 203.228 72.1556 205.666C55.6444 209.993 33.7848 209.655 21.7076 205.221C-3.67182 195.92 6.19429 151.379 6.19429 151.379C6.19429 151.379 50.0898 143.117 79.3595 166.318C82.1556 168.528 84.7512 170.981 87.1161 173.648C90.352 177.308 92.339 180.657 93.2772 183.717C94.4275 187.53 93.9674 190.888 92.2703 193.795Z"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M75.4481 204.915C75.4481 204.915 60.0692 247.604 85.9058 258.507C111.742 269.41 -8.9726 270.862 17.6737 258.507C44.3199 246.151 29.0039 207.523 29.0039 207.523"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M106.731 59.8441C106.411 58.8671 106.542 55.4698 106.647 53.5515C106.667 53.1894 106.828 52.8491 107.094 52.6032C107.361 52.3573 107.713 52.2246 108.075 52.2339L138.77 52.5028C139.288 52.5075 139.782 52.7164 140.147 53.0839C140.511 53.4517 140.715 53.9483 140.715 54.4658V77.4998C140.715 77.7582 140.664 78.0143 140.565 78.253C140.466 78.4918 140.32 78.7087 140.137 78.891C139.954 79.0735 139.736 79.2178 139.497 79.3159C139.258 79.4142 139.002 79.4641 138.743 79.4629L108.831 79.3523C108.398 79.3344 107.988 79.1497 107.688 78.8369C107.388 78.5238 107.22 78.1066 107.221 77.6731V75.9162C109.348 76.9082 113.37 77.213 113.37 77.213C114.801 77.1741 117.063 77.0008 118.303 76.2897C119.438 75.6383 120.873 74.7031 121.067 73.2868C121.142 72.7391 120.997 72.184 120.664 71.7427C120.331 71.3013 119.836 71.01 119.289 70.9323C118.96 70.8666 118.62 70.8905 118.303 71.001"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M136.687 123.675C136.672 124.086 136.534 124.483 136.289 124.813C135.587 125.677 134.198 125.507 133.253 125.241C129.282 124.111 126.898 128.414 125.398 131.312C121.61 138.639 115.926 145.445 108.836 149.951C108.376 150.25 107.907 150.53 107.432 150.805C100.503 154.803 91.5242 154.059 84.8223 149.909L93.3916 128.859L94.2402 129.537C99.4153 133.622 105.785 131.33 110.196 127.299C111.42 126.184 112.5 124.92 113.411 123.538C114.929 121.204 116.228 118.685 118.305 116.788C121.179 114.161 125.386 113.647 129.02 114.759C132.653 115.87 137.015 119.444 136.687 123.675Z"
        fill="#F1F8F9"
      />
      <path
        d="M136.648 122.184C136.648 122.184 135.674 117.027 126.893 121.052"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M131.764 115.993C131.764 115.993 126.29 114.243 123.242 118.426"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M21.2688 145.521C22.7302 145.651 24.2014 145.627 25.658 145.449C29.4655 144.964 33.1078 143.6 36.298 141.466C38.6271 139.894 40.5681 137.813 41.9742 135.38C43.38 132.947 44.2142 130.226 44.4132 127.423C44.8016 120.984 40.8277 115.331 38.291 109.71C36.1994 105.058 34.6756 99.8831 35.6855 94.8813C36.3877 91.3974 39.6355 86.9272 40.1046 83.4044C40.4695 80.4093 40.5794 77.3888 40.4333 74.3749C40.1614 67.3084 41.4163 61.3777 44.3325 60.4753C46.0595 59.9405 47.0964 58.1537 46.4002 56.4864C45.7339 54.8849 44.8763 54.7893 41.5388 52.9756C32.838 48.2427 26.7875 57.0661 23.447 65.5727C21.6751 70.0815 21.3166 75.0893 19.0786 79.3859C16.0429 85.2094 9.09897 88.6485 7.32415 94.9829C6.21563 98.9389 7.4108 103.179 6.87297 107.251C5.99452 113.917 0.780606 119.293 0.765666 126.171C0.741763 136.924 11.1427 144.657 21.2688 145.521Z"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M92.2706 193.796C91.5206 199.132 90.4031 205.255 89.1721 211.383C85.6822 228.752 81.2422 246.225 81.2422 246.225"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M93.2645 183.716C93.2795 183.065 93.2884 182.467 93.2645 181.9C92.9986 169.649 84.1902 166.721 80.4015 166.019C79.8362 165.908 79.2643 165.835 78.6895 165.801"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M82.5573 240.928C83.0449 240.63 83.6033 240.466 84.1749 240.456C84.7465 240.445 85.3109 240.587 85.8096 240.866C86.3083 241.146 86.7233 241.554 87.012 242.047C87.3006 242.541 87.4524 243.103 87.4515 243.674C87.4515 243.716 87.4515 243.767 87.4515 243.827C87.4037 245.302 87.0149 246.746 86.3158 248.047C85.6163 249.347 84.6255 250.467 83.4208 251.32L79.2676 254.275"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M35.3789 98.8308C35.9586 98.5948 36.5502 98.3856 37.1478 98.1914C38.4057 95.8668 38.2115 90.7784 38.0919 88.9288L39.3499 86.0933C42.8218 88.2267 47.4172 87.4259 51.681 85.9409C52.2798 85.7318 52.8621 85.4781 53.4229 85.182C56.13 83.939 59.9247 80.9989 58.2215 79.481C56.5184 77.9631 56.6379 75.0409 57.7285 73.2482C58.0602 72.6984 58.6488 72.1576 59.2613 72.3279C59.6737 72.4415 59.9396 72.8269 60.1756 73.1825C60.4744 73.6277 60.7732 74.0549 61.072 74.4912C61.1766 74.6376 61.3469 74.79 61.5082 74.7242C61.5767 74.6794 61.6296 74.6149 61.6606 74.539C62.1865 73.4573 62.0879 71.9066 62.1775 70.7204C62.2821 69.3071 62.3628 67.8849 62.4046 66.4656C62.4285 65.6828 62.5899 64.8431 63.4295 64.5683C63.5989 64.5031 63.7812 64.4771 63.9622 64.4927C64.143 64.5082 64.3181 64.5647 64.4741 64.6579C64.6297 64.7514 64.7624 64.879 64.8613 65.0311C64.9605 65.1835 65.0235 65.3562 65.0459 65.5363C65.657 68.9704 65.7466 72.4767 65.3119 75.9373C64.8995 79.5617 64.1555 83.4072 61.8309 86.3293C60.5067 88.0151 58.7322 89.2913 56.7126 90.0105C54.7585 90.6887 54.8213 93.0462 54.8213 95.1139V98.547C60.83 99.7661 66.4742 101.236 71.1413 105.419C74.2278 108.241 77.1321 111.256 79.8361 114.446C88.3487 124.434 90.1893 126.308 94.2887 129.538C99.4638 133.622 105.837 131.331 110.244 127.3C111.466 126.184 112.544 124.921 113.453 123.538C114.971 121.204 116.271 118.686 118.344 116.788C121.222 114.162 125.429 113.648 129.062 114.759C132.695 115.871 137.064 119.445 136.717 123.675C136.702 124.086 136.565 124.483 136.323 124.814C135.621 125.677 134.231 125.507 133.287 125.241C129.313 124.112 126.932 128.414 125.429 131.313C121.643 138.639 115.957 145.446 108.867 149.951C108.407 150.25 107.94 150.531 107.462 150.806C99.5415 155.377 88.9224 153.746 82.1099 147.923L65.6764 133.972"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M37.1465 98.1918C37.1465 98.1918 44.9957 95.7656 54.82 98.5563"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M107.219 75.916C107.219 75.916 107.279 86.075 109.702 89.9862"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M115.182 29.4164C113.318 32.5986 113.405 34.8575 113.889 38.5237C113.929 38.8359 113.88 39.1529 113.747 39.4386C113.615 39.7242 113.404 39.9665 113.14 40.1377C112.876 40.3089 112.569 40.4019 112.254 40.406C111.939 40.4099 111.63 40.3251 111.361 40.161C100.604 33.97 90.5381 38.8673 77.696 35.8345C72.9811 34.726 67.1039 32.4342 63.0732 28.7442C61.7361 26.7581 61.0222 24.4182 61.0234 22.0243C61.0234 11.9878 74.735 3.84863 91.6406 3.84863C102.478 3.84863 111.997 7.19213 117.441 12.2298C117.441 12.2298 117.441 12.2298 117.456 12.2477C118.822 14.2114 119.568 16.54 119.596 18.9318C119.596 22.5442 116.918 26.4285 115.182 29.4164Z"
        fill="#D7E8F0"
      />
      <path
        d="M119.546 18.9395V19.1935C119.483 22.7102 117.723 25.979 115.635 28.8086C114.236 30.703 112.578 32.8035 112.972 35.1251C113.146 36.147 113.716 37.0762 113.869 38.113C114.021 39.1498 113.465 40.4197 112.429 40.4735C111.771 40.5064 111.21 40.0373 110.69 39.6339C107.603 37.2435 103.593 36.3472 99.6941 36.1918C95.7948 36.0364 91.9016 36.5474 87.9994 36.6579C82.3761 36.8193 76.6632 36.1171 71.4672 33.9688C66.7971 32.0206 62.5393 28.8534 59.7366 24.6375C56.9848 20.4813 58.6132 14.4516 61.5473 10.8391C64.3022 7.45083 68.2881 5.2816 72.3785 3.75476C83.377 -0.35364 95.9323 -0.255039 106.781 4.2418C110.002 5.56246 113.11 7.30741 115.53 9.82325C117.89 12.2763 119.525 15.5452 119.546 18.9395Z"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M74.4124 22.9362C76.7457 22.9362 78.6373 21.0448 78.6373 18.7113C78.6373 16.378 76.7457 14.4863 74.4124 14.4863C72.0792 14.4863 70.1875 16.378 70.1875 18.7113C70.1875 21.0448 72.0792 22.9362 74.4124 22.9362Z"
        fill="#F1F8F9"
      />
      <path
        d="M88.0726 22.9381C90.4058 22.9381 92.2975 21.0468 92.2975 18.7132C92.2975 16.3799 90.4058 14.4883 88.0726 14.4883C85.7393 14.4883 83.8477 16.3799 83.8477 18.7132C83.8477 21.0468 85.7393 22.9381 88.0726 22.9381Z"
        fill="#F1F8F9"
      />
      <path
        d="M102 22.9381C104.334 22.9381 106.225 21.0468 106.225 18.7132C106.225 16.3799 104.334 14.4883 102 14.4883C99.667 14.4883 97.7754 16.3799 97.7754 18.7132C97.7754 21.0468 99.667 22.9381 102 22.9381Z"
        fill="#F1F8F9"
      />
      <g opacity="0.54">
        <path
          opacity="0.54"
          d="M183.184 195.847L181.465 220.646C180.68 222.143 179.698 223.529 178.546 224.767C178.421 224.722 178.295 224.674 178.173 224.617C177.943 224.54 177.716 224.447 177.5 224.357C174.02 222.831 171.375 219.986 170.604 216.762C169.908 213.939 170.536 210.888 169.191 208.235C167.551 204.966 163.09 202.916 162.543 199.229C162.029 195.778 165.136 192.614 164.635 189.231C164.037 185.407 159.361 183.139 158.485 179.404C157.939 177.082 158.429 174.417 158.222 172.027C157.995 169.353 157.546 166.702 156.881 164.103C163.804 163.601 170.849 167.417 173.093 172.881C174.925 177.363 173.822 182.583 176.679 186.659C178.239 188.867 180.835 190.519 182.18 192.829C182.594 193.55 182.897 194.329 183.076 195.142C183.076 195.198 183.076 195.255 183.097 195.3C183.097 195.392 183.121 195.458 183.133 195.527C183.163 195.733 183.184 195.847 183.184 195.847Z"
          fill="#FF9900"
        />
        <path
          opacity="0.54"
          d="M183.195 195.824C183.195 195.824 183.174 195.723 183.15 195.525C183.171 195.604 183.186 195.684 183.195 195.764C183.199 195.774 183.202 195.784 183.202 195.794C183.202 195.804 183.199 195.815 183.195 195.824Z"
          fill="#FF9900"
        />
      </g>
      <path
        d="M206.068 186.146C205.172 189.367 200.263 191.324 199.671 194.626C199.148 197.554 202.381 200.285 201.846 203.258C201.276 206.446 196.629 208.209 194.923 211.026C193.534 213.315 194.182 215.948 193.465 218.383C192.634 221.15 189.88 223.621 186.246 224.929C186.019 225.01 185.804 225.088 185.574 225.156C185.449 225.192 185.311 225.237 185.186 225.27C183.99 224.281 182.601 222.539 181.475 220.66L183.184 195.845C184.516 194.715 186.04 193.681 187.101 192.427C188.251 191.073 188.786 189.594 189.127 188.043H189.163V187.873C189.662 185.426 189.706 182.862 190.857 180.541C193.19 175.817 200.514 172.524 207.72 172.957C207.04 175.189 206.575 177.48 206.331 179.8C206.125 181.852 206.633 184.153 206.068 186.146Z"
        fill="#FF9900"
      />
      <path
        d="M189.176 187.853V188.023H189.141L189.176 187.853Z"
        fill="#FF9900"
      />
      <path
        d="M189.175 187.854L189.139 188.025C188.798 189.575 188.263 191.055 187.113 192.408C186.052 193.663 184.529 194.697 183.196 195.826C183.201 195.811 183.201 195.794 183.196 195.778C183.187 195.698 183.171 195.618 183.151 195.539C183.151 195.471 183.127 195.405 183.115 195.312C183.115 195.268 183.115 195.211 183.094 195.154C182.915 194.342 182.613 193.562 182.198 192.841C180.853 190.532 178.257 188.879 176.697 186.671C176.697 186.671 177.402 175.033 183.357 171.493C189.004 168.146 189.175 185.918 189.175 187.854Z"
        fill="#FF9900"
      />
      <path
        d="M189.176 187.853V188.023H189.141L189.176 187.853Z"
        fill="#FF9900"
      />
      <path
        d="M183.183 195.776V195.845C183.183 195.845 183.163 195.743 183.139 195.546C183.159 195.621 183.174 195.698 183.183 195.776Z"
        fill="#FF9900"
      />
      <path
        d="M182.652 195.845C183.978 194.716 185.52 193.667 186.584 192.415C189.572 188.892 188.418 184.39 190.334 180.526C192.664 175.808 199.994 172.519 207.198 172.949C206.514 175.179 206.047 177.471 205.805 179.791C205.59 181.859 206.104 184.157 205.539 186.159C204.622 189.38 199.731 191.334 199.136 194.635C198.607 197.56 201.849 200.291 201.314 203.261C200.744 206.453 196.1 208.215 194.391 211.03C192.996 213.322 193.65 215.948 192.927 218.389C192.052 221.332 189.004 223.932 185.033 225.16C184.908 225.202 184.77 225.247 184.642 225.28C183.447 224.29 182.057 222.549 180.928 220.666"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M202.8 176.645C192.058 188.205 187.006 212.577 185.04 225.163C184.878 226.223 184.741 227.194 184.609 228.07"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M203.792 235.157L198.865 264.316C198.748 265.115 198.352 265.846 197.747 266.381C197.142 266.915 196.367 267.218 195.56 267.236H169.721C168.906 267.217 168.124 266.908 167.517 266.364C166.91 265.82 166.517 265.077 166.41 264.269L161.854 235.154L203.792 235.157Z"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M208.486 231.902C208.442 232.767 208.072 233.583 207.452 234.187C206.831 234.791 206.005 235.138 205.139 235.159H160.586C159.73 235.138 158.913 234.798 158.295 234.205C157.677 233.612 157.302 232.81 157.246 231.956C157.058 229.864 158.605 228.071 160.586 228.071H205.139C207.102 228.074 208.644 229.84 208.486 231.902Z"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M178.019 224.759C179.931 222.861 182.274 218.63 183.311 215.176C184.165 212.329 182.662 209.398 182.316 206.475C181.778 201.901 184.049 196.983 181.655 192.836C180.32 190.523 177.723 188.88 176.163 186.665C173.292 182.587 174.395 177.37 172.56 172.897C170.316 167.426 163.274 163.611 156.354 164.119C157.015 166.719 157.464 169.369 157.695 172.043C157.901 174.433 157.396 177.098 157.952 179.417C158.828 183.152 163.525 185.414 164.101 189.241C164.609 192.623 161.499 195.791 162.01 199.236C162.559 202.929 167.014 204.97 168.661 208.244C169.999 210.901 169.372 213.948 170.065 216.769C170.935 220.295 174.015 223.393 178.019 224.759Z"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M160.578 168.404C170.889 181.802 175.742 210.035 177.651 224.61C177.812 225.874 177.95 227.033 178.072 228.07"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M176.156 186.666C176.156 186.666 176.864 175.028 182.816 171.49C188.768 167.952 188.643 187.777 188.643 187.777"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
      <path
        d="M182.652 195.845C182.652 195.845 181.436 189.188 183.569 182.642"
        stroke="black"
        stroke-width="1.21011"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export default ImmersiveExperience;
