export const pricingFeatures = [
  {
    title: 'Collaboration',
    subTitleOne: 'Tap & Talk',
    contentOne: 'Instant immersive in-office experience.',
    subTitleTwo: 'Group Broadcast',
    contentTwo: 'Instant broadcast to everyone in groups.',
    subTitleThree: '3D Immersive Rooms',
    contentThree: 'Dive into a new dimension of collaboration.',
    subTitleFour: 'Documents Integration',
    contentFour:
      'Seamlessly incorporate your files from Onedrive, Dropbox or Google Drive.',
    subTitleFive: 'Chat',
    contentFive:
      'Real time communication with chat, docs and tasks at one place.',
    subTitleSix: 'Groups ( Internal & External )',
    contentSix: 'Collaboration among employees and clients',
  },
  {
    title: 'Productivity',
    subTitleOne: 'Project Management',
    contentOne: 'Streamline your projects effortlessly.',
    subTitleTwo: 'Org Insights',
    contentTwo: 'Unlock data-driven organization wisdom at your fingertips.',
    subTitleThree: 'Upskilling',
    contentThree: `Elevate your team's skills and knowledge.`,
    subTitleFour: 'Performance Review',
    contentFour: 'Define and manage performance KPIs and goals seamlessly.',
    subTitleFive: 'Timesheets',
    contentFive: 'Simplify time tracking and reporting.',
    subTitleSix: 'Leave Management',
    contentSix: 'Manage leaves with ease.',
    subTitleSeven: 'Asset Management',
    contentSeven: 'Assign and manage hardware & software assets at one place.',
  },
  {
    title: 'Engagement',
    subTitleOne: 'Wall of Moments',
    contentOne: 'Your personal social space.',
    subTitleTwo: 'Birthdays/Anniversaries',
    contentTwo: 'Birthdays/Anniversaries',
    subTitleThree: 'Kudos & Rewards',
    contentThree: 'Celebrate achievements and achievers.',
    subTitleFour: 'Company Announcements',
    contentFour: 'Organization wide announcements made easy.',
  },
  {
    title: 'Wellness',
    subTitleOne: 'Online Health Camps',
    contentOne: 'Prioritize employee well-being.',
    subTitleTwo: 'Stress & Burnouts',
    contentTwo: 'Monitor, measure and address stress(surveys) and burnouts.',
    subTitleThree: 'Health reminders',
    contentThree: 'Frequent nudges for screen time, hydration and others.',
  },
];
