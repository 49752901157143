import { useLayoutState } from '@worx.squad/shared-frontend';
import { useLayoutEffect } from 'react';
import MostRecentBlog from '../components/Blog/MostRecentBlog';
import { Starter } from '../components/Blog/Starters';
import Challenges from '../components/CommonComponent/Challenges';
import ContactUs from '../components/CommonComponent/ContactUs';
import { Footer } from '../components/CommonComponent/Footer';
import { Header } from '../components/CommonComponent/Header';

const Blog = () => {
  const [, setLayoutState] = useLayoutState();
  useLayoutEffect(() => {
    setLayoutState?.('normal');
  }, [setLayoutState]);

  return (
    <div className="relative">
      <div className="sticky bg-[#000000] top-0 z-[9999] shadow-lg">
        <Header />
      </div>
      <div
        className="pt-1 place-content-center py-14 bg-cover bg-no-repeat bg-center sm:h-screen"
        style={{
          backgroundImage:
            'url(../../assets/FutureOfWork/futureOfWorkBanner.jpg)',
        }}
      >
        <Starter />
      </div>
      <div className="bg-[#000000] ">
        <Challenges />
      </div>
      <div>
        <MostRecentBlog />
      </div>
      <div className="pt-1 bg-[#000000]">
        <ContactUs />
        <Footer />
      </div>
    </div>
  );
};

export default Blog;
