import '../RadarAnimation.scss';
const LiveOffice = () => {
  return (
    <svg
      width="58"
      height="63"
      viewBox="0 0 58 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4247 0.625777C12.2635 1.46015 12.2635 2.81293 11.4247 3.6473C1.91903 13.1035 1.91903 28.435 11.4247 37.8912C12.2635 38.7255 12.2635 40.0783 11.4247 40.9127C10.586 41.7471 9.22614 41.7471 8.3874 40.9127C-2.7958 29.7878 -2.7958 11.7507 8.3874 0.625777C9.22614 -0.208592 10.586 -0.208592 11.4247 0.625777ZM48.8854 0.625777C60.0686 11.7507 60.0686 29.7878 48.8854 40.9127C48.0466 41.7471 46.6867 41.7471 45.848 40.9127C45.0093 40.0783 45.0093 38.7255 45.848 37.8912C55.3537 28.435 55.3537 13.1035 45.848 3.6473C45.0093 2.81293 45.0093 1.46015 45.848 0.625777C46.6867 -0.208592 48.0466 -0.208592 48.8854 0.625777ZM19.5243 8.68316C20.3631 9.51753 20.3631 10.8703 19.5243 11.7047C14.4919 16.7109 14.4919 24.8276 19.5243 29.8338C20.3631 30.6682 20.3631 32.0209 19.5243 32.8553C18.6856 33.6897 17.3257 33.6897 16.487 32.8553C9.77707 26.1804 9.77707 15.3581 16.487 8.68316C17.3257 7.84879 18.6856 7.84879 19.5243 8.68316ZM40.7858 8.68316C47.4957 15.3581 47.4957 26.1804 40.7858 32.8553C39.947 33.6897 38.5872 33.6897 37.7484 32.8553C36.9097 32.0209 36.9097 30.6682 37.7484 29.8338C42.7809 24.8276 42.7809 16.7109 37.7484 11.7047C36.9097 10.8703 36.9097 9.51753 37.7484 8.68316C38.5872 7.84879 39.947 7.84879 40.7858 8.68316ZM28.6364 16.4962C31.0087 16.4962 32.9318 18.4093 32.9318 20.7692C32.9318 23.1292 31.0087 25.0423 28.6364 25.0423C26.2641 25.0423 24.3409 23.1292 24.3409 20.7692C24.3409 18.4093 26.2641 16.4962 28.6364 16.4962Z"
        fill="#FF9900"
        id="liveOffice"
      />
      <path
        d="M28.6367 30L28.6367 60"
        stroke="#FF9900"
        stroke-width="4.77273"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default LiveOffice;
