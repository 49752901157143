const FullEcosystem = () => {
  return (
    <svg
      width="268"
      height="268"
      viewBox="0 0 268 268"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.87">
        <path
          d="M47.498 122.592C56.3179 110.264 69.5357 104.676 69.5357 104.676C69.9672 104.497 68.1287 87.6074 68.0912 86.4095C67.5337 66.6793 70.806 46.1505 85.6371 31.9036C104.177 14.1057 137.817 10.418 159.085 25.4126C158.817 25.2143 166.083 19.4871 166.696 19.12C170.666 16.7243 175.145 15.3001 179.769 14.9633C198.379 13.6233 215.446 28.2454 222.427 45.9334C229.408 63.6214 227.806 103.923 214.998 115.546"
          stroke="#185D74"
          stroke-width="1.33022"
          stroke-miterlimit="10"
        />
        <path
          d="M39.5449 143.981C40.0035 139.125 41.2518 134.377 43.2406 129.924C43.4301 129.501 43.6284 129.086 43.8356 128.681"
          stroke="#CCDDE2"
          stroke-width="1.33022"
          stroke-miterlimit="10"
        />
        <path
          d="M62.5433 195.289C60.3993 193.34 58.4135 191.357 56.5938 189.363"
          stroke="#CCDDE2"
          stroke-width="1.33022"
          stroke-miterlimit="10"
        />
        <path
          d="M241.194 137.341C241.21 138.681 241.194 140.048 241.13 141.418C240.75 149.889 238.935 158.645 234.401 165.779C233.575 167.095 232.654 168.349 231.646 169.531C221.998 168.258 220.558 158.417 209.402 152.853C196.036 146.172 174.152 148.565 174.152 148.565L184.933 138.62C184.357 108.668 162.368 110.506 162.368 110.506L161.25 113.243C162.099 110.764 162.957 108.454 163.825 106.31L163.874 106.197C174.058 80.9031 184.609 77.7675 192.818 74.1147C194.779 73.6489 196.787 73.4096 198.802 73.4018C219.789 73.2892 232.107 94.6623 237.493 111.868C238.833 116.156 240.894 125.327 241.17 135.639C241.176 136.192 241.194 136.768 241.194 137.341Z"
          fill="black"
        />
        <path
          d="M252.996 249.607C251.366 251.851 248.678 253.073 245.907 253.073H214.069C212.845 253.09 211.631 252.851 210.506 252.372C209.38 251.892 208.367 251.182 207.532 250.288C207.419 250.173 207.313 250.052 207.216 249.924C206.218 248.688 205.594 247.192 205.418 245.614C205.257 244.164 206.334 242.875 207.792 242.934C208.344 242.955 208.87 243.176 209.272 243.556C209.674 243.935 209.926 244.447 209.979 244.998C209.979 245.113 210.003 245.226 210.019 245.338C210.137 245.992 210.425 246.604 210.853 247.112C211.231 247.56 211.707 247.915 212.243 248.153C212.779 248.39 213.362 248.502 213.948 248.482H246.044C246.618 248.491 247.188 248.377 247.714 248.145C248.24 247.914 248.71 247.572 249.091 247.142C249.52 246.644 249.814 246.044 249.941 245.399C250.069 244.755 250.027 244.088 249.82 243.465L235.484 200.839C235.367 200.512 235.229 200.194 235.069 199.885C234.228 198.108 232.894 196.609 231.226 195.566C229.558 194.524 227.626 193.982 225.66 194.005C219.624 194.078 211.962 194.094 211.241 194.094C195.18 194.035 187.872 194.035 183.852 194.142C184.127 193.154 184.626 192.243 185.309 191.478C185.309 191.478 185.326 191.462 185.326 191.454C186.306 190.409 187.648 189.777 189.078 189.688C195.033 189.2 200.929 188.31 206.656 186.756C216.762 184.017 226.402 179.319 233.099 171.764C236.359 168.107 238.823 163.812 240.335 159.152C241.509 155.452 242.248 151.628 242.538 147.757C242.642 146.535 242.706 145.303 242.731 144.064V144.007C242.731 143.723 242.747 143.431 242.747 143.147C242.784 141.381 242.703 139.615 242.506 137.859C244.256 137.873 245.932 138.567 247.18 139.794C247.289 140.083 247.37 140.382 247.421 140.687C247.47 140.975 247.494 141.268 247.494 141.56V176.226C247.496 178.594 247.029 180.939 246.119 183.124C245.208 185.31 243.873 187.293 242.19 188.959C239.403 191.719 238.609 195.908 239.931 199.593L254.177 241.985C254.608 243.257 254.725 244.614 254.519 245.941C254.314 247.268 253.791 248.525 252.996 249.607Z"
          fill="#FF9900"
        />
        <path
          d="M56.5957 248.532V151.347H62.5426V248.572L56.5957 248.532Z"
          fill="#2E2E2E"
        />
        <path
          d="M174.164 148.544C178.423 148.091 183.348 147.555 188.079 147.662"
          stroke="black"
          stroke-width="1.33022"
          stroke-miterlimit="10"
        />
        <path
          d="M150.959 152.838C143.417 154.741 136.079 157.488 129.819 162.054C119.313 169.708 114.511 183.988 118.804 196.358C118.804 196.374 135.688 245.078 135.688 245.078H131.245C130.287 245.078 129.339 245.267 128.455 245.635C127.571 246.003 126.768 246.542 126.094 247.222C124.772 248.535 124.121 250.725 124.121 252.735H164.115L148.917 203.021C148.538 201.796 148.452 200.498 148.668 199.234C148.883 197.969 149.393 196.773 150.157 195.742C150.92 194.712 151.916 193.875 153.063 193.301C154.21 192.726 155.476 192.429 156.758 192.435C165.417 192.679 173.46 193.011 182.218 192.631"
          stroke="black"
          stroke-width="1.33022"
          stroke-miterlimit="10"
        />
        <path
          d="M241.259 141.819V141.765C240.87 131.313 240.345 121 237.485 111.856C232.099 94.6505 219.781 73.2775 198.794 73.3901C196.779 73.3979 194.771 73.6372 192.81 74.103C178.976 77.3913 169.368 91.5659 163.866 106.185L163.817 106.298C163.292 107.7 162.806 109.099 162.36 110.495C162.057 111.434 161.683 112.348 161.242 113.231C159.557 116.627 156.885 119.436 153.577 121.289C150.269 123.142 146.478 123.953 142.702 123.616C117.295 121.306 83.8114 119.987 73.2656 137.839"
          stroke="black"
          stroke-width="1.33022"
          stroke-miterlimit="10"
        />
        <path
          d="M199.683 122.377C195.985 126.842 192.091 131.154 188.065 135.324C179.824 143.868 172.714 152.942 159.925 153.548C149.723 154.038 140.01 150.123 130.362 147.408C120.042 144.506 114.818 142.185 105.2 139.253C102.43 138.412 99.556 137.961 96.6614 137.913L86.9785 137.988"
          stroke="black"
          stroke-width="1.33022"
          stroke-miterlimit="10"
        />
        <path
          d="M161.924 245.716C162.846 245.302 163.846 245.09 164.856 245.094H169.31C169.31 245.094 160 226.996 152.555 214.949"
          stroke="black"
          stroke-width="1.33022"
          stroke-miterlimit="10"
        />
        <path
          d="M168.038 192.674L199.18 253.062H163.954H157.564"
          stroke="black"
          stroke-width="1.33022"
          stroke-miterlimit="10"
        />
        <path
          d="M252.989 249.595C251.36 251.838 248.672 253.06 245.901 253.06H214.062C211.422 253.06 208.847 251.967 207.201 249.903C206.204 248.667 205.58 247.172 205.403 245.594C205.242 244.144 206.32 242.855 207.778 242.914C208.33 242.935 208.856 243.156 209.258 243.535C209.66 243.915 209.911 244.427 209.964 244.977C210.03 245.756 210.335 246.495 210.838 247.092C211.222 247.532 211.699 247.884 212.234 248.12C212.769 248.357 213.349 248.473 213.934 248.461H246.037C246.612 248.471 247.182 248.357 247.708 248.125C248.233 247.894 248.703 247.551 249.084 247.121C249.514 246.624 249.807 246.024 249.935 245.379C250.063 244.735 250.021 244.068 249.813 243.444L235.478 200.827C234.752 198.809 233.416 197.067 231.656 195.843C229.895 194.618 227.797 193.972 225.653 193.993C219.618 194.065 211.956 194.081 211.235 194.081C193.351 194.025 186.311 194.025 182.58 194.162C182.522 194.161 182.465 194.15 182.411 194.127C182.357 194.105 182.309 194.072 182.268 194.03C182.227 193.989 182.195 193.94 182.173 193.886C182.151 193.833 182.14 193.775 182.141 193.717C182.135 192.062 182.742 190.464 183.845 189.231C183.849 189.227 183.853 189.223 183.856 189.219C183.859 189.214 183.86 189.209 183.861 189.204C184.843 188.161 186.184 187.531 187.613 187.44C193.568 186.953 199.464 186.063 205.191 184.509C215.298 181.77 224.938 177.072 231.635 169.517C234.895 165.859 237.359 161.564 238.871 156.905C240.041 153.206 240.777 149.383 241.066 145.515C241.171 144.289 241.235 143.058 241.259 141.821V141.763C241.259 141.481 241.275 141.189 241.275 140.905C241.311 139.139 241.231 137.372 241.034 135.617C243.95 135.601 246.889 137.721 247.399 140.68C247.447 140.968 247.471 141.261 247.471 141.553V176.214C247.474 178.582 247.007 180.926 246.096 183.112C245.186 185.298 243.85 187.281 242.167 188.947C239.38 191.707 238.587 195.896 239.908 199.581L254.155 241.973C254.587 243.244 254.708 244.6 254.505 245.927C254.302 247.253 253.782 248.512 252.989 249.595Z"
          stroke="black"
          stroke-width="1.33022"
          stroke-miterlimit="10"
        />
        <path
          d="M100.029 143.984H17.08C15.0478 143.984 13.4004 145.632 13.4004 147.664V147.667C13.4004 149.699 15.0478 151.346 17.08 151.346H100.029C102.061 151.346 103.708 149.699 103.708 147.667V147.664C103.708 145.632 102.061 143.984 100.029 143.984Z"
          stroke="black"
          stroke-width="1.33022"
          stroke-miterlimit="10"
        />
        <path
          d="M76.5386 248.986H42.9984C41.8602 248.986 40.9375 249.909 40.9375 251.047C40.9375 252.185 41.8602 253.108 42.9984 253.108H76.5386C77.6768 253.108 78.5995 252.185 78.5995 251.047C78.5995 249.909 77.6768 248.986 76.5386 248.986Z"
          stroke="black"
          stroke-width="1.33022"
          stroke-miterlimit="10"
        />
        <path
          d="M51.9531 140.676V141.046C52.0067 143.589 54.4 143.978 56.4636 143.994L96.358 143.967C98.1054 143.967 99.5472 142.627 99.5499 140.982V140.912C99.5178 139.304 98.1134 137.993 96.3875 137.991C96.3875 137.991 74.0685 137.91 56.7825 137.859C56.4716 138.765 55.6837 139.467 54.7001 140.041C53.854 140.484 52.908 140.703 51.9531 140.676Z"
          stroke="black"
          stroke-width="1.33022"
          stroke-miterlimit="10"
        />
        <path
          d="M30.0185 104.85C30.0268 105.274 30.148 105.687 30.3696 106.048L48.8616 137.107C49.1009 137.48 49.415 137.8 49.7838 138.046C50.1528 138.293 50.5688 138.46 51.0056 138.538C51.7445 138.683 52.5102 138.6 53.2005 138.299C54.3342 137.796 54.9881 136.78 54.9667 135.751C54.9626 135.621 54.9492 135.492 54.9265 135.365C54.8696 135.081 54.7643 134.809 54.6156 134.561L36.1236 103.502C35.3196 102.143 33.3525 101.61 31.7713 102.31C30.643 102.803 29.9891 103.818 30.0185 104.85Z"
          stroke="black"
          stroke-width="1.33022"
          stroke-miterlimit="10"
        />
        <path
          d="M80.0156 137.998C80.0156 137.998 82.2427 135.878 87.3642 132.512"
          stroke="black"
          stroke-width="1.33022"
          stroke-miterlimit="10"
        />
        <path
          d="M98.1768 103.152L90.059 112.154C89.4938 112.781 88.7091 113.167 87.8673 113.232C87.0253 113.298 86.1905 113.037 85.5352 112.505C85.1731 112.211 84.8778 111.844 84.6682 111.427C84.4586 111.011 84.3396 110.554 84.3195 110.089C84.2992 109.623 84.378 109.158 84.5506 108.725C84.7232 108.292 84.9858 107.9 85.3208 107.576L86.8055 106.14C86.8618 106.059 86.9261 105.987 86.9904 105.912L88.0624 104.73L97.2816 94.7122C98.4005 93.4823 99.8097 92.5526 101.38 92.0078C102.951 91.4627 104.633 91.3201 106.273 91.5927L106.41 91.6168"
          stroke="black"
          stroke-width="1.33022"
          stroke-miterlimit="10"
        />
        <path
          d="M109.62 105.584H101.5C100.576 105.584 99.6895 105.217 99.0362 104.564C98.3828 103.91 98.0156 103.024 98.0156 102.1C98.0156 101.176 98.3828 100.29 99.0362 99.6368C99.6895 98.9834 100.576 98.6162 101.5 98.6162H113.024"
          stroke="black"
          stroke-width="1.33022"
          stroke-miterlimit="10"
        />
        <path
          d="M103.391 105.587C103.278 107.01 102.734 108.063 101.933 108.803C99.869 110.679 96.0849 110.473 93.4531 109.183"
          stroke="black"
          stroke-width="1.33022"
          stroke-miterlimit="10"
        />
        <path
          d="M88.0802 104.728C85.7459 101.916 86.2256 98.0947 88.04 95.1386C95.5306 82.9071 109.81 90.4406 119.198 95.7122C124.786 98.8652 130.128 102.436 135.178 106.395C139.649 109.879 143.787 114.049 149.157 116.144C152.373 117.401 156.171 117.702 159.371 116.252"
          stroke="black"
          stroke-width="1.33022"
          stroke-miterlimit="10"
        />
        <path
          d="M101.934 108.784C107.598 112.965 113.007 117.482 118.132 122.31"
          stroke="black"
          stroke-width="1.33022"
          stroke-miterlimit="10"
        />
        <path
          d="M170.85 43.0384C172.206 44.2765 174.288 44.3435 176.012 44.1774C178.215 43.9764 180.396 43.4431 182.554 42.9928L183.864 42.9258H183.883C184.134 42.9285 184.382 42.9802 184.614 43.0788C184.845 43.1772 185.055 43.3203 185.23 43.4996C185.406 43.6792 185.545 43.8914 185.639 44.1246C185.733 44.3577 185.78 44.607 185.778 44.8581C185.808 47.6866 185.525 50.5097 184.936 53.276L187.447 54.6347C187.771 53.7289 187.407 52.4237 188.176 52.3246C188.669 51.4053 189.404 50.8077 190.556 51.4911C192.813 52.8311 193.702 56.768 191.529 58.5717L192.922 61.1766C193.408 62.09 194.196 62.8061 195.151 63.203C196.106 63.5999 197.169 63.6529 198.159 63.3528L196.736 60.2065C200.075 60.7987 202.412 56.6313 201.394 54.088C200.7 52.3407 200.735 50.9819 201.662 49.2828C201.724 49.1675 201.094 49.0148 201.147 48.8969C201.18 47.6507 200.834 46.316 200.233 45.6809C199.178 44.5445 197.052 44.3409 197.486 42.6149C197.679 41.864 197.751 41.0868 197.698 40.3128C197.585 39.3011 197.256 38.3253 196.734 37.4514C196.212 36.5772 195.509 35.8249 194.672 35.2449C192.914 34.0899 190.947 35.1377 189.069 35.1083C186.788 35.0841 185.153 33.1492 182.763 33.1197C181.573 33.1009 180.619 33.5646 179.6 34.1408C178.748 34.6312 177.909 35.1431 177.094 35.6979C176.28 36.2526 175.556 36.7323 174.583 36.735C173.316 36.7511 172.091 35.9471 170.815 36.3223C170.17 36.496 169.603 36.8835 169.207 37.4211C168.802 39.3989 169.534 41.8458 170.85 43.0384Z"
          fill="#FF9900"
        />
        <path
          d="M187.467 54.327C187.63 53.6289 187.871 52.9511 188.185 52.3063C188.678 51.3871 189.415 50.7894 190.565 51.4728C192.821 52.8128 193.711 56.7497 191.538 58.5534L192.931 61.1583C193.417 62.0714 194.205 62.7878 195.16 63.1847C196.115 63.5816 197.178 63.6344 198.168 63.3345"
          stroke="black"
          stroke-width="1.33022"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M196.736 60.1875L198.154 63.3392C198.51 64.0923 199.532 66.4078 200.601 68.8144C201.362 70.5377 202.155 72.3118 202.745 73.617"
          stroke="black"
          stroke-width="1.33022"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M173.771 48.8821C173.771 48.8821 177.95 47.8422 179.775 51.01"
          stroke="black"
          stroke-width="1.33022"
          stroke-miterlimit="10"
        />
        <path
          d="M175.01 58.3398C173.884 58.648 172.37 58.9241 171.502 57.9432L173.394 53.9607C173.853 52.9841 173.967 51.8802 173.716 50.8305C173.303 49.0643 172.271 46.3817 173.378 44.0527"
          stroke="black"
          stroke-width="1.33022"
          stroke-miterlimit="10"
        />
        <path
          d="M177.809 52.996C178.126 52.4699 177.882 51.7412 177.263 51.3684C176.644 50.9956 175.886 51.1197 175.569 51.6458C175.251 52.1719 175.496 52.9005 176.115 53.2733C176.733 53.6464 177.492 53.522 177.809 52.996Z"
          fill="black"
        />
        <path
          d="M183.906 78.0695L182.914 67.0654"
          stroke="black"
          stroke-width="1.33022"
          stroke-miterlimit="10"
        />
        <path
          d="M187.451 54.6186C187.451 54.5221 187.451 54.423 187.451 54.3265C187.457 54.2231 187.457 54.1193 187.451 54.0156"
          stroke="black"
          stroke-width="1.33022"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M187.457 54.631L186.594 54.1673L184.943 53.2695"
          stroke="black"
          stroke-width="1.33022"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M180.541 58.6113C180.541 58.6113 180.458 61.3985 177.271 62.5456"
          stroke="black"
          stroke-width="1.33022"
          stroke-miterlimit="10"
        />
        <path
          d="M196.737 60.1919C200.076 60.7841 202.413 56.6167 201.395 54.0734C200.701 52.3261 200.736 50.9673 201.663 49.2682C201.724 49.1529 201.775 49.0404 201.829 48.9278C202.518 47.4083 202.274 45.9021 201.191 44.6103C200.165 43.3883 198.618 42.9595 199.063 41.1773C199.263 40.4035 199.337 39.6028 199.283 38.8055C199.168 37.7648 198.83 36.7612 198.294 35.862C197.758 34.9629 197.035 34.1892 196.174 33.5929C194.368 32.3949 192.334 33.4803 190.388 33.4508C188.046 33.416 186.355 31.4221 183.897 31.3926C182.667 31.3926 181.675 31.8509 180.63 32.4458C179.751 32.9523 178.88 33.4857 178.049 34.0538C177.219 34.622 176.441 35.1258 175.455 35.1258C174.147 35.1258 172.885 34.3218 171.577 34.697C170.269 35.0722 169.492 36.1549 169.235 37.4092C168.817 39.3763 169.548 41.8339 170.864 43.0372C172.22 44.2753 174.303 44.3423 176.026 44.1762C178.229 43.9752 180.41 43.4419 182.568 42.9916L183.878 42.9246H183.897C184.149 42.927 184.397 42.979 184.628 43.0774C184.859 43.176 185.069 43.3188 185.245 43.4984C185.421 43.6777 185.56 43.8902 185.654 44.1231C185.747 44.3563 185.794 44.6055 185.792 44.8569C185.822 47.6854 185.54 50.5082 184.95 53.2748"
          stroke="black"
          stroke-width="1.33022"
          stroke-miterlimit="10"
        />
        <path
          d="M187.96 63.2501C187.96 63.2501 182.981 69.8644 178.556 68.3823C174.131 66.9003 173.906 58.7129 173.906 58.7129"
          stroke="black"
          stroke-width="1.33022"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M56.5957 248.532V151.347H62.5426V248.572"
          stroke="black"
          stroke-width="1.33022"
          stroke-miterlimit="10"
        />
        <path
          d="M62.0705 94.7943C67.3723 94.7943 71.6702 90.4963 71.6702 85.1945C71.6702 79.8926 67.3723 75.5947 62.0705 75.5947C56.7686 75.5947 52.4707 79.8926 52.4707 85.1945C52.4707 90.4963 56.7686 94.7943 62.0705 94.7943Z"
          fill="#FF9900"
        />
        <path
          d="M58.3026 87.6729C58.0218 87.481 57.7725 87.2468 57.5629 86.9788C57.1923 86.52 57.0028 85.9413 57.0299 85.3523C57.057 84.7632 57.2992 84.2044 57.7103 83.7815C57.9786 83.5055 58.3072 83.2951 58.6703 83.1673C59.0335 83.0394 59.4213 82.9974 59.8034 83.0445C59.7187 82.5131 59.8093 81.9682 60.0618 81.4928C60.314 81.0174 60.7144 80.6368 61.2018 80.409C61.6896 80.1812 62.2382 80.118 62.7646 80.2295C63.2912 80.341 63.7674 80.6207 64.1209 81.0265C64.8686 81.8734 65.2197 83.133 65.351 84.2425C65.4188 84.8216 65.4279 85.4064 65.3778 85.9872C65.3537 86.2793 65.2492 87.965 64.9758 88.0722C63.1695 88.7637 60.0902 88.9137 58.3026 87.6729Z"
          fill="#F1F8F9"
        />
        <path
          d="M129.804 92.5265C132.646 92.5265 134.949 90.2228 134.949 87.381C134.949 84.5391 132.646 82.2354 129.804 82.2354C126.962 82.2354 124.658 84.5391 124.658 87.381C124.658 90.2228 126.962 92.5265 129.804 92.5265Z"
          fill="#FF9900"
        />
        <path
          d="M129.448 85.0036C129.605 84.909 129.776 84.8412 129.955 84.8026C130.262 84.7286 130.586 84.7696 130.865 84.9175C131.145 85.0657 131.36 85.3107 131.472 85.6066C131.544 85.7998 131.571 86.0075 131.55 86.213C131.529 86.4183 131.461 86.6164 131.351 86.7911C131.622 86.8772 131.859 87.0473 132.027 87.277C132.194 87.5067 132.285 87.7835 132.285 88.0681C132.285 88.3525 132.194 88.6296 132.027 88.8593C131.859 89.0887 131.622 89.2589 131.351 89.3452C130.737 89.4794 130.098 89.4328 129.51 89.2112C129.214 89.1112 128.928 88.982 128.658 88.8252C128.521 88.7475 127.725 88.3134 127.738 88.1552C127.816 87.1154 128.446 85.5878 129.448 85.0036Z"
          fill="#F1F8F9"
        />
        <path
          d="M89.9043 81.5162C90.481 81.9975 91.1247 82.3921 91.8151 82.6874C93.0865 83.218 94.4739 83.4094 95.8415 83.2427C97.2092 83.076 98.51 82.5569 99.6166 81.736C100.474 81.0821 101.225 80.1789 102.112 79.5223C102.73 79.0375 103.474 78.7365 104.256 78.654C104.516 78.6615 104.774 78.6071 105.009 78.4959C105.604 78.126 104.902 77.5123 104.84 77.0781C104.757 76.3838 104.932 75.6832 105.33 75.1083C106.348 73.5904 106.81 71.7669 106.637 69.9472C106.463 68.1277 105.666 66.4241 104.38 65.1253C103.094 63.8266 101.398 63.0127 99.5804 62.8216C97.7626 62.6308 95.9345 63.0746 94.4067 64.0775C92.8978 65.0664 91.7776 66.5323 90.6413 67.934C89.639 69.1695 88.5911 70.3862 87.8568 71.7985C86.1308 75.1191 87.1385 79.2087 89.9043 81.5162Z"
          fill="#FF9900"
        />
        <path
          d="M100.142 70.5764C101.09 70.5764 101.86 69.8072 101.86 68.8585C101.86 67.9095 101.09 67.1406 100.142 67.1406C99.193 67.1406 98.4238 67.9095 98.4238 68.8585C98.4238 69.8072 99.193 70.5764 100.142 70.5764Z"
          fill="#F1F8F9"
        />
        <path
          d="M96.8077 74.6321C97.7564 74.6321 98.5256 73.8629 98.5256 72.9142C98.5256 71.9655 97.7564 71.1963 96.8077 71.1963C95.859 71.1963 95.0898 71.9655 95.0898 72.9142C95.0898 73.8629 95.859 74.6321 96.8077 74.6321Z"
          fill="#F1F8F9"
        />
        <path
          d="M93.6983 78.4446C94.6471 78.4446 95.4162 77.6754 95.4162 76.7267C95.4162 75.778 94.6471 75.0088 93.6983 75.0088C92.7496 75.0088 91.9805 75.778 91.9805 76.7267C91.9805 77.6754 92.7496 78.4446 93.6983 78.4446Z"
          fill="#F1F8F9"
        />
        <g opacity="0.46">
          <path
            opacity="0.46"
            d="M143.382 60.6502C143.248 61.3232 142.965 61.9573 142.554 62.5069C142.143 63.0563 141.615 63.5071 141.007 63.826L126.68 71.4372C126.14 71.7256 125.548 71.9044 124.938 71.9636C124.329 72.0225 123.713 71.9606 123.128 71.7813C122.542 71.6021 121.998 71.3091 121.526 70.9189C121.054 70.5287 120.663 70.049 120.377 69.5076L108.957 48.006C108.379 46.9155 108.257 45.6401 108.618 44.4595C108.979 43.2793 109.794 42.2903 110.884 41.7107L125.222 34.1021C127.38 32.9551 130.357 33.8341 131.515 36.0291L142.937 57.5227C143.451 58.4797 143.609 59.5879 143.382 60.6502Z"
            fill="#FF9900"
          />
        </g>
        <path
          d="M139.131 59.0177L125.16 53.6925C124.982 53.6234 124.834 53.4931 124.743 53.3254C124.652 53.1573 124.624 52.9625 124.664 52.776L127.904 37.8725C127.941 37.7138 128.025 37.5702 128.145 37.4606C128.265 37.3507 128.416 37.28 128.577 37.2577C128.738 37.2352 128.902 37.2623 129.048 37.3352C129.193 37.4081 129.313 37.5236 129.392 37.6661L140.122 57.8894C140.198 58.0333 140.228 58.1971 140.209 58.3587C140.19 58.5203 140.122 58.6725 140.015 58.7947C139.907 58.9169 139.765 59.0038 139.607 59.0437C139.45 59.0834 139.283 59.0742 139.131 59.0177Z"
          fill="#F1F8F9"
        />
        <g opacity="0.46">
          <path
            opacity="0.46"
            d="M159.995 75.6004C160.288 75.8105 160.531 76.0839 160.704 76.4004C160.877 76.7167 160.978 77.068 160.998 77.4282L161.534 85.9479C161.554 86.2695 161.51 86.5919 161.406 86.8966C161.302 87.2016 161.138 87.4827 160.925 87.7242C160.711 87.9657 160.452 88.1627 160.163 88.3039C159.873 88.4451 159.558 88.5279 159.237 88.5475L146.45 89.3354C145.802 89.3743 145.165 89.1545 144.679 88.7244C144.193 88.2945 143.897 87.6891 143.856 87.0413L143.334 78.5163C143.311 77.8725 143.535 77.2446 143.962 76.7619C144.388 76.2793 144.983 75.9786 145.625 75.922L158.409 75.118C158.978 75.0861 159.54 75.2571 159.995 75.6004Z"
            fill="#FF9900"
          />
        </g>
        <path
          d="M158.296 77.2828L152.7 82.8224C152.629 82.8897 152.536 82.9304 152.438 82.9371C152.341 82.9441 152.244 82.9167 152.164 82.8599L145.732 78.0627C145.663 78.011 145.612 77.9394 145.585 77.8577C145.559 77.776 145.557 77.6878 145.582 77.6055C145.607 77.5232 145.657 77.4506 145.724 77.397C145.791 77.3437 145.874 77.3123 145.959 77.307L157.987 76.5646C158.071 76.5624 158.154 76.5855 158.225 76.6311C158.296 76.6764 158.352 76.742 158.385 76.8195C158.418 76.8966 158.427 76.9821 158.411 77.0649C158.395 77.1475 158.355 77.2233 158.296 77.2828Z"
          fill="#F1F8F9"
        />
        <g opacity="0.46">
          <path
            opacity="0.46"
            d="M73.1634 122.427C72.3243 122.565 71.4758 122.637 70.6254 122.642C62.4836 122.642 62.7569 115.092 62.7569 115.092L69.5025 116.55C69.2476 115.926 69.1501 115.248 69.2184 114.577C69.2865 113.906 69.5186 113.262 69.8938 112.702C71.4375 110.445 75.0233 111.605 75.0233 111.605L77.2531 110.169L76.7707 112.849L79.7589 112.345L78.2232 114.122C80.2868 116.105 82.9159 122.143 76.575 125.281C70.2341 128.42 67.4979 124.66 67.4979 124.66L70.6254 122.644L73.1634 122.427Z"
            fill="#FF9900"
          />
        </g>
        <path
          d="M73.0283 115.041C73.5669 115.041 74.0038 114.604 74.0038 114.065C74.0038 113.527 73.5669 113.09 73.0283 113.09C72.4896 113.09 72.0527 113.527 72.0527 114.065C72.0527 114.604 72.4896 115.041 73.0283 115.041Z"
          fill="#F1F8F9"
        />
        <path
          d="M90.0352 253.068H201.596"
          stroke="black"
          stroke-width="0.829742"
          stroke-miterlimit="10"
        />
      </g>
    </svg>
  );
};

export default FullEcosystem;
