import { DefaultButton, MessageBarType, Spinner } from '@fluentui/react';
import {
  Form,
  PhoneInputField,
  STextFormFieldStyle,
  TextField,
  useNotification,
} from '@worx.squad/shared-frontend';
import moment from 'moment-timezone';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import BlackLinkSvg from '../Home/assets/BlackLinkSvg';
import LinkSvg from '../Home/assets/LinkSvg';
import { useContactUsMutation } from '../Home/home.generated';
import { tryUsSchema } from '../Home/tryUs.schema';

export const TryUs = () => {
  const [, contactUs] = useContactUsMutation();
  const { notification } = useNotification();
  const navigate = useNavigate();
  const [linkIconOne, setLinkIconOne] = useState(true);
  const [loader, setLoader] = useState(false);
  const tryUsForm = useForm({
    mode: 'all',
    resolver: tryUsSchema,
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone_no: '91',
    },
  });
  const { handleSubmit } = tryUsForm;
  const onSubmit = async (data: any) => {
    setLoader(true);
    try {
      const res = await contactUs({
        object: {
          email: data.email,
          firstName: data.first_name,
          lastName: data.last_name,
          phone: data.phone_no,
        },
      });
      if (res.data) {
        notification({
          message:
            'Thank you for contacting us. We will get back to you shortly.',
          type: MessageBarType.success,
        });
        setLoader(false);
        navigate('/thank-you');
        tryUsForm.reset();
      } else {
        notification({
          message: res.error?.message + '',
          type: MessageBarType.error,
        });
      }
    } catch (e) {
      notification({
        message: e + '',
        type: MessageBarType.error,
      });
    }
  };
  return (
    <div className="w-full flex flex-col justify-center items-center">
      <div>
        <div>
          <div className="text-[28px] sm:text-[3rem] font-normal sm:font-light sm:leading-[3.5rem] leading-[2rem]">
            We Are Glad You <br /> Got Here
          </div>
          <div className="text-[1rem] text-[#000000B8] font-normal my-2">
            Feel free to contact us about your digital projects.
          </div>
        </div>
        <Form
          className="flex flex-col w-full sm:w-1/2 gap-5"
          formHook={tryUsForm}
          onSubmit={() => handleSubmit(onSubmit)}
        >
          <div className="flex flex-col sm:flex-row gap-5 mt-6">
            <label className="text-black w-full">
              First Name
              <TextField
                formHook={tryUsForm}
                name="first_name"
                // label="First Name"
                styles={STextFormFieldStyle}
                placeholder="Enter first name"
                className="w-full"
                underlined
              />
            </label>
            <label className="text-black w-full">
              Last Name
              <TextField
                formHook={tryUsForm}
                name="last_name"
                // label=" Last Name"
                styles={STextFormFieldStyle}
                placeholder="Enter last name"
                className="w-full"
                underlined
              />
            </label>
          </div>

          <div className="flex flex-col sm:flex-row  gap-5">
            <label className="text-black w-full mt-[4px]">
              Email
              <TextField
                formHook={tryUsForm}
                name="email"
                // label="Email"
                styles={STextFormFieldStyle}
                placeholder="Enter email address"
                className="w-full"
                underlined
              />
            </label>
            <div className="w-full mt-[-4px]">
              <PhoneInputField
                country={moment.tz.guess()}
                placeholder="Enter phone number"
                formHook={tryUsForm}
                name="phone_no"
                label="Phone Number"
                inputStyle={{
                  width: '322px',
                  height: '52px',
                  borderRadius: 'unset',
                  border: 'none',
                  borderBottom: '1px solid gray',
                }}
              />
            </div>
          </div>
          <div className="mt-8">
            <DefaultButton
              onClick={handleSubmit(onSubmit)}
              onMouseEnter={() => setLinkIconOne(false)}
              onMouseLeave={() => setLinkIconOne(true)}
              className="border w-full sm:w-1/3 border-[#FF9900] text-black py-6 hover:bg-[#FF9900] hover:text-white ease-in-out duration-300"
            >
              {loader ? (
                <Spinner />
              ) : (
                <span className="flex gap-4 justify-center items-center">
                  <span className="text-[1rem]">Submit</span>
                  <span>{linkIconOne ? <BlackLinkSvg /> : <LinkSvg />}</span>
                </span>
              )}
            </DefaultButton>
          </div>
        </Form>
      </div>
    </div>
  );
};
