const SocialBonding = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_957_8659)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.08161 42.4922C10.4259 45.443 13.7701 45.443 17.1144 42.4922C19.8685 40.1315 22.2292 40.1315 24.9833 42.4922C28.3275 45.443 31.6718 45.443 35.016 42.4922C37.5734 40.1315 40.1308 40.1315 42.8849 42.4922C46.2292 45.443 49.3767 45.443 52.721 42.4922C53.9013 41.5086 54.4915 41.1151 55.8685 40.7217V53.7053H57.2456C60.7865 53.7053 60.7865 60.0004 57.2456 60.0004H2.55702C-0.787237 60.0004 -0.787237 53.7053 2.55702 53.7053H3.93407V40.7217C5.50784 41.1151 5.90129 41.5086 7.08161 42.4922Z"
          fill="#FF9900"
          id="socialBonding"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.24507 26.9512H50.7533C53.7041 26.9512 55.868 29.5085 55.868 32.2626V39.1479C54.0975 39.5413 53.1139 40.1315 51.7369 41.3118C48.9828 43.6725 46.6221 43.6725 43.868 41.3118C40.5238 38.361 37.1795 38.361 33.8352 41.3118C31.0811 43.6725 28.7205 43.6725 25.9664 41.3118C22.6221 38.361 19.2779 38.361 15.9336 41.3118C13.3762 43.6725 10.8188 43.6725 8.06474 41.3118C6.68769 40.1315 5.90081 39.5413 3.93359 39.1479V32.2626C3.93359 29.5085 6.29425 26.9512 9.24507 26.9512Z"
          fill="#FF9900"
          id="socialBonding"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25.9668 11.2139H34.0324V25.3778H25.9668V11.2139Z"
          fill="#FF9900"
          id="socialBonding"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M30.4918 0.197346C32.459 2.16456 35.0164 4.72194 32.459 7.47603C31.0819 8.85308 28.918 9.0498 27.3442 7.47603C24.7868 4.72194 27.3442 2.16456 29.3114 0.197346C29.7049 0.000624359 30.0983 0.000624359 30.4918 0.197346Z"
          fill="#FF9900"
          id="flame"
        />
        <line
          id="socialBonding"
          x1="37"
          y1="11.5"
          x2="44.0711"
          y2="11.5"
          stroke="#FF9900"
        />
        <line
          id="socialBonding"
          x1="36.6464"
          y1="8.64645"
          x2="42.6464"
          y2="2.64645"
          stroke="#FF9900"
        />
        <line
          id="socialBonding"
          x1="35.6464"
          y1="4.64645"
          x2="38.6464"
          y2="1.64645"
          stroke="#FF9900"
        />
        <line
          id="socialBonding"
          x1="17"
          y1="11.5"
          x2="24.0711"
          y2="11.5"
          stroke="#FF9900"
        />
        <line
          id="socialBonding"
          x1="17.3254"
          y1="3.62037"
          x2="24.3254"
          y2="9.62037"
          stroke="#FF9900"
        />
        <line
          id="socialBonding"
          x1="19.3536"
          y1="0.646447"
          x2="22.3536"
          y2="3.64645"
          stroke="#FF9900"
        />
      </g>
      <defs>
        <clipPath id="clip0_957_8659">
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SocialBonding;
