import { DefaultButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import DownloadApp from '../Home/DownloadApp';
import { BookADemo } from '../Home/Utils';
import LinkSvg from '../Home/assets/LinkSvg';
import HowItWorksAnimation from './HowItWorksAnimation';

export const Starter = () => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  return (
    <div className="bg-black text-lg">
      <div className="mx-[20px] sm:mx-[40px] pt-[20px] sm:py-[40px] md:flex justify-between">
        <div className="flex flex-col w-full lg:w-2/3 md:pt-0 pt-0 self-center sm:mt-[94px]">
          <div className="text-white font-medium font-poppins text-[48px] sm:text-[72px] leading-[3.5rem] sm:leading-[5rem]">
            Works as an integrated digital workspace?
          </div>
          <div className="text-black text-[14px] mt-5 mb-10 space-y-5 ">
            <div>
              <p className="text-[#FF9900] text-[1rem]">
                Enterprise Productivity and Employee Experience Platform
              </p>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-4 mb-[6rem] sm:pt-[76px]">
            <div>
              <DefaultButton
                onClick={BookADemo}
                className="bg-[#000000]  w-full border border-[#FF9900] text-white hover:bg-[#FF9900] hover:text-white px-6 py-6 ease-in-out duration-300"
              >
                <span className="flex gap-4 justify-center items-center">
                  <span>Book A Demo</span>
                  <span>
                    <LinkSvg />
                  </span>
                </span>
              </DefaultButton>
            </div>
            <div>
              <DefaultButton
                onClick={showModal}
                className="bg-[#000000]  w-full border border-[#FF9900] text-white hover:bg-[#FF9900] hover:text-white px-6 py-6 ease-in-out duration-300"
              >
                <span className="flex gap-4 justify-center items-center">
                  <span>Download Squad For Free</span>
                  <span>
                    <LinkSvg />
                  </span>
                </span>
              </DefaultButton>
              <DownloadApp
                showModal={showModal}
                isModalOpen={isModalOpen}
                hideModal={hideModal}
              />
            </div>
          </div>
        </div>
        <div className="mb-20 mr-[8rem] pt-[90px] hidden lg:block">
          <HowItWorksAnimation />
        </div>
      </div>
    </div>
  );
};
