const WallOfFame = () => {
  return (
    <svg
      width="53"
      height="60"
      viewBox="0 0 53 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_957_8525)">
        <path
          d="M33.1259 59.6058C33.436 59.6058 33.7334 59.4811 33.9526 59.259C34.1719 59.0369 34.295 58.7357 34.295 58.4216V24.4742C34.295 24.1602 34.1719 23.859 33.9526 23.6369C33.7334 23.4148 33.436 23.29 33.1259 23.29C32.8158 23.29 32.5185 23.4148 32.2992 23.6369C32.08 23.859 31.9568 24.1602 31.9568 24.4742V28.4216H22.6039V24.4742C22.6039 24.1602 22.4807 23.859 22.2614 23.6369C22.0422 23.4148 21.7448 23.29 21.4347 23.29C21.1247 23.29 20.8273 23.4148 20.6081 23.6369C20.3888 23.859 20.2656 24.1602 20.2656 24.4742V58.4216C20.2656 58.7357 20.3888 59.0369 20.6081 59.259C20.8273 59.4811 21.1247 59.6058 21.4347 59.6058C21.7448 59.6058 22.0422 59.4811 22.2614 59.259C22.4807 59.0369 22.6039 58.7357 22.6039 58.4216V54.4742H31.9568V58.4216C31.9568 58.7357 32.08 59.0369 32.2992 59.259C32.5185 59.4811 32.8158 59.6058 33.1259 59.6058ZM22.6039 38.6848H31.9568V44.2111H22.6039V38.6848ZM31.9568 30.79V36.3164H22.6039V30.79H31.9568ZM22.6039 52.1058V46.5795H31.9568V52.1058H22.6039Z"
          fill="#FF9900"
          id="wallOfFameBlink"
        />
        <path
          d="M38.5334 8.72928C38.4598 8.48624 38.3109 8.27365 38.1088 8.12295C37.9067 7.97224 37.662 7.89142 37.4111 7.89243H30.7237L28.3855 1.07138C28.3055 0.834516 28.1524 0.629945 27.949 0.487812C27.7455 0.345679 27.5024 0.2735 27.2553 0.28191C27.0084 0.285925 26.769 0.369066 26.5716 0.519405C26.3741 0.669745 26.2288 0.879559 26.1564 1.11875L24.1611 7.89243H17.1464C16.8945 7.89058 16.6488 7.97117 16.4457 8.12221C16.2427 8.27325 16.0933 8.48666 16.0197 8.73067C15.9462 8.97468 15.9524 9.23623 16.0374 9.47639C16.1224 9.71655 16.2817 9.92247 16.4917 10.0635L21.9475 13.7977L19.9522 20.524C19.8786 20.7637 19.882 21.0209 19.962 21.2585C20.0421 21.496 20.1946 21.7017 20.3975 21.8458C20.6005 21.9899 20.8434 22.0649 21.0912 22.06C21.3389 22.0552 21.5788 21.9707 21.7761 21.8187L27.232 17.6266L32.7502 21.8266C32.9466 21.9944 33.1921 22.0919 33.4487 22.1042C33.7053 22.1165 33.9588 22.0428 34.1699 21.8945C34.3811 21.7463 34.5381 21.5317 34.6168 21.284C34.6955 21.0362 34.6914 20.7692 34.6052 20.524L32.5709 13.7819L38.0268 10.0477C38.2401 9.91243 38.4046 9.71107 38.4959 9.47354C38.5871 9.23601 38.6003 8.975 38.5334 8.72928ZM30.5522 12.3135C30.3444 12.4547 30.1868 12.6598 30.1026 12.8986C30.0184 13.1373 30.0121 13.3971 30.0846 13.6398L31.3239 17.7451L27.9646 15.1951C27.7622 15.0413 27.5161 14.9582 27.2631 14.9582C27.0102 14.9582 26.764 15.0413 26.5617 15.1951L23.2414 17.7293L24.4728 13.6398C24.5453 13.3971 24.539 13.1373 24.4548 12.8986C24.3706 12.6598 24.213 12.4547 24.0052 12.3135L20.9889 10.2609H24.995C25.246 10.2619 25.4907 10.181 25.6928 10.0303C25.8949 9.87964 26.0438 9.66705 26.1174 9.42401L27.3645 5.27928L28.7908 9.47138C28.8716 9.703 29.0216 9.90342 29.2199 10.0449C29.4182 10.1863 29.655 10.2618 29.8975 10.2609H33.5764L30.5522 12.3135Z"
          fill="#FF9900"
          id="wallOfFameBlink"
        />
        <path
          d="M16.3671 11.139C16.3171 10.9712 16.2133 10.825 16.0722 10.7235C15.9311 10.622 15.7606 10.571 15.5877 10.5785H11.5192L10.0928 6.41796C10.0416 6.26001 9.9424 6.12249 9.80944 6.02499C9.67648 5.92749 9.51653 5.875 9.35239 5.875C9.18825 5.875 9.02829 5.92749 8.89533 6.02499C8.76238 6.12249 8.6632 6.26001 8.61195 6.41796L7.36489 10.5785H3.1171C2.94991 10.5781 2.78704 10.6322 2.65252 10.7327C2.518 10.8333 2.41897 10.975 2.37005 11.1369C2.32113 11.2989 2.32491 11.4724 2.38083 11.632C2.43676 11.7916 2.54186 11.9288 2.68062 12.0232L6.0243 14.2969L4.78504 18.3943C4.73421 18.5544 4.73521 18.7268 4.78789 18.8863C4.84057 19.0459 4.94218 19.1842 5.07789 19.2811C5.21361 19.378 5.37635 19.4285 5.54239 19.4252C5.70842 19.4219 5.86907 19.365 6.00092 19.2627L9.35239 16.7048L12.7039 19.2627C12.8352 19.3635 12.9947 19.4195 13.1595 19.4227C13.3243 19.426 13.4858 19.3762 13.6209 19.2806C13.756 19.185 13.8577 19.0484 13.9114 18.8906C13.9651 18.7328 13.968 18.5618 13.9197 18.4022L12.6805 14.2969L16.0242 12.0232C16.1683 11.9335 16.2797 11.7987 16.3416 11.6392C16.4035 11.4797 16.4124 11.3041 16.3671 11.139ZM11.3633 13.3101C11.2247 13.4042 11.1196 13.541 11.0635 13.7001C11.0074 13.8593 11.0032 14.0325 11.0515 14.1943L11.7608 16.5627L9.84342 15.1022C9.7085 14.9997 9.54441 14.9443 9.37577 14.9443C9.20713 14.9443 9.04304 14.9997 8.90812 15.1022L7.01415 16.5311L7.72342 14.1627C7.77174 14.0009 7.76753 13.8277 7.71141 13.6685C7.65529 13.5094 7.55022 13.3727 7.41165 13.2785L5.67357 12.1574H7.94165C8.11457 12.1649 8.28503 12.1139 8.42615 12.0124C8.56727 11.9109 8.67103 11.7647 8.72107 11.5969L9.43033 9.22849L10.2565 11.5969C10.3097 11.751 10.4087 11.8845 10.5399 11.9793C10.6711 12.074 10.828 12.1252 10.9892 12.1259H13.0546L11.3633 13.3101Z"
          fill="#FF9900"
          id="wallOfFameStar"
        />
        <path
          d="M49.025 19.4195C49.1495 19.425 49.2735 19.4001 49.3865 19.347C49.4996 19.294 49.5984 19.2142 49.6747 19.1145C49.7511 19.0147 49.8027 18.8979 49.8253 18.7738C49.8478 18.6496 49.8407 18.5218 49.8044 18.4011L48.5652 14.2958L51.9088 12.0221C52.0502 11.9263 52.1566 11.7863 52.2121 11.6234C52.2675 11.4606 52.2688 11.2839 52.2158 11.1202C52.1629 10.9565 52.0585 10.8148 51.9186 10.7169C51.7788 10.6189 51.6111 10.5699 51.4412 10.5774H47.3727L45.9463 6.41688C45.8908 6.25495 45.7846 6.11575 45.644 6.02032C45.5034 5.92489 45.3359 5.87842 45.1669 5.88794C45.002 5.89079 44.8421 5.9466 44.7105 6.04733C44.5788 6.14806 44.4821 6.28851 44.4343 6.44846L43.2184 10.5774H38.9706C38.8034 10.577 38.6406 10.6311 38.506 10.7317C38.3715 10.8322 38.2725 10.9739 38.2236 11.1359C38.1746 11.2978 38.1784 11.4714 38.2343 11.631C38.2903 11.7905 38.3954 11.9277 38.5341 12.0221L41.8778 14.2958L40.6386 18.3932C40.5877 18.5533 40.5887 18.7257 40.6414 18.8853C40.6941 19.0448 40.7957 19.1831 40.9314 19.28C41.0671 19.377 41.2299 19.4274 41.3959 19.4241C41.5619 19.4208 41.7226 19.3639 41.8544 19.2616L45.2059 16.7037L48.5574 19.2616C48.6923 19.3641 48.8564 19.4195 49.025 19.4195ZM45.6658 15.0853C45.5308 14.9828 45.3668 14.9274 45.1981 14.9274C45.0295 14.9274 44.8654 14.9828 44.7305 15.0853L42.8677 16.53L43.5769 14.1616C43.6253 13.9998 43.621 13.8266 43.5649 13.6675C43.5088 13.5083 43.4037 13.3716 43.2652 13.2774L41.5271 12.1564H43.7952C43.9681 12.1638 44.1385 12.1128 44.2797 12.0113C44.4208 11.9098 44.5245 11.7637 44.5746 11.5958L45.2838 9.22741L46.11 11.5958C46.1632 11.7499 46.2622 11.8835 46.3934 11.9782C46.5246 12.0729 46.6816 12.1241 46.8427 12.1248H48.9081L47.209 13.2774C47.0704 13.3716 46.9654 13.5083 46.9092 13.6675C46.8531 13.8266 46.8489 13.9998 46.8972 14.1616L47.6065 16.53L45.6658 15.0853Z"
          fill="#FF9900"
          id="wallOfFameStar"
        />
        <path
          d="M49.8829 54.4756C49.6762 54.4756 49.478 54.5588 49.3318 54.7068C49.1856 54.8549 49.1035 55.0557 49.1035 55.2651V56.0545C49.1035 56.2639 49.1856 56.4647 49.3318 56.6128C49.478 56.7608 49.6762 56.844 49.8829 56.844C50.0896 56.844 50.2879 56.7608 50.4341 56.6128C50.5802 56.4647 50.6623 56.2639 50.6623 56.0545V55.2651C50.6623 55.0557 50.5802 54.8549 50.4341 54.7068C50.2879 54.5588 50.0896 54.4756 49.8829 54.4756Z"
          fill="#FF9900"
          id="wallOfFameLine"
        />
        <path
          d="M49.2578 45.3158L46.9196 48.4737C46.8581 48.5566 46.8135 48.651 46.7881 48.7514C46.7627 48.8519 46.757 48.9564 46.7715 49.059C46.786 49.1616 46.8203 49.2604 46.8724 49.3496C46.9246 49.4388 46.9936 49.5167 47.0754 49.579C47.1573 49.6412 47.2505 49.6864 47.3496 49.7121C47.4488 49.7379 47.552 49.7436 47.6533 49.7289C47.7546 49.7142 47.8521 49.6795 47.9402 49.6267C48.0283 49.5739 48.1052 49.504 48.1666 49.4211L49.1019 48.1579V52.8947C49.1019 53.1041 49.184 53.3049 49.3302 53.453C49.4764 53.601 49.6746 53.6842 49.8813 53.6842C50.088 53.6842 50.2863 53.601 50.4324 53.453C50.5786 53.3049 50.6607 53.1041 50.6607 52.8947V48.1579L51.596 49.4211C51.6574 49.504 51.7344 49.5739 51.8225 49.6267C51.9105 49.6795 52.008 49.7142 52.1093 49.7289C52.2107 49.7436 52.3138 49.7379 52.413 49.7121C52.5121 49.6864 52.6053 49.6412 52.6872 49.579C52.7691 49.5167 52.8381 49.4388 52.8902 49.3496C52.9424 49.2604 52.9767 49.1616 52.9911 49.059C53.0056 48.9564 53 48.8519 52.9746 48.7514C52.9492 48.651 52.9045 48.5566 52.8431 48.4737L50.5049 45.3158C50.4323 45.2177 50.3381 45.1382 50.2299 45.0833C50.1217 45.0285 50.0023 45 49.8813 45C49.7603 45 49.641 45.0285 49.5328 45.0833C49.4245 45.1382 49.3304 45.2177 49.2578 45.3158Z"
          fill="#FF9900"
          id="wallOfFameLine"
        />
        <path
          d="M43.6467 44.9994C43.6467 44.7901 43.5646 44.5892 43.4184 44.4412C43.2723 44.2931 43.074 44.21 42.8673 44.21C42.6606 44.21 42.4623 44.2931 42.3162 44.4412C42.17 44.5892 42.0879 44.7901 42.0879 44.9994V45.7889C42.0879 45.9983 42.17 46.1991 42.3162 46.3472C42.4623 46.4952 42.6606 46.5784 42.8673 46.5784C43.074 46.5784 43.2723 46.4952 43.4184 46.3472C43.5646 46.1991 43.6467 45.9983 43.6467 45.7889V44.9994Z"
          fill="#FF9900"
          id="wallOfFameLine"
        />
        <path
          d="M42.5711 47.4309C42.4755 47.4684 42.388 47.5248 42.3139 47.5967C42.1715 47.7479 42.0908 47.9482 42.0879 48.1572C42.0891 48.2603 42.1103 48.3623 42.1502 48.4572C42.1899 48.5528 42.2452 48.6409 42.3139 48.7177C42.3864 48.7917 42.4726 48.8504 42.5676 48.8905C42.6625 48.9306 42.7644 48.9512 42.8673 48.9512C42.9702 48.9512 43.0721 48.9306 43.167 48.8905C43.262 48.8504 43.3482 48.7917 43.4207 48.7177C43.4893 48.6408 43.5446 48.5527 43.5844 48.4572C43.6243 48.3623 43.6455 48.2603 43.6467 48.1572C43.6473 48.0533 43.6276 47.9503 43.5889 47.8541C43.5501 47.7579 43.4929 47.6704 43.4207 47.5967C43.3111 47.4871 43.1719 47.4129 43.0207 47.3834C42.8695 47.3539 42.7131 47.3704 42.5711 47.4309Z"
          fill="#FF9900"
          id="wallOfFameLine"
        />
        <path
          d="M43.6471 37.8923L44.5824 39.1554C44.6438 39.2384 44.7207 39.3082 44.8088 39.3611C44.8968 39.4139 44.9943 39.4486 45.0957 39.4633C45.197 39.4779 45.3002 39.4722 45.3993 39.4465C45.4985 39.4208 45.5916 39.3755 45.6735 39.3133C45.7554 39.2511 45.8244 39.1732 45.8765 39.084C45.9287 38.9948 45.963 38.896 45.9775 38.7934C45.9919 38.6908 45.9863 38.5862 45.9609 38.4858C45.9355 38.3854 45.8908 38.291 45.8294 38.2081L43.4912 35.0502C43.4186 34.9521 43.3244 34.8725 43.2162 34.8177C43.108 34.7629 42.9886 34.7344 42.8676 34.7344C42.7466 34.7344 42.6273 34.7629 42.5191 34.8177C42.4109 34.8725 42.3167 34.9521 42.2441 35.0502L39.9059 38.2081C39.8445 38.291 39.7998 38.3854 39.7744 38.4858C39.749 38.5862 39.7434 38.6908 39.7578 38.7934C39.7723 38.896 39.8066 38.9948 39.8587 39.084C39.9109 39.1732 39.9799 39.2511 40.0618 39.3133C40.1436 39.3755 40.2368 39.4208 40.336 39.4465C40.4351 39.4722 40.5383 39.4779 40.6396 39.4633C40.741 39.4486 40.8384 39.4139 40.9265 39.3611C41.0146 39.3082 41.0915 39.2384 41.1529 39.1554L42.0882 37.8923V42.6291C42.0882 42.8385 42.1703 43.0393 42.3165 43.1874C42.4627 43.3354 42.6609 43.4186 42.8676 43.4186C43.0744 43.4186 43.2726 43.3354 43.4188 43.1874C43.5649 43.0393 43.6471 42.8385 43.6471 42.6291V37.8923Z"
          fill="#FF9900"
          id="wallOfFameLine"
        />
        <path
          d="M39.7508 56.0514C39.9575 56.0514 40.1558 55.9682 40.3019 55.8201C40.4481 55.6721 40.5302 55.4713 40.5302 55.2619V53.2171L40.7562 53.4461C40.9053 53.5754 41.0971 53.643 41.2933 53.6353C41.4894 53.6277 41.6755 53.5453 41.8143 53.4047C41.9531 53.2641 42.0345 53.0756 42.042 52.8769C42.0496 52.6782 41.9829 52.484 41.8552 52.3329L40.2964 50.754C40.1503 50.6069 39.9528 50.5244 39.7469 50.5244C39.541 50.5244 39.3434 50.6069 39.1974 50.754L37.6386 52.3329C37.5109 52.484 37.4442 52.6782 37.4518 52.8769C37.4593 53.0756 37.5406 53.2641 37.6795 53.4047C37.8183 53.5453 38.0043 53.6277 38.2005 53.6353C38.3967 53.643 38.5885 53.5754 38.7376 53.4461L38.9636 53.2171V55.2619C38.9636 55.3662 38.984 55.4695 39.0237 55.5658C39.0633 55.6621 39.1214 55.7495 39.1946 55.8229C39.2678 55.8963 39.3547 55.9543 39.4501 55.9935C39.5456 56.0327 39.6478 56.0524 39.7508 56.0514Z"
          fill="#FF9900"
          id="wallOfFameLine"
        />
        <path
          d="M2.16624 39.969L3.89654 37.7742V45.7874C3.89654 45.9968 3.97865 46.1976 4.12482 46.3457C4.27099 46.4937 4.46924 46.5769 4.67595 46.5769C4.88266 46.5769 5.08091 46.4937 5.22708 46.3457C5.37324 46.1976 5.45536 45.9968 5.45536 45.7874V37.7742L7.18565 39.9611C7.24836 40.0456 7.3272 40.1166 7.41749 40.1697C7.50778 40.2228 7.60768 40.2569 7.71126 40.2702C7.81484 40.2834 7.91999 40.2754 8.02047 40.2467C8.12095 40.218 8.21471 40.1691 8.29618 40.103C8.37766 40.0369 8.44518 39.9548 8.49476 39.8618C8.54433 39.7687 8.57494 39.6665 8.58476 39.5612C8.59459 39.4559 8.58344 39.3497 8.55196 39.2489C8.52049 39.1481 8.46933 39.0547 8.40154 38.9742L5.28389 35.0269C5.21086 34.9347 5.11831 34.8603 5.01309 34.8091C4.90787 34.758 4.79265 34.7314 4.67595 34.7314C4.55924 34.7314 4.44403 34.758 4.3388 34.8091C4.23358 34.8603 4.14104 34.9347 4.06801 35.0269L0.95036 38.9742C0.882565 39.0547 0.831412 39.1481 0.799936 39.2489C0.768461 39.3497 0.757305 39.4559 0.767132 39.5612C0.776958 39.6665 0.807566 39.7687 0.857139 39.8618C0.906713 39.9548 0.97424 40.0369 1.05571 40.103C1.13719 40.1691 1.23095 40.218 1.33142 40.2467C1.4319 40.2754 1.53705 40.2834 1.64064 40.2702C1.74422 40.2569 1.84412 40.2228 1.93441 40.1697C2.0247 40.1166 2.10354 40.0456 2.16624 39.9611V39.969Z"
          fill="#FF9900"
          id="wallOfFameLine"
        />
        <path
          d="M5.22928 47.5967C5.15515 47.5248 5.06775 47.4684 4.97207 47.4309C4.83013 47.3704 4.67369 47.3539 4.5225 47.3834C4.3713 47.4129 4.23212 47.4871 4.12251 47.5967C3.98009 47.7479 3.89936 47.9482 3.89648 48.1572C3.89772 48.2603 3.9189 48.3623 3.95884 48.4572C3.99384 48.5552 4.04969 48.6441 4.12242 48.7178C4.19516 48.7915 4.283 48.848 4.37972 48.8835C4.52166 48.944 4.6781 48.9605 4.82929 48.931C4.98049 48.9015 5.11967 48.8273 5.22928 48.7177C5.29788 48.6408 5.35322 48.5527 5.39295 48.4572C5.43659 48.3635 5.45794 48.2607 5.45531 48.1572C5.45244 47.9482 5.3717 47.7479 5.22928 47.5967Z"
          fill="#FF9900"
          id="wallOfFameLine"
        />
        <path
          d="M5.45531 50.5238C5.45531 50.3145 5.37319 50.1137 5.22702 49.9656C5.08086 49.8176 4.88261 49.7344 4.6759 49.7344C4.46918 49.7344 4.27094 49.8176 4.12477 49.9656C3.9786 50.1137 3.89648 50.3145 3.89648 50.5238V51.3133C3.89648 51.5227 3.9786 51.7235 4.12477 51.8716C4.27094 52.0196 4.46918 52.1028 4.6759 52.1028C4.88261 52.1028 5.08086 52.0196 5.22702 51.8716C5.37319 51.7235 5.45531 51.5227 5.45531 51.3133V50.5238Z"
          fill="#FF9900"
          id="wallOfFameLine"
        />
        <path
          d="M4.6759 52.8916C4.46918 52.8916 4.27094 52.9748 4.12477 53.1228C3.9786 53.2709 3.89648 53.4717 3.89648 53.6811V54.4705C3.89648 54.6799 3.9786 54.8807 4.12477 55.0288C4.27094 55.1768 4.46918 55.26 4.6759 55.26C4.88261 55.26 5.08086 55.1768 5.22702 55.0288C5.37319 54.8807 5.45531 54.6799 5.45531 54.4705V53.6811C5.45531 53.4717 5.37319 53.2709 5.22702 53.1228C5.08086 52.9748 4.88261 52.8916 4.6759 52.8916Z"
          fill="#FF9900"
          id="wallOfFameLine"
        />
        <path
          d="M13.8035 52.3363C13.7277 52.2666 13.6408 52.2105 13.5463 52.1705C13.3565 52.0916 13.1437 52.0916 12.954 52.1705C12.8595 52.2105 12.7725 52.2666 12.6967 52.3363C12.6245 52.4101 12.5674 52.4976 12.5286 52.5938C12.4898 52.69 12.4701 52.793 12.4707 52.8969C12.4719 53 12.4931 53.1019 12.5331 53.1969C12.5681 53.2948 12.6239 53.3838 12.6967 53.4575C12.7694 53.5312 12.8572 53.5877 12.954 53.6232C13.072 53.6723 13.2002 53.6913 13.3272 53.6785C13.4543 53.6657 13.5763 53.6215 13.6825 53.5498C13.7887 53.4781 13.8759 53.381 13.9364 53.2672C13.9969 53.1533 14.0289 53.0262 14.0295 52.8969C14.0267 52.6878 13.9459 52.4876 13.8035 52.3363Z"
          fill="#FF9900"
          id="wallOfFameLine"
        />
        <path
          d="M13.2501 54.4756C13.0434 54.4756 12.8452 54.5588 12.699 54.7068C12.5528 54.8549 12.4707 55.0557 12.4707 55.2651V56.0545C12.4707 56.2639 12.5528 56.4647 12.699 56.6128C12.8452 56.7608 13.0434 56.844 13.2501 56.844C13.4568 56.844 13.6551 56.7608 13.8012 56.6128C13.9474 56.4647 14.0295 56.2639 14.0295 56.0545V55.2651C14.0295 55.0557 13.9474 54.8549 13.8012 54.7068C13.6551 54.5588 13.4568 54.4756 13.2501 54.4756Z"
          fill="#FF9900"
          id="wallOfFameLine"
        />
        <path
          d="M14.0299 45.7907L14.9652 47.0539C15.0266 47.1368 15.1035 47.2067 15.1916 47.2595C15.2797 47.3123 15.3771 47.3471 15.4785 47.3617C15.5798 47.3764 15.683 47.3707 15.7821 47.345C15.8813 47.3192 15.9745 47.274 16.0563 47.2118C16.1382 47.1496 16.2072 47.0716 16.2594 46.9824C16.3115 46.8932 16.3458 46.7945 16.3603 46.6918C16.3747 46.5892 16.3691 46.4847 16.3437 46.3842C16.3183 46.2838 16.2736 46.1894 16.2122 46.1065L13.874 42.9486C13.8014 42.8506 13.7072 42.771 13.599 42.7162C13.4908 42.6613 13.3715 42.6328 13.2505 42.6328C13.1295 42.6328 13.0101 42.6613 12.9019 42.7162C12.7937 42.771 12.6995 42.8506 12.6269 42.9486L10.2887 46.1065C10.2273 46.1894 10.1826 46.2838 10.1572 46.3842C10.1318 46.4847 10.1262 46.5892 10.1406 46.6918C10.1551 46.7945 10.1894 46.8932 10.2416 46.9824C10.2937 47.0716 10.3627 47.1496 10.4446 47.2118C10.5265 47.274 10.6196 47.3192 10.7188 47.345C10.8179 47.3707 10.9211 47.3764 11.0225 47.3617C11.1238 47.3471 11.2213 47.3123 11.3093 47.2595C11.3974 47.2067 11.4743 47.1368 11.5358 47.0539L12.471 45.7907V50.5276C12.471 50.7369 12.5532 50.9377 12.6993 51.0858C12.8455 51.2338 13.0437 51.317 13.2505 51.317C13.4572 51.317 13.6554 51.2338 13.8016 51.0858C13.9478 50.9377 14.0299 50.7369 14.0299 50.5276V45.7907Z"
          fill="#FF9900"
          id="wallOfFameLine"
        />
      </g>
      <defs>
        <clipPath id="clip0_957_8525">
          <rect width="53" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WallOfFame;
