const TeamActivity = () => {
  return (
    <svg
      width="75"
      height="60"
      viewBox="0 0 75 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id="kudosNRewards"
    >
      <g clip-path="url(#clip0_971_8695)">
        <path
          d="M56.0674 26.4959C58.6664 26.4959 60.7734 24.3706 60.7734 21.7489C60.7734 19.1272 58.6664 17.002 56.0674 17.002C53.4683 17.002 51.3613 19.1272 51.3613 21.7489C51.3613 24.3706 53.4683 26.4959 56.0674 26.4959Z"
          fill="#FF9900"
        />
        <path
          d="M18.5548 26.5713C21.1534 26.5713 23.26 24.4464 23.26 21.8252C23.26 19.204 21.1534 17.0791 18.5548 17.0791C15.9562 17.0791 13.8496 19.204 13.8496 21.8252C13.8496 24.4464 15.9562 26.5713 18.5548 26.5713Z"
          fill="#FF9900"
        />
        <path
          d="M9.14267 18.4521C11.7413 18.4521 13.8478 16.3272 13.8478 13.706C13.8478 11.0849 11.7413 8.95996 9.14267 8.95996C6.54408 8.95996 4.4375 11.0849 4.4375 13.706C4.4375 16.3272 6.54408 18.4521 9.14267 18.4521Z"
          fill="#FF9900"
        />
        <path
          d="M65.2774 18.4521C67.876 18.4521 69.9826 16.3272 69.9826 13.706C69.9826 11.0849 67.876 8.95996 65.2774 8.95996C62.6788 8.95996 60.5723 11.0849 60.5723 13.706C60.5723 16.3272 62.6788 18.4521 65.2774 18.4521Z"
          fill="#FF9900"
        />
        <path
          d="M18.5548 10.3359C21.1534 10.3359 23.26 8.21103 23.26 5.58984C23.26 2.96865 21.1534 0.84375 18.5548 0.84375C15.9562 0.84375 13.8496 2.96865 13.8496 5.58984C13.8496 8.21103 15.9562 10.3359 18.5548 10.3359Z"
          fill="#FF9900"
        />
        <path
          d="M56.0665 10.3359C58.6651 10.3359 60.7717 8.21103 60.7717 5.58984C60.7717 2.96865 58.6651 0.84375 56.0665 0.84375C53.4679 0.84375 51.3613 2.96865 51.3613 5.58984C51.3613 8.21103 53.4679 10.3359 56.0665 10.3359Z"
          fill="#FF9900"
        />
        <path
          d="M19.8105 27.8625L12.8148 27.913L3.40356 18.4199L0.265625 21.5851L12.2811 33.7043V59.5077H16.9872V46.8495H20.1242V59.5077H24.8303V32.926C24.8303 30.1312 22.5829 27.8625 19.8105 27.8625Z"
          fill="#FF9900"
        />
        <path
          d="M74.9518 21.4904L71.8147 18.3252L62.4044 27.8191L54.8139 27.7895C52.0432 27.7895 49.7949 30.0556 49.7949 32.8521V59.4348H54.501V46.7765H57.6389V59.4348H62.3441V34.0808L62.4044 34.1478L74.9518 21.4904Z"
          fill="#FF9900"
        />
        <path
          d="M49.8189 11.2012H45.3818V22.9533L43.6534 24.6968H31.1042L29.2663 22.8429V11.2012H24.8301V24.6968L31.1042 31.0264V59.5064H35.8094V43.6838H38.9473V59.5064H43.6534V31.0264L49.8189 24.8073V11.2012Z"
          fill="#FF9900"
        />
        <path
          d="M37.3779 21.5359C39.977 21.5359 42.0839 19.4106 42.0839 16.7889C42.0839 14.1673 39.977 12.042 37.3779 12.042C34.7788 12.042 32.6719 14.1673 32.6719 16.7889C32.6719 19.4106 34.7788 21.5359 37.3779 21.5359Z"
          fill="#FF9900"
        />
      </g>
      <defs>
        <clipPath id="clip0_971_8695">
          <rect width="75" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TeamActivity;
