import { useLayoutState } from '@worx.squad/shared-frontend';
import { useLayoutEffect } from 'react';
import ContactUs from '../components/CommonComponent/ContactUs';
import { Footer } from '../components/CommonComponent/Footer';
import { Header } from '../components/CommonComponent/Header';
import { Starter } from '../components/SquadPricingPlans/Starter';
import WhatYouGet from '../components/SquadPricingPlans/WhatYouGet';

const Pricing = () => {
  const [, setLayoutState] = useLayoutState();
  useLayoutEffect(() => {
    setLayoutState?.('normal');
  }, [setLayoutState]);

  return (
    <div className="relative">
      <div className="sticky bg-[#000000] top-0 z-[9999]">
        <Header />
      </div>
      <div className="bg-[#000000]">
        <Starter />
      </div>
      <div className="my-[3rem]">
        {' '}
        <WhatYouGet />
      </div>
      <div className="pt-1 bg-[#000000]">
        <ContactUs />
        <Footer />
      </div>
    </div>
  );
};

export default Pricing;
