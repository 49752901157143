import { IconButton, Text } from '@fluentui/react';
import { useState } from 'react';
import { productivityItems } from './Utils';
const Productivity = () => {
  const [disabledOne, setDisabledOne] = useState(true);
  const items = productivityItems.map((i, key) => {
    return {
      key: key,
      content: i.content,
      title: i.title,
    };
  });

  return (
    <div className="mx-[20px] sm:mx-[40px] my-[120px] gap-[0rem] sm:gap-[5rem] flex flex-col justify-between lg:flex-row">
      <div className="text-[24px] sm:text-[48px] leading-[2.5rem] lg:leading-[3.5rem] w-full lg:w-[33%] ">
        What improves productivity?
      </div>
      <div className="text-center w-[100%] mt-[20px] sm:mt-[0px]">
        <div className="p-4 bg-[#185D740F] my-1">
          <div
            className="text-left mr-1 flex justify-between items-center gap-10 sm:gap-96 cursor-pointer"
            onClick={() => {
              setDisabledOne(!disabledOne);
            }}
          >
            <Text className="text-lg font-semibold text-[#185D74]">
              Project management
            </Text>
            <IconButton
              iconProps={
                disabledOne
                  ? { iconName: 'CalculatorSubtract' }
                  : { iconName: 'Add' }
              }
            />
          </div>
          <div
            className="mr-5 sm:mr-0"
            style={{ maxWidth: 800, textAlign: 'left' }}
          >
            <Text
              className="font-medium text-lg text-[#000000]"
              style={!disabledOne ? { display: 'none' } : {}}
            >
              Track tasks status (time to complete tasks) & deviations in
              project goals timelines.
            </Text>
          </div>
        </div>
        <div className="mx-auto">
          {items.map((item, index) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const [disabled, setDisabled] = useState(false);
            if (index === item.key && index !== 0) {
              return (
                <div className="p-4 bg-[#185D740F] my-1" key={index}>
                  <div
                    className="text-left mr-1 flex justify-between items-center gap-10 sm:gap-96 cursor-pointer"
                    onClick={() => {
                      setDisabled(!disabled);
                    }}
                  >
                    <Text className="text-lg font-semibold text-[#185D74]">
                      {item.title}
                    </Text>
                    <IconButton
                      iconProps={
                        disabled
                          ? { iconName: 'CalculatorSubtract' }
                          : { iconName: 'Add' }
                      }
                    />
                  </div>
                  <div
                    className="mr-5 sm:mr-0"
                    style={{ maxWidth: 800, textAlign: 'left' }}
                  >
                    <Text
                      className="font-medium text-lg text-[#000000]"
                      style={!disabled ? { display: 'none' } : {}}
                    >
                      {item.content}
                    </Text>
                  </div>
                </div>
              );
            } else {
              return '';
            }
          })}
        </div>
      </div>{' '}
    </div>
  );
};

export default Productivity;
