import './RadarAnimation.scss';
const OurMission = () => {
  return (
    <div className="bg-[#185D74] py-[60px] px-[20px] sm:px-[40px] flex flex-col lg:flex-row items-center justify-center gap-8">
      <div>
        <img src="../../../assets/Domino.png" alt="Domino" />
      </div>
      <div
        className="text-white text-center lg:text-left text-[28px]"
        style={{ maxWidth: 900 }}
      >
        Squad is a full Operating system for small to mid-size businesses from
        productivity, collaboration, engagement, and wellness.
      </div>
    </div>
  );
};

export default OurMission;
