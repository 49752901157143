import './RadarAnimation.scss';
import Collaboration from './assets/Collaboration';
import ProjectManagement from './assets/ProjectManagement';
import Stress from './assets/Stress';
import Upskill from './assets/Upskill';
const RadarAnimation = () => {
  return (
    <div className="radar">
      <div className="radar-container">
        <div className="radar__dot">
          <div className="flex items-center gap-[10px]">
            <span>
              <Collaboration isWhite={true} />
            </span>{' '}
            <span> Collaboration</span>
          </div>
        </div>
        <div className="radar__dot">
          <div className="flex items-center gap-[10px]">
            <span>
              <Stress isWhite={true} />
            </span>{' '}
            <span> Wellness</span>
          </div>
        </div>
        <div className="radar__dot">
          <div className="flex items-center gap-[10px]">
            <span>
              <Upskill isWhite={true} />
            </span>{' '}
            <span> Engagement </span>
          </div>
        </div>
        <div className="radar__dot">
          <div className="flex items-center gap-[10px]">
            <span>
              <ProjectManagement isWhite={true} />
            </span>{' '}
            <span> Productivity</span>
          </div>
        </div>
        <img
          src={'../../../assets/RadarIcon.png'}
          alt="radarIcon"
          className="lg:w-[300px] lg:h-[300px] sm:w-[90px] sm:h-[90px] vertical-move vert-move"
        />
      </div>
    </div>
  );
};

export default RadarAnimation;
