import { DefaultButton } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import LinkSvg from '../Home/assets/LinkSvg';

export const Starter = () => {
  const navigate = useNavigate();

  return (
    <div className="my-[80px] text-lg">
      <div className="mx-[20px] sm:mx-[40px] md:flex justify-between">
        <div className="flex flex-col w-full md:pt-0 pt-0 self-center">
          <div className="text-white font-medium font-poppins text-[48px] sm:text-[72px] leading-[3.5rem] sm:leading-[5rem] sm:pt-[138px]">
            Future of Work: <br /> The Digital Way
          </div>
          <div className="text-black my-[16px] space-y-5">
            <div className=" text-white">
              The evolving workplace landscape is a tapestry woven with threads
              of change and innovation.
            </div>
            <div className="text-white font-semibold my-[16px]">
              10 min read
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-4 mt-[48px] sm:mb-[174px]">
            <div>
              <DefaultButton
                onClick={() => navigate(`/blog/652b9bb5722f7ce55da8a801`)}
                className="bg-transparent border-white w-full text-white hover:bg-transparent hover:border-[#FF9900] hover:text-white px-6 py-6 ease-in-out duration-300"
              >
                <span className="flex gap-4 justify-center items-center">
                  <span className="text-[1rem]">Read More</span>
                  <span>
                    <LinkSvg />
                  </span>
                </span>
              </DefaultButton>
            </div>
            <div>
              <DefaultButton
                // onClick={() => navigate('/login')}
                className="bg-transparent border-white w-full text-white hover:bg-transparent hover:border-[#FF9900] hover:text-white px-6 py-6 ease-in-out duration-300"
              >
                <span className="flex gap-4 justify-center items-center">
                  <span className="text-[1rem]">Share</span>
                  <span>
                    <LinkSvg />
                  </span>
                </span>
              </DefaultButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
